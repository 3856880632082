import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  GET_CARRIER_LIST,
  DELETE_CARRIER,
  GET_CARRIER,
  MANAGE_CARRIER,
  RESET_CARRIER,
  RESET_CARRIER_LIST,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE_LIST = {
  data: {
    data: [],
  },
  loading: false,
  success: false,
  error: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
};

const List = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_CARRIER_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_CARRIER_LIST):
      return { ...state, data: action.payload, source: action.source, loading: false, success: true };
    case FAILED(GET_CARRIER_LIST):
      return { ...state, loading: false, error: true };
    case DELETE_CARRIER:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_CARRIER):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_CARRIER):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_CARRIER):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_CARRIER_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_CARRIER = {
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
  data: {
    id: '',
    issuerName: '',
    logo: '',
    type: '',
  },
};

const Carrier = (state = INIT_STATE_CARRIER, action) => {
  switch (action.type) {
    case GET_CARRIER:
      return { ...state, success: false, error: false, loading: true };
    case SUCCESS(GET_CARRIER):
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILED(GET_CARRIER):
      return { ...state, loading: false, error: true };
    case MANAGE_CARRIER:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_CARRIER):
      return {
        ...state,
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_CARRIER):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_CARRIER:
    case RESET:
      return INIT_STATE_CARRIER;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  Carrier,
});

const getCarrierLogoFromState = (state) => state.Broker.Carrier.Carrier; // all slice
export const getCarrierLogoFromStateSelector = createSelector([getCarrierLogoFromState], (carrier) => {
  // forward
  return {
    ...carrier.data,
  };
});
