import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { flashSuccessMessage, flashErrorMessage } from 'redux-flash';
import { CHANGE_PASSWORD, CHECK_TOKEN } from 'constants/actionTypes';
import { changePasswordSuccess, changePasswordFailed, checkTokenSuccess, checkTokenFailed } from './actions';
import { resolveErrorPromise } from '../../../helpers';

const fetchJSON = async (url, options = {}) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw response;
  }
  const json = await response.json();

  return json;
};

function* changePassword(action) {
  const body = JSON.stringify({
    email: action.payload.email,
    password: action.payload.password,
    confirmPassword: action.payload.confirmPassword,
  });

  try {
    const infoOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_API_BASIC_AUTH}`,
      },
      body,
    };
    const response = yield call(
      fetchJSON,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/password-token/${action.payload.id}`,
      infoOptions
    );

    yield put(flashSuccessMessage('Password has been changed. Please login with your new password.'));

    yield put(changePasswordSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(changePasswordFailed(message));
    } else {
      yield put(changePasswordFailed());
      yield put(flashErrorMessage('Invalid Token'));
    }
  }
}

function* checkToken(action) {
  try {
    const infoOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_API_BASIC_AUTH}`,
      },
    };
    yield call(
      fetchJSON,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/password-token/${action.payload.token}`,
      infoOptions
    );
    yield put(checkTokenSuccess());
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message =
          'You password reset request is invalid. Please request a new password reset and try again. The email url is valid for 10 minutes.';
        break;
      default:
        message = error.statusText;
        break;
    }

    yield put(checkTokenFailed());
    yield put(flashErrorMessage(message));
  }
}

export function* watchChangePassword() {
  yield takeLeading(CHANGE_PASSWORD, changePassword);
}

export function* watchCheckToken() {
  yield takeLeading(CHECK_TOKEN, checkToken);
}

function* authSaga() {
  yield all([fork(watchChangePassword), fork(watchCheckToken)]);
}

export default authSaga;
