import {
  GET_GLOBAL_ALERT,
  GET_GLOBAL_ALERT_LIST,
  DELETE_GLOBAL_ALERT,
  RESET_GLOBAL_ALERT,
  RESET_GLOBAL_ALERT_LIST,
  MANAGE_GLOBAL_ALERT,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getGlobalAlertList = () => ({
  type: GET_GLOBAL_ALERT_LIST,
});

export const getGlobalAlertListSuccess = (data) => ({
  type: SUCCESS(GET_GLOBAL_ALERT_LIST),
  payload: data,
});

export const getGlobalAlertListFailed = (error) => ({
  type: FAILED(GET_GLOBAL_ALERT_LIST),
  payload: error,
});

export const resetGlobalAlertList = () => ({
  type: RESET_GLOBAL_ALERT_LIST,
});

export const deleteGlobalAlert = (globalAlert) => ({
  type: DELETE_GLOBAL_ALERT,
  payload: globalAlert,
});

export const deleteGlobalAlertSuccess = () => ({
  type: SUCCESS(DELETE_GLOBAL_ALERT),
});

export const deleteGlobalAlertFailed = () => ({
  type: FAILED(DELETE_GLOBAL_ALERT),
});

export const resetDeleteGlobalAlert = () => ({
  type: RESETED(DELETE_GLOBAL_ALERT),
});

export const getGlobalAlert = (globalAlertId) => ({
  type: GET_GLOBAL_ALERT,
  payload: globalAlertId,
});

export const getGlobalAlertSuccess = (data) => ({
  type: SUCCESS(GET_GLOBAL_ALERT),
  payload: data,
});

export const getGlobalAlertFailed = () => ({
  type: FAILED(GET_GLOBAL_ALERT),
});

export const manageGlobalAlert = (data) => ({
  type: MANAGE_GLOBAL_ALERT,
  payload: data,
});

export const manageGlobalAlertSuccess = () => ({
  type: SUCCESS(MANAGE_GLOBAL_ALERT),
});

export const manageGlobalAlertFailed = (data) => ({
  type: FAILED(MANAGE_GLOBAL_ALERT),
  payload: data,
});

export const resetGlobalAlert = () => ({
  type: RESET_GLOBAL_ALERT,
});
