export const GET_PRODUCER_LIST = 'GET_PRODUCER_LIST';

export const RESET_PRODUCER_LIST = 'RESET_PRODUCER_LIST';

export const GET_ALL_PRODUCERS_LIST = 'GET_ALL_PRODUCERS_LIST';

export const RESET_ALL_PRODUCERS_LIST = 'RESET_ALL_PRODUCERS_LIST';

export const RESET_ALL_PRODUCERS_AND_AGENCIES_LIST = 'RESET_ALL_PRODUCERS_AND_AGENCIES_LIST';

export const GET_ALL_PRODUCERS_AND_AGENCIES_LIST = 'GET_ALL_PRODUCERS_AND_AGENCIES_LIST';

export const GET_PRODUCER = 'GET_PRODUCER';

export const MANAGE_PRODUCER = 'MANAGE_PRODUCER';

export const DELETE_PRODUCER = 'DELETE_PRODUCER';

export const REACTIVATE_PRODUCER = 'REACTIVATE_PRODUCER';

export const RESET_PRODUCER = 'RESET_PRODUCER';
export const GET_PRODUCER_POSSIBLE_PARENTS = 'GET_PRODUCER_POSSIBLE_PARENTS';

export const APPROVE_PRODUCER = 'APPROVE_PRODUCER';

export const APPROVE_PRODUCER_NIPR = 'APPROVE_PRODUCER_NIPR';

export const APPROVE_PRODUCER_FFM = 'APPROVE_PRODUCER_FFM';

export const MANUAL_APPROVE_PRODUCER = 'MANUAL_APPROVE_PRODUCER';

// restrictions
export const GET_PRODUCER_RESTRICTIONS_FORM_CONFIG = 'GET_PRODUCER_RESTRICTIONS_FORM_CONFIG';

export const GET_PRODUCER_RESTRICTIONS = 'GET_PRODUCER_RESTRICTIONS';

export const MANAGE_PRODUCER_RESTRICTIONS = 'MANAGE_PRODUCER_RESTRICTIONS';

export const PRODUCER_TEMPLATE_IMPORT_CSV = 'PRODUCER_TEMPLATE_IMPORT_CSV';

export const GET_AGENT_REP_PRODUCER_RESTRICTIONS = 'GET_AGENT_REP_PRODUCER_RESTRICTIONS';

export const GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG = 'GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG';