import _ from 'lodash';
import { createSelector } from 'reselect';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import { GET_LOCATION, RESET_LOCATION, RESET } from 'constants/actionTypes';

const INIT_STATE = {
  data: {
    data: [],
  },
  loading: false,
  source: '',
};

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATION:
      return { ...state, loading: true };
    case SUCCESS(GET_LOCATION):
      return { ...state, data: action.payload, source: action.source, loading: false };
    case FAILED(GET_LOCATION):
      return { ...state, loading: false };
    case RESET_LOCATION:
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default Location;

// selectors
const getLocationDataFromState = state => state.Location.data;
const getLocationLoadingFromState = state => state.Location.loading;
const getLocationSourceFromState = state => state.Location.source;
export const getLocationSelector = createSelector(
  [getLocationDataFromState, getLocationLoadingFromState, getLocationSourceFromState],
  (data, loading, source) => {
    const locations = {
      counties: [],
      states: [],
    };

    data.data.forEach(item => {
      const locationState = { stateAbbreviation: item.stateAbbreviation, state: item.state };
      if (_.findIndex(locations.states, locationState) === -1) {
        locations.states.push(locationState);
      }

      locations.counties.push({ countyFips: item.countyFips, county: item.county });
    });
    const isEmpty = locations.counties.length < 1 || locations.states.length < 1;

    return {
      ...locations,
      loading,
      source,
      isEmpty,
    };
  }
);
