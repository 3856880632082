import _ from 'lodash';
import dayjs from 'dayjs';
import oAuth2Service from './oAuthToken';

let token = {};

export const checkToken = async () => {
  if (_.isEmpty(token)) {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    if (_.isEmpty(tokenData)) {
      return false;
    }

    oAuth2Service.options.accessTokenUri = `${
      process.env.REACT_APP_PUBLIC_API_ENDPOINT
    }/oauth/token?tz=${dayjs().format()}`;
    const createdToken = oAuth2Service.createToken(
      tokenData.accessToken,
      tokenData.refreshToken,
      tokenData.tokenType,
      tokenData.data
    );

    createdToken.expiresIn(new Date(tokenData.expires));
    _.merge(token, createdToken);

    if (tokenData.accessToken.length === 0) {
      await token.refresh().then(refreshToken => {
        _.merge(token, refreshToken);
        const { client, ...newRefreshToken } = refreshToken;
        localStorage.setItem('token', JSON.stringify(newRefreshToken));

        return token;
      });
    }

    return token;
  }

  if (token.expired()) {
    await token.refresh().then(refreshToken => {
      _.merge(token, refreshToken);
      const { client, ...newRefreshToken } = refreshToken;
      localStorage.setItem('token', JSON.stringify(newRefreshToken));

      return token;
    });
  }

  return token;
};

const ErrorHandler = error => {
  if (error.status === 401) {
    // TODO: remove this from here and find a proper solution to reset the state
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
    throw error;
  } else {
    throw error;
  }
};

const tokenFromRefreshToken = async (oldToken, error, callback, ...params) => {
  if (error.status === 401) {
    try {
      return oldToken
        .refresh()
        .then(refreshToken => {
          _.merge(oldToken, refreshToken);
          const { client, ...newRefreshToken } = refreshToken;
          localStorage.setItem('token', JSON.stringify(newRefreshToken));

          return callback(...params); // retry now with the current token
        })
        .catch(failure => {
          return ErrorHandler({ ...failure, status: 401 }); // any error coming here should be treated as unauthorized
        });
    } catch (err) {
      return ErrorHandler(err);
    }
  } else {
    return ErrorHandler(error);
  }
};

export const getMethod = async (endpoint, signal, headers = {}) => {
  return checkToken()
    .then(() => {
      const signedOptions = token.sign({
        method: 'GET',
        headers: {
          ...{
            'Content-Type': 'application/json',
          },
          ...headers,
        },
      });

      return fetch(endpoint, { ...signedOptions, signal });
    })
    .then(response => {
      if (!response.ok) throw response;

      return response.json();
    })
    .catch(error =>
      // try fetching a new token from the refresh token, the active token was probably invalidated server side
      tokenFromRefreshToken(token, error, getMethod, endpoint, signal, headers)
    );
};

export const createMethod = async (endpoint, body = '', headers = {}) => {
  return checkToken()
    .then(() => {
      const signedOptions = token.sign({
        method: 'POST',
        body,
        headers: {
          ...{
            'Content-Type': 'application/json',
          },
          ...headers,
        },
      });

      return fetch(endpoint, signedOptions);
    })
    .then(response => {
      if (!response.ok) throw response;

      return response.json();
    })
    .catch(error =>
      // try fetching a new token from the refresh token, the active token was probably invalidated server side
      tokenFromRefreshToken(token, error, createMethod, endpoint, body, headers)
    );
};

export const updateMethod = async (endpoint, body = '', headers = {}) => {
  return checkToken()
    .then(() => {
      const signedOptions = token.sign({
        method: 'PUT',
        body,
        headers: {
          ...{
            'Content-Type': 'application/json',
          },
          ...headers,
        },
      });

      return fetch(endpoint, signedOptions);
    })
    .then(response => {
      if (!response.ok) throw response;

      return response.json();
    })
    .catch(error =>
      // try fetching a new token from the refresh token, the active token was probably invalidated server side
      tokenFromRefreshToken(token, error, updateMethod, endpoint, body, headers)
    );
};

export const deleteMethod = async (endpoint, headers = {}) => {
  return checkToken()
    .then(() => {
      const signedOptions = token.sign({
        method: 'DELETE',
        headers: {
          ...{
            'Content-Type': 'application/json',
          },
          ...headers,
        },
      });

      return fetch(endpoint, signedOptions);
    })
    .then(response => {
      if (!response.ok) throw response;

      return response;
    })
    .catch(error =>
      // try fetching a new token from the refresh token, the active token was probably invalidated server side
      tokenFromRefreshToken(token, error, deleteMethod, endpoint, headers)
    );
};

export const patchMethod = async (endpoint, body = '', headers = {}) => {
  return checkToken()
    .then(() => {
      const signedOptions = token.sign({
        method: 'PATCH',
        body,
        headers: {
          ...{
            'Content-Type': 'application/json',
          },
          ...headers,
        },
      });

      return fetch(endpoint, signedOptions);
    })
    .then(response => {
      if (!response.ok) throw response;

      return response.json();
    })
    .catch(error =>
      // try fetching a new token from the refresh token, the active token was probably invalidated server side
      tokenFromRefreshToken(token, error, updateMethod, endpoint, body, headers)
    );
};

export const basicGetMethod = async (endpoint, headers = {}) => {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      ...headers,
      ...{
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_API_BASIC_AUTH}`,
      },
    },
  })
  .then(response => {
    if (!response.ok) {
      throw response;
    }

    return response.json();
  })
  .catch(async error => ErrorHandler(error));
};

export const getUserInfo = async id => {
  return getMethod(`${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${id}?include=person,roles,availableRoutes`)
    .then(responseData => {
      return responseData;
    })
    .catch(error => {
      throw error;
    });
};

export const loginAction = async (username, password) => {
  oAuth2Service.options.accessTokenUri = `${
    process.env.REACT_APP_PUBLIC_API_ENDPOINT
  }/oauth/token?tz=${dayjs().format()}`;

  return oAuth2Service.owner
  .getToken(username, password)
  .then(oauth2Token => {
    if (!_.isEmpty(oauth2Token.accessToken)) {
      _.merge(token, oauth2Token);
      const { client, ...newUser } = oauth2Token;

      localStorage.setItem('token', JSON.stringify(newUser));
    }

    return oauth2Token;
  })
  .catch(error => {
    throw error;
  });
};

export const removeCookies = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  token = {};
};

export const resolveErrorPromise = async error => {
  const response = await Promise.resolve(error)
    .then(message => {
      return message.json();
    })
    .then(data => {
      let detail;
      try {
        detail = JSON.parse(data.detail)
      } catch (e) {
        detail = data.detail;
      }

      return {
        ...data,
        detail
      };
    })
    .catch(() => {
      if (error?.message) {
        return { details: error.message };
      }

      return {};
    });

  return response;
};
