import { combineReducers } from 'redux';
import {
  GET_ISSUER_LIST,
  MANAGE_ISSUER,
  DELETE_ISSUER,
  RESET_ISSUER,
  RESET,
  RESET_ISSUER_LIST,
  MANAGE_ALL_ISSUER_RESTRICTIONS,
  MANAGE_ISSUER_RESTRICTIONS,
  DELETE_ISSUER_RESTRICTIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';
import { createSelector } from 'reselect';

const INIT_STATE = {
  data: {
    data: [],
    meta: {
      pagination: {
        total: 0,
      },
    },
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ISSUER_LIST:
    case MANAGE_ALL_ISSUER_RESTRICTIONS:
    case MANAGE_ISSUER_RESTRICTIONS:
    case DELETE_ISSUER_RESTRICTIONS:
      return { ...state, loading: true };
    case SUCCESS(GET_ISSUER_LIST):
    case SUCCESS(MANAGE_ALL_ISSUER_RESTRICTIONS):
    case SUCCESS(MANAGE_ISSUER_RESTRICTIONS):
    case SUCCESS(DELETE_ISSUER_RESTRICTIONS):
      return { ...state, data: action.payload, source: action.source, loading: false };
    case FAILED(GET_ISSUER_LIST):
    case FAILED(MANAGE_ALL_ISSUER_RESTRICTIONS):
    case FAILED(MANAGE_ISSUER_RESTRICTIONS):
    case FAILED(DELETE_ISSUER_RESTRICTIONS):
      return { ...state, loading: false };
    case DELETE_ISSUER:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_ISSUER):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_ISSUER):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_ISSUER):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_ISSUER_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_ISSUER = {
  success: false,
  error: false,
  isSubmitting: false,
};

const Issuer = (state = INIT_STATE_ISSUER, action) => {
  switch (action.type) {
    case MANAGE_ISSUER:
      return { ...state, isSubmitting: true, success: false, error: false };
    case SUCCESS(MANAGE_ISSUER):
      return { ...state, isSubmitting: false, success: true, error: false };
    case FAILED(MANAGE_ISSUER):
      return { ...state, isSubmitting: false, success: true, error: true };
    case RESET_ISSUER:
      return INIT_STATE_ISSUER;
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  Issuer,
});

const getIssuerListFromState = (state) => state.Broker.Issuers.List.data;
export const getIssuerListSelector = createSelector([getIssuerListFromState], (issuers) => {
  return issuers;
});

const getLoadingIssuerListFromState = (state) => state.Broker.Issuers.List;
export const getLoadingIssuerListSelector = createSelector([getLoadingIssuerListFromState], (issuers) => {
  return issuers.loading;
});
