import { CREATE_ENCRYPTION, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { createSelector } from 'reselect';

const INIT_STATE_VIEW = {
  loading: false,
  success: false,
  encryptedValues: [],
  decryptedValues: [],
};

const Encryption = (state = INIT_STATE_VIEW, action) => {
  switch (action.type) {
    case CREATE_ENCRYPTION:
      return { ...state, loading: true };
    case SUCCESS(CREATE_ENCRYPTION):
      return { ...state, encryptedValues: action.payload.encryptedValues, decryptedValues: action.payload.decryptedValues, loading: false };
    case FAILED(CREATE_ENCRYPTION):
      return { ...state, loading: false, error: true };
    case RESETED(CREATE_ENCRYPTION):
    case RESET:
      return INIT_STATE_VIEW;
    default:
      return state;
  }
};

export default Encryption;

export const getEncryptionEncryptedValues = createSelector([state => state.Encryption.encryptedValues], encryptedValues => encryptedValues);
export const getEncryptionDecryptedValues = createSelector([state => state.Encryption.decryptedValues], decryptedValues => decryptedValues);
export const getEncryptionLoading = createSelector([state => state.Encryption.loading], loading => loading);
