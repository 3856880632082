import { GET_LOGIN_HISTORY_LIST, RESET_LOGIN_HISTORY_LIST } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const getLoginHistoryList = userId => ({
  type: GET_LOGIN_HISTORY_LIST,
  payload: userId,
});

export const getLoginHistoryListSuccess = data => ({
  type: SUCCESS(GET_LOGIN_HISTORY_LIST),
  payload: data,
});

export const getLoginHistoryListFailed = (error = null) => ({
  type: FAILED(GET_LOGIN_HISTORY_LIST),
  payload: error,
});

export const resetLoginHistoryList = () => ({
  type: RESET_LOGIN_HISTORY_LIST,
});
