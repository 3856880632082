import {
  GET_ADVERTISMENT,
  MANAGE_ADVERTISMENT,
  RESET_ADVERTISMENT,
} from 'constants/actionTypes';

import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getAdvertisment = () => ({
  type: GET_ADVERTISMENT,
});

export const getAdvertismentSuccess = data => ({
  type: SUCCESS(GET_ADVERTISMENT),
  payload: data,
});

export const getAdvertismentFailed = () => ({
  type: FAILED(GET_ADVERTISMENT),
});

export const manageAdvertisment = data => ({
  type: MANAGE_ADVERTISMENT,
  payload: data,
});

export const manageAdvertismentSuccess = data => ({
  type: SUCCESS(MANAGE_ADVERTISMENT),
  payload: data,
});

export const manageAdvertismentFailed = (error = null) => ({
  type: FAILED(MANAGE_ADVERTISMENT),
  payload: error,
});

export const resetAdvertisment = () => ({
  type: RESET_ADVERTISMENT,
});
