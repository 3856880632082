import { all, cancel, fork, take, put, delay, select } from 'redux-saga/effects';
import { RESET, RESET_INACTIVITY, WILL_LOG_OUT_INACTIVITY_TWO_MINUTES } from 'constants/actionTypes';
import { resetInactivity, willLogOutInactivityTwoMinutes } from 'redux/auth/inactivity/actions';
import { logoutUser } from 'redux/auth/auth/actions';
import { getAuthUserHasInfoSelector } from 'redux/auth/auth/reducers';
import dayjs from 'dayjs';

function* inactivityTimer() {
  // check if the user is logged in
  const isLoggedIn = yield select(getAuthUserHasInfoSelector);
  if (isLoggedIn) {
    localStorage.setItem('inactivity', JSON.stringify({ lastActionTime: dayjs().toJSON() }));
    yield delay(10000); // 10s delay so we don't overload redux, most of the tasks get cancelled in this timeframe
    yield put(resetInactivity());
    yield delay(770000); // 12 min 50 s

    yield put(willLogOutInactivityTwoMinutes());
    yield delay(120000); // 2 min

    // total 15 min of inactivity triggers log out
    yield put(logoutUser());
  }
}

export function* watchAllActions() {
  let lastTask;
  while (true) {
    const action = yield take('*');
    if (action.type !== RESET_INACTIVITY && action.type !== WILL_LOG_OUT_INACTIVITY_TWO_MINUTES) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(inactivityTimer, action);
    }
  }
}

function* inactivitySaga() {
  while (true) {
    const tasks = yield all([fork(watchAllActions)]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default inactivitySaga;
