import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import { processDataForForm } from 'helpers/formaters';

import { getMethod, updateMethod, resolveErrorPromise, RESETED } from 'helpers';

import { GET_SUBMISSION_ISSUES_LIST, VIEW_SUBMISSION_ISSUE, MANAGE_SUBMISSION_ISSUE, RESET, FAILED_MESSAGE } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import { getSubmissionIssuesListSuccess, getSubmissionIssuesListFailed, viewSubmissionIssueSuccess, viewSubmissionIssueFailed, manageSubmissionIssueSuccess, manageSubmissionIssueFailed } from './actions';

function* submissionIssuesListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/submission-issue?include=groups,producer.person,person`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getSubmissionIssuesListSuccess(response));
  } catch (error) {
    yield put(getSubmissionIssuesListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* submissionIssueView(action) {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/submission-issue/${action.payload}?include=groups.plans,producer.user,producer.producerStates,edeApplication,demographics.person`;
    const response = yield call(getMethod, request, abortController.signal);

    yield put(viewSubmissionIssueSuccess(processDataForForm(response)));
  } catch (error) {
    yield put(viewSubmissionIssueFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* submissionIssueManage(action) {
    try {
      const response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/submission-issue/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
      
      yield put(manageSubmissionIssueSuccess(processDataForForm(response)));
    } catch (error) {
      const message = yield call(resolveErrorPromise, error);
      if (message?.detail?.formErrorMessages) {
        yield put(manageSubmissionIssueFailed(message));
      } else {
        yield put(manageSubmissionIssueFailed());
        yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
      }
    }
  }

export function* watchGetSubmissionIssuesList() {
  yield takeLeading(GET_SUBMISSION_ISSUES_LIST, submissionIssuesListGet);
}

export function* watchViewSubmissionIssue() {
  yield takeLeading(VIEW_SUBMISSION_ISSUE, submissionIssueView);
}

export function* watchManageSubmissionIssue() {
    yield takeLeading(MANAGE_SUBMISSION_ISSUE, submissionIssueManage);
  }

function* submissionIssueSaga() {
  while (true) {
    const tasks = yield all([fork(watchGetSubmissionIssuesList), fork(watchViewSubmissionIssue), fork(watchManageSubmissionIssue)]);

    yield all([take(RESET), take(RESETED(GET_SUBMISSION_ISSUES_LIST)), take(RESETED(VIEW_SUBMISSION_ISSUE)), take(RESETED(MANAGE_SUBMISSION_ISSUE))]);

    yield cancel(tasks);
  }
}

export default submissionIssueSaga;
