import {
  GET_BENEFIT_SECTION_LIST,
  DELETE_BENEFIT_SECTION,
  GET_BENEFIT_SECTION,
  MANAGE_BENEFIT_SECTION,
  RESET_BENEFIT_SECTION,
  RESET_BENEFIT_SECTION_LIST,
} from 'constants/actionTypes';

import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getBenefitSectionList = (data = {}) => ({
  type: GET_BENEFIT_SECTION_LIST,
  payload: data,
});

export const getBenefitSectionListSuccess = data => ({
  type: SUCCESS(GET_BENEFIT_SECTION_LIST),
  payload: data,
});

export const getBenefitSectionListFailed = data => ({
  type: FAILED(GET_BENEFIT_SECTION_LIST),
  payload: data,
});

export const resetBenefitSectionList = () => ({
  type: RESET_BENEFIT_SECTION_LIST,
});

export const deleteBenefitSection = (benefitSectionId) => ({
  type: DELETE_BENEFIT_SECTION,
  payload: benefitSectionId,
});

export const deleteBenefitSectionSuccess = () => ({
  type: SUCCESS(DELETE_BENEFIT_SECTION),
});

export const deleteBenefitSectionFailed = () => ({
  type: FAILED(DELETE_BENEFIT_SECTION),
});

export const resetDeleteBenefitSection = () => ({
  type: RESETED(DELETE_BENEFIT_SECTION),
});

export const getBenefitSection = (benefitSectionId) => ({
  type: GET_BENEFIT_SECTION,
  payload: benefitSectionId,
});

export const getBenefitSectionSuccess = data => ({
  type: SUCCESS(GET_BENEFIT_SECTION),
  payload: data,
});

export const getBenefitSectionFailed = () => ({
  type: FAILED(GET_BENEFIT_SECTION),
});

export const manageBenefitSection = data => ({
  type: MANAGE_BENEFIT_SECTION,
  payload: data,
});

export const manageBenefitSectionSuccess = data => ({
  type: SUCCESS(MANAGE_BENEFIT_SECTION),
  payload: data,
});

export const manageBenefitSectionFailed = (error = null) => ({
  type: FAILED(MANAGE_BENEFIT_SECTION),
  payload: error,
});

export const resetBenefitSection = () => ({
  type: RESET_BENEFIT_SECTION,
});
