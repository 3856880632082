import { all, call, fork, put, cancelled, take, cancel, takeLatest, takeLeading } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { createMethod, getMethod, resolveErrorPromise } from 'helpers';
import {
  GET_CREDIT_LOG_LIST,
  GET_AGENCY_CREDIT_LOG_LIST,
  FAILED_MESSAGE,
  RESET,
  CREATE_CREDIT_LOG,
} from 'constants/actionTypes';
import {
  getCreditLogListSuccess,
  getCreditLogListFailed,
  getAgencyCreditLogListSuccess,
  getAgencyCreditLogListFailed,
  createCreditLogSuccess,
  createCreditLogFailed,
} from 'redux/actions';

function* creditLogGetList(action) {
  const abortController = new AbortController();
  try {
    let request;
    request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-transaction?include=user.person,creditLog.agency,`;
    let params = {};
    params = { ...params, ...action.payload.filters, ...action.payload.pagination, ...action.payload.order };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getCreditLogListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getCreditLogListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencyCreditLogGetList(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload}/credit-transaction?include=user.person,creditLog`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAgencyCreditLogListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAgencyCreditLogListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* creditLogCreate(action) {
  const abortController = new AbortController();
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agency}/credit-log`,
      JSON.stringify(action.payload)
    );
    yield put(createCreditLogSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(createCreditLogFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetCreditLogList() {
  yield takeLatest(GET_CREDIT_LOG_LIST, creditLogGetList);
}

export function* watchAgencyGetCreditLogList() {
  yield takeLatest(GET_AGENCY_CREDIT_LOG_LIST, agencyCreditLogGetList);
}

export function* watchGetCreditLogCreate() {
  yield takeLeading(CREATE_CREDIT_LOG, creditLogCreate);
}

function* creditLogSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetCreditLogList),
      fork(watchAgencyGetCreditLogList),
      fork(watchGetCreditLogCreate),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default creditLogSaga;
