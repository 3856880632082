import {
  RESET,
  GET_ACTIVE_SESSION_LIST,
  REVOKE_ACTIVE_SESSION,
  REVOKE_ALL_ACTIVE_SESSIONS,
  RESET_ACTIVE_SESSION_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
  },
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVE_SESSION_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_ACTIVE_SESSION_LIST):
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: false,
        error: false,
      };
    case FAILED(GET_ACTIVE_SESSION_LIST):
      return { ...state, loading: false };
    case REVOKE_ACTIVE_SESSION:
      return { ...state, loading: true };
    case SUCCESS(REVOKE_ACTIVE_SESSION):
      return { ...state, loading: false, success: true, error: false };
    case FAILED(REVOKE_ACTIVE_SESSION):
      return { ...state, loading: false, success: true, error: true };
    case REVOKE_ALL_ACTIVE_SESSIONS:
      return { ...state, loading: true };
    case SUCCESS(REVOKE_ALL_ACTIVE_SESSIONS):
      return { ...state, loading: false, success: true, error: false };
    case FAILED(REVOKE_ALL_ACTIVE_SESSIONS):
      return { ...state, loading: false, success: true, error: true };
    case RESET_ACTIVE_SESSION_LIST:
      return { ...INIT_STATE };
    case RESET:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

export default List;
