export const GET_DASHBOARD_ALERT_LIST = 'GET_DASHBOARD_ALERT_LIST';

export const RESET_DASHBOARD_ALERT_LIST = 'RESET_DASHBOARD_ALERT_LIST';

export const DELETE_DASHBOARD_ALERT = 'DELETE_DASHBOARD_ALERT';

export const GET_DASHBOARD_ALERT = 'GET_DASHBOARD_ALERT';

export const RESET_DASHBOARD_ALERT = 'RESET_DASHBOARD_ALERT';

export const MANAGE_DASHBOARD_ALERT = 'MANAGE_DASHBOARD_ALERT';
