import { WILL_LOG_OUT_INACTIVITY_TWO_MINUTES, RESET_INACTIVITY, REINITIALIZE_INACTIVITY } from 'constants/actionTypes';

export const willLogOutInactivityTwoMinutes = () => ({
  type: WILL_LOG_OUT_INACTIVITY_TWO_MINUTES,
});

export const reinitializeInactivity = () => ({
  type: REINITIALIZE_INACTIVITY,
});

export const resetInactivity = () => ({
  type: RESET_INACTIVITY,
});
