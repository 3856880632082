import {
  GET_TERMS_AND_CONDITIONS_LIST,
  IMPORT_TERMS_AND_CONDITIONS_CSV,
  RESET_TERMS_AND_CONDITIONS_LIST,
  DELETE_TERMS_AND_CONDITIONS,
  REACTIVATE_TERMS_AND_CONDITIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getTermsAndConditionsList = (data) => ({
  type: GET_TERMS_AND_CONDITIONS_LIST,
  payload: data,
});

export const getTermsAndContionsListSuccess = (data) => ({
  type: SUCCESS(GET_TERMS_AND_CONDITIONS_LIST),
  payload: data,
});

export const getTermsAndConditionsListFailed = (error) => ({
  type: FAILED(GET_TERMS_AND_CONDITIONS_LIST),
  payload: error,
});

export const resetTermsAndConditionsList = () => ({
  type: RESET_TERMS_AND_CONDITIONS_LIST,
});

export const importTermsAndConditionsCSV = (data) => ({
  type: IMPORT_TERMS_AND_CONDITIONS_CSV,
  payload: data,
});

export const importTermsAndConditionsCSVSuccess = (data) => ({
  type: SUCCESS(IMPORT_TERMS_AND_CONDITIONS_CSV),
  payload: data,
});

export const importTermsAndConditionsCSVFailed = (error) => ({
  type: FAILED(IMPORT_TERMS_AND_CONDITIONS_CSV),
  payload: error,
});

export const deleteTermsAndConditions = (data) => ({
  type: DELETE_TERMS_AND_CONDITIONS,
  payload: data,
});

export const deleteTermsAndConditionsSuccess = () => ({
  type: SUCCESS(DELETE_TERMS_AND_CONDITIONS),
});

export const deleteTermsAndConditionsFailed = () => ({
  type: FAILED(DELETE_TERMS_AND_CONDITIONS),
});

export const deleteTermsAndConditionsReset = () => ({
  type: RESETED(DELETE_TERMS_AND_CONDITIONS),
});

export const reactivateTermsAndConditions = (data) => ({
  type: REACTIVATE_TERMS_AND_CONDITIONS,
  payload: data,
});

export const reactivateTermsAndConditionsSuccess = () => ({
  type: SUCCESS(REACTIVATE_TERMS_AND_CONDITIONS),
});

export const reactivateTermsAndConditionsFailed = () => ({
  type: FAILED(REACTIVATE_TERMS_AND_CONDITIONS),
});

export const reactivateTermsAndConditionsReset = () => ({
  type: RESETED(REACTIVATE_TERMS_AND_CONDITIONS),
});
