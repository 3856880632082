import _ from 'lodash';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';

dayjs.extend(LocalizedFormat);
dayjs.extend(CustomParseFormat);

export const processDataForForm = data => {
  const newData = data.data;

  if (_.isArray(data.data)) {
    newData.forEach((item, index) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(item)) {
        if (typeof value === 'object' && _.has(value, 'data')) {
          newData[index][key] = processDataForForm(value);
        }
      }
    });
  } else {
    for (const [key, value] of Object.entries(newData)) {
      if (typeof value === 'object' && _.has(value, 'data')) {
        newData[key] = processDataForForm(value);
      }
    }
  }

  return newData;
};

export const formatDayJsV2 = (data, isTimeStamp = false) => {
  // To activate locale detection uncomment the line below and don't forget to import 'dayjs/locale/ro';
  // dayjs.locale(window.navigator.language);

  if (isTimeStamp) {
    return dayjs(data).format('L LT');
  }

  return dayjs(data).format('L');
};

export const formatMoney = amount => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(amount);
};

export const formatPhoneNumber = (phoneNumberString, intlCode = false) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${intlCode ? '+1 ' : ''}(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return '';
};

export const formatSSN = ssn => {
  if (ssn === '') {
    return ssn;
  }

  const count = (ssn.match(/-/g) || []).length;
  if (count === 2) {
    return ssn;
  }

  const first = ssn.substring(0, 3);
  const second = ssn.substring(3, 5);
  const third = ssn.substring(5);

  return `${first}-${second}-${third}`;
};

export const formatCell = (cell) => {
  return <span className="position-relative font-secondary-bold">{cell}</span>;
}
