import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  DELETE_CARRIER_FORMS,
  GET_CARRIER_FORMS,
  GET_CARRIER_FORMS_LIST,
  MANAGE_CARRIER_FORMS,
  RESET,
  RESET_CARRIER_FORMS,
  RESET_CARRIER_FORMS_LIST,
} from 'constants/actionTypes';
import { FAILED, RESETED, SUCCESS } from 'helpers/actionTypesUtils';

const INIT_STATE_LIST = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  success: false,
  errors: false,
  delete: {
    loading: false,
    success: false,
    errors: false,
  }
}

const List = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_CARRIER_FORMS_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_CARRIER_FORMS_LIST):
      return { ...state, data: action.payload, loading: false, success: true };
    case FAILED(GET_CARRIER_FORMS_LIST):
      return { ...state, loading: false, error: true };
    case DELETE_CARRIER_FORMS:
      return { ...state, delete: { ...state.delete, loading: true } };
    case SUCCESS(DELETE_CARRIER_FORMS):
      return { ...state, delete: { ...state.delete, loading: false, success: true } };
    case FAILED(DELETE_CARRIER_FORMS):
      return { ...state, delete: { ...state.delete, loading: false, errors: action.payload } };
    case RESETED(DELETE_CARRIER_FORMS):
      return { ...state, delete: { ...INIT_STATE_LIST.delete } };
    case RESET:
    case RESET_CARRIER_FORMS_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_CARRIER_FORMS = {
  success: false,
  loading: false,
  errors: false,
  data: {
    id: '',
    issuerName: '',
    description: '',
    document: '',
  },
};

const CarrierForm = (state = INIT_STATE_CARRIER_FORMS, action) => {
  switch (action.type) {
    case GET_CARRIER_FORMS:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_CARRIER_FORMS):
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILED(GET_CARRIER_FORMS):
      return { ...state, loading: false, error: true };
    case MANAGE_CARRIER_FORMS:
      return { ...state, loading: true };
    case SUCCESS(MANAGE_CARRIER_FORMS):
      return {
        ...state,
        success: true,
        loading: false,
        data: action.payload.data,
      };
    case FAILED(MANAGE_CARRIER_FORMS):
      return { ...state, errors: action.payload, loading: false };
    case RESET_CARRIER_FORMS:
    case RESET:
      return INIT_STATE_CARRIER_FORMS;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  CarrierForm,
});


const getCarrierFormListFromState = (state) => state.Broker.CarrierForm.List;

export const getCarrierFormListSelector = createSelector(
  [getCarrierFormListFromState],
  carrierForm => carrierForm.data.data
);

export const getCarrierFormListDeleteSelector = createSelector(
  [getCarrierFormListFromState],
  carrierForm => carrierForm.delete
)

export const getCarrierFormListMetaSelector = createSelector(
  [getCarrierFormListFromState],
  carrierForm => carrierForm.data.meta
);

export const getCarrierFormListLoadingSelector = createSelector(
  [getCarrierFormListFromState],
  carrierForm => carrierForm.loading
);

const getCarrierFormFromState = (state) => state.Broker.CarrierForm.CarrierForm;

export const getCarrierFormSelector = createSelector(
  [getCarrierFormFromState],
  carrierForm => carrierForm.data
);

export const getCarrierFormLoadingSelector = createSelector(
  [getCarrierFormFromState],
  carrierForm => carrierForm.loading
);

export const getCarrierFormSuccessSelector = createSelector(
  [getCarrierFormFromState],
  carrierForm => carrierForm.success
);
