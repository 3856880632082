import { all, call, put, takeLeading, takeLatest, cancelled, take, cancel } from 'redux-saga/effects';
import { clearMessages, flashErrorMessage } from 'redux-flash';
import { getMethod, patchMethod, updateMethod, createMethod, resolveErrorPromise } from 'helpers';
import {
  GET_QUESTION_LIST,
  IMPORT_CSV,
  DELETE_QUESTION,
  REACTIVATE_QUESTION,
  GET_QUESTION,
  MANAGE_QUESTION,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getQuestionListSuccess,
  getQuestionListFailed,
  deleteQuestionSuccess,
  deleteQuestionFailed,
  reactivateQuestionSuccess,
  reactivateQuestionFailed,
  getQuestionSuccess,
  getQuestionFailed,
  manageQuestionSuccess,
  manageQuestionFailed,
  importCSVSuccess,
  importCSVFailed,
} from 'redux/actions';

function* questionGetList() {
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question`;
    // request data
    const response = yield call(getMethod, request);
    yield put(getQuestionListSuccess(response));
  } catch (error) {
    yield put(getQuestionListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* questionDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question/${action.payload.id}`,
      JSON.stringify({ status: action.payload.status })
    );
    yield put(deleteQuestionSuccess()); // save to redux
  } catch (error) {
    yield put(deleteQuestionFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* questionReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question/${action.payload.id}`,
      JSON.stringify({ status: action.payload.status })
    );
    yield put(reactivateQuestionSuccess()); // save to redux
  } catch (error) {
    yield put(reactivateQuestionFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* questionGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question/${action.payload.questionId}?include=tags`,
      abortController.signal
    );
    yield put(getQuestionSuccess(data)); // save to redux
  } catch (error) {
    yield put(getQuestionFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* questionManage(action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question/${action.payload.id}?include=tags`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/question?include=tags`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageQuestionSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageQuestionFailed(message));
    } else {
      yield put(manageQuestionFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* sendImportCSV(action) {
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/import-csv`,
      JSON.stringify(action.payload)
    );

    yield put(importCSVSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(importCSVFailed(message));
    } else if (message?.detail) {
      yield put(importCSVFailed(message.detail));
      yield put(clearMessages());
      yield put(flashErrorMessage(message.detail, { timeout: 30000 }));
    } else {
      yield put(importCSVSuccess());
    }
  }
}

export function* watchGetQuestions() {
  yield takeLeading(GET_QUESTION_LIST, questionGetList);
}
export function* watchDeleteQuestion() {
  yield takeLeading(DELETE_QUESTION, questionDelete);
}
export function* watchReactivateQuestion() {
  yield takeLeading(REACTIVATE_QUESTION, questionReactivate);
}
export function* watchGetQuestion() {
  yield takeLatest(GET_QUESTION, questionGet);
}
export function* watchManageQuestion() {
  yield takeLeading(MANAGE_QUESTION, questionManage);
}
export function* watchSendImportCSV() {
  yield takeLeading(IMPORT_CSV, sendImportCSV);
}
function* usersSaga() {
  while (true) {
    const tasks = yield all([
      call(watchGetQuestions),
      call(watchDeleteQuestion),
      call(watchReactivateQuestion),
      call(watchGetQuestion),
      call(watchManageQuestion),
      call(watchSendImportCSV),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default usersSaga;
