import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import {getMethod, patchMethod, RESETED, resolveErrorPromise} from 'helpers';

import {
  GET_APPLICATION_LIST,
  VIEW_APPLICATION,
  RESET,
  FAILED_MESSAGE,
  DELETE_APPLICATION,
  REACTIVATE_APPLICATION,
  GET_OFF_EX_APPLICATION_LIST,
  VIEW_OFF_EX_APPLICATION,
  GET_PRIVATE_EX_LIST,
  GET_QUOTING_ACTIVITY_LIST,
  GET_AGENT_OF_RECORD_LIST,
} from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import {processDataForForm} from "helpers/formaters";
import {
  getApplicationListSuccess,
  getApplicationListFailed,
  viewApplicationSuccess,
  viewApplicationFailed,
  deleteApplicationSuccess,
  deleteApplicationFailed,
  reactivateApplicationSuccess,
  reactivateApplicationFailed,
  getOffExApplicationListSuccess,
  getOffExApplicationListFailed,
  getPrivateExListSuccess,
  getPrivateExListFailed,
  viewOffExApplicationFailed,
  viewOffExApplicationSuccess,
  getQuotingActivityListSuccess,
  getQuotingActivityListFailed,
  getAgentOfRecordListSuccess,
  getAgentOfRecordListFailed,
 } from './actions';

function* applicationListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ede-application?include=person,producer.agency,producer.person,dmisviDocuments,enrollments&started=1`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getApplicationListSuccess(response));
  } catch (error) {
    yield put(getApplicationListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* applicationView(action) {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ede-application/${action.payload}?include=salesExternalDetails,quote.demographics`;
    const response = yield call(getMethod, request, abortController.signal);

    yield put(viewApplicationSuccess(processDataForForm(response)));
  } catch (error) {
    yield put(viewApplicationFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* applicationDelete(action) {
  try {
    yield call(
        patchMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ede-application/${action.payload.id}`,
        JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteApplicationSuccess());
  } catch (error) {
    yield put(deleteApplicationFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* applicationReactivate(action) {
  try {
    yield call(
        patchMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ede-application/${action.payload.id}`,
        JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(reactivateApplicationSuccess());
  } catch (error) {
    yield put(reactivateApplicationFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}


function* offExApplicationListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/quote?include=demographics.person.contacts,demographics.person.addresses,demographics.planDemographics,producer.person.contacts,producer.agentNumbers,producer.agency,groups.plans&isApplication=1&applicationType=offExchange`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getOffExApplicationListSuccess(response));
  } catch (error) {
    yield put(getOffExApplicationListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* privateExListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/private-exchange?include=producer.person,producer.agency,producer.agentNumbers`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getPrivateExListSuccess(response));
  } catch (error) {
    yield put(getPrivateExListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* offExApplicationView(action) {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/quote/${action.payload}?include=demographics.person.contacts,demographics.person.addresses,demographics.planDemographics,producer.person.contacts,producer.agentNumbers,producer.agency,groups.plans,salesExternalDetails&isApplication=1`;
    const response = yield call(getMethod, request, abortController.signal);

    yield put(viewOffExApplicationSuccess(processDataForForm(response)));
  } catch (error) {
    yield put(viewOffExApplicationFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* quotingActivityListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/quoting-activity`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `?${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getQuotingActivityListSuccess(response));
  } catch (error) {
    yield put(getQuotingActivityListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agentOfRecordListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agent-of-record`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `?${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getAgentOfRecordListSuccess(response));
  } catch (error) {
    yield put(getAgentOfRecordListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetApplicationList() {
  yield takeLeading(GET_APPLICATION_LIST, applicationListGet);
}

export function* watchViewApplication() {
  yield takeLeading(VIEW_APPLICATION, applicationView);
}

export function* watchDeleteApplication() {
  yield takeLeading(DELETE_APPLICATION, applicationDelete);
}

export function* watchReactivateApplication() {
  yield takeLeading(REACTIVATE_APPLICATION, applicationReactivate);
}

export function* watchGetOffExApplicationList() {
  yield takeLeading(GET_OFF_EX_APPLICATION_LIST, offExApplicationListGet);
}

export function* watchViewOffExApplication() {
  yield takeLeading(VIEW_OFF_EX_APPLICATION, offExApplicationView);
}

export function* watchGetPrivateExList() {
  yield takeLeading(GET_PRIVATE_EX_LIST, privateExListGet);
}

export function* watchGetQuotingActivityList() {
  yield takeLeading(GET_QUOTING_ACTIVITY_LIST, quotingActivityListGet);
}

export function* watchGetAgentOfRecordList() {
  yield takeLeading(GET_AGENT_OF_RECORD_LIST, agentOfRecordListGet);
}
function* applicationSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetApplicationList),
      fork(watchViewApplication),
      fork(watchDeleteApplication),
      fork(watchReactivateApplication),
      fork(watchGetOffExApplicationList),
      fork(watchViewOffExApplication),
      fork(watchGetQuotingActivityList),
      fork(watchGetPrivateExList),
      fork(watchGetAgentOfRecordList),
    ]);

    yield all([take(RESET), take(RESETED(GET_APPLICATION_LIST)), take(RESETED(VIEW_APPLICATION)), take(RESETED(GET_PRIVATE_EX_LIST)), take(RESETED(GET_OFF_EX_APPLICATION_LIST)), take(RESETED(GET_QUOTING_ACTIVITY_LIST)), take(RESETED(GET_AGENT_OF_RECORD_LIST))]);

    yield cancel(tasks);
  }
}

export default applicationSaga;
