import {
  GET_CREDIT_PACKAGE_LIST,
  EXPORT_CREDIT_PACKAGE,
  DELETE_CREDIT_PACKAGE,
  GET_CREDIT_PACKAGE,
  MANAGE_CREDIT_PACKAGE,
  RESET_CREDIT_PACKAGE,
} from 'constants/creditPackage';

import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getCreditPackageList = (agencyId = null) => ({
  type: GET_CREDIT_PACKAGE_LIST,
  payload: agencyId,
});

export const getCreditPackageListSuccess = data => ({
  type: SUCCESS(GET_CREDIT_PACKAGE_LIST),
  payload: data,
});

export const getCreditPackageListFailed = () => ({
  type: FAILED(GET_CREDIT_PACKAGE_LIST),
});

export const resetCreditPackageList = () => ({
  type: RESETED(GET_CREDIT_PACKAGE_LIST),
});

export const exportCreditPackages = (data = {}) => ({
  type: EXPORT_CREDIT_PACKAGE,
  payload: data,
});

export const deleteCreditPackage = data => ({
  type: DELETE_CREDIT_PACKAGE,
  payload: data,
});

export const deleteCreditPackageSuccess = () => ({
  type: SUCCESS(DELETE_CREDIT_PACKAGE),
});

export const deleteCreditPackageFailed = () => ({
  type: FAILED(DELETE_CREDIT_PACKAGE),
});

export const deleteCreditPackageReset = () => ({
  type: RESETED(DELETE_CREDIT_PACKAGE),
});

export const getCreditPackage = creditPackageId => ({
  type: GET_CREDIT_PACKAGE,
  payload: { creditPackageId },
});

export const getCreditPackageSuccess = creditPackage => ({
  type: SUCCESS(GET_CREDIT_PACKAGE),
  payload: creditPackage,
});

export const getCreditPackageFailed = () => ({
  type: FAILED(GET_CREDIT_PACKAGE),
});

export const manageCreditPackage = year => ({
  type: MANAGE_CREDIT_PACKAGE,
  payload: year,
});

export const manageCreditPackageSuccess = data => ({
  type: SUCCESS(MANAGE_CREDIT_PACKAGE),
  payload: data,
});

export const manageCreditPackageFailed = (error = null) => ({
  type: FAILED(MANAGE_CREDIT_PACKAGE),
  payload: error,
});

export const resetCreditPackage = () => ({
  type: RESET_CREDIT_PACKAGE,
});
