export const getDefaultProducerAgentNumbers = () => {
  return [
    'NPN',
    'FFMUserId',
    'IHCAgentNumber',
    'NationalGeneralAgentNumber',
    'PivotHealthAgentNumber',
    'SSOId',
    'UnitedHealthcareAgentNumber',
    'CignaAgentNumber',
    'AflacAgentNumber',
  ];
}

export const getAgentNumberNaming = agentNumberName => {
  const mapping = {
    NPN: 'NPN',
    FFMUserId : 'FFM User ID',
    IHCAgentNumber: 'IHC Agent Number',
    NationalGeneralAgentNumber: 'National General Agent Number',
    PivotHealthAgentNumber: 'Pivot Health Agent Number',
    SSOId: 'SSOId',
    UnitedHealthcareAgentNumber: 'FL Agent License No.',
    CignaAgentNumber: 'Cigna Agent Number',
    AflacAgentNumber: 'Aflac Agent Number',
  };

  return mapping[agentNumberName] || 'Agent Number';
};