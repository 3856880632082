import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  GET_QUESTION_LIST,
  RESET_QUESTION_LIST,
  DELETE_QUESTION,
  REACTIVATE_QUESTION,
  GET_QUESTION,
  MANAGE_QUESTION,
  RESET_QUESTION,
  IMPORT_CSV,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  data: { data: [] },
  loading: false,
  importedCSV: false,
  errors: null,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTION_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_QUESTION_LIST):
      return { ...state, data: action.payload, error: false, loading: false, importedCSV: false };
    case FAILED(GET_QUESTION_LIST):
      return { ...state, error: action.payload, loading: false, importedCSV: false };
    case DELETE_QUESTION:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_QUESTION):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_QUESTION):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_QUESTION):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_QUESTION:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_QUESTION):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_QUESTION):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_QUESTION):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case IMPORT_CSV:
      return { ...state, loading: true };
    case SUCCESS(IMPORT_CSV):
      return { ...state, importedCSV: true, error: false, loading: false, errors: null };
    case FAILED(IMPORT_CSV):
      return { ...state, error: action.payload, loading: false, errors: action.payload };
    case RESET:
    case RESET_QUESTION_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const QUESTION_INIT_STATE = {
  question: {
    data: {
      id: '',
      question: '',
      isKickoff: false,
      description: '',
      questionType: '',
      questionValues: '',
      validAnswers: '',
      tags: {
        data: [],
      },
    },
  },
  loading: false,
  isSubmitting: false,
  success: false,
  errors: null,
  needsRedirect: false,
};

const Question = (state = QUESTION_INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTION:
      return { ...state, loading: true, needsRedirect: false };
    case SUCCESS(GET_QUESTION):
      return { ...state, question: action.payload, loading: false, needsRedirect: false };
    case FAILED(GET_QUESTION):
      return { ...state, loading: false, needsRedirect: true };
    case MANAGE_QUESTION:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_QUESTION):
      return { ...state, question: action.payload, errors: null, success: true, isSubmitting: false };
    case FAILED(MANAGE_QUESTION):
      return { ...state, errors: action.payload, isSubmitting: false };
    case RESET_QUESTION:
    case RESET:
      return QUESTION_INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  Question,
});

// selectors
const getQuestionQuestionFromState = state => state.Eligibility.Questions.Question.question;
export const getQuestionSelector = createSelector([getQuestionQuestionFromState], question => {
  return {
    ...question.data,
    tags: question.data.tags.data,
  };
});
