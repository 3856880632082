import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import { GET_LOG_LIST, EXPORT_LOGS, RESET_LOG_LIST } from 'constants/actionTypes';

export const getLogList = (data = {}) => ({
  type: GET_LOG_LIST,
  data,
});

export const getLogListSuccess = data => ({
  type: SUCCESS(GET_LOG_LIST),
  payload: data,
});

export const getLogListFailed = () => ({
  type: FAILED(GET_LOG_LIST),
});

export const resetLogList = () => ({
  type: RESET_LOG_LIST,
});

export const exportLogs = (data = {}) => ({
  type: EXPORT_LOGS,
  payload: data,
});
