import { CREATE_ENCRYPTION } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const createEncryption = data => ({
  type: CREATE_ENCRYPTION,
  payload: data,
});

export const createEncryptionSuccess = data => ({
  type: SUCCESS(CREATE_ENCRYPTION),
  payload: data,
});

export const createEncryptionFailed = data => ({
  type: FAILED(CREATE_ENCRYPTION),
  payload: data,
});
