import { CONSUMER_CONSENT_LIST, GET_CONSUMER_CONSENT_LIST } from 'constants/consumerConsent';
import { FAILED, RESETED, SUCCESS } from 'helpers';

export const getConsumerConsentList = (payload, callback = '') => ({
  type: GET_CONSUMER_CONSENT_LIST,
  payload: { ...payload, callback },
});

export const getConsumerConsentListSuccess = (data) => ({
  type: SUCCESS(GET_CONSUMER_CONSENT_LIST),
  payload: data,
});

export const getConsumerConsentListFailed = () => ({
  type: FAILED(GET_CONSUMER_CONSENT_LIST),
});

export const resetConsumerConsentList = () => ({
  type: RESETED(CONSUMER_CONSENT_LIST),
});