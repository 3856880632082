import { all } from 'redux-saga/effects';
import globalAlertSaga from 'redux/auth/global-alert/saga';
import dashboardAlertSaga from 'redux/auth/dashboard-alert/saga';
import activitySaga from 'redux/activity/saga';
import routeSaga from 'redux/acl/route/saga';
import consumerConsentSaga from 'redux/broker/consumer-consent/saga';
import authSaga from './auth/auth/saga';
import logsSaga from './log/log/saga';
import agenciesSaga from './broker/agency/saga';
import clientSaga from './acl/client/saga';
import roleSaga from './acl/role/saga';
import userSaga from './acl/user/saga';
import questionSaga from './eligibility/question/saga';
import termsAndConditionsSaga from './eligibility/terms-and-conditions/saga';
import forgetPassword from './auth/forget-password/saga';
import changePassword from './auth/change-password/saga';
import producerSaga from './broker/producer/saga';
import locationSaga from './location/saga';
import activeSessionsSaga from './auth/active-session/saga';
import issuerSaga from './broker/issuer/saga';
import apiSaga from './broker/api/saga';
import lineOfCoverageSaga from './broker/line-of-coverage/saga';
import baaSaga from './broker/baa/saga';
import baaSignatureSaga from './broker/baa-signature/saga';
import creditPackagesSaga from './billing/credit-package/saga';
import creditLogSaga from './broker/credit-log/saga';
import loginHistory from './auth/login-history/saga';
import policySaga from './policy/saga';
import inactivitySaga from './auth/inactivity/saga';
import effectiveDatesSaga from './effective-dates/saga';
import applicationSaga from "./application/saga";
import twoFaVerifySaga from './auth/two-fa/saga';
import encryptionSaga from './encryption/saga';
import carrierSaga from './broker/carrier/saga';
import reportingSaga from './reporting/saga';
import zipCodeSaga from './zip-code/saga';
import benefitSectionSaga from './broker/benefit-section/saga';
import dmiSaga from './dmi/saga';
import quoteSaga from './quote/saga';
import quotePinSaga from './pin/saga';
import ideonLogSaga from './log/ideonLog/saga';
import advertisingSaga from './advertising/saga';
import carrierFormsSaga from './broker/carrier-form/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    activeSessionsSaga(),
    clientSaga(),
    forgetPassword(),
    changePassword(),
    logsSaga(),
    agenciesSaga(),
    roleSaga(),
    userSaga(),
    producerSaga(),
    questionSaga(),
    termsAndConditionsSaga(),
    locationSaga(),
    issuerSaga(),
    apiSaga(),
    lineOfCoverageSaga(),
    baaSaga(),
    baaSignatureSaga(),
    creditPackagesSaga(),
    creditLogSaga(),
    loginHistory(),
    policySaga(),
    inactivitySaga(),
    effectiveDatesSaga(),
    globalAlertSaga(),
    dashboardAlertSaga(),
    activitySaga(),
    applicationSaga(),
    twoFaVerifySaga(),
    encryptionSaga(),
    carrierSaga(),
    reportingSaga(),
    routeSaga(),
    zipCodeSaga(),
    benefitSectionSaga(),
    dmiSaga(),
    consumerConsentSaga(),
    quoteSaga(),
    quotePinSaga(),
    ideonLogSaga(),
    advertisingSaga(),
    carrierFormsSaga(),
  ]);
}
