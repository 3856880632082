import { GET_CREDIT_LOG_LIST, GET_AGENCY_CREDIT_LOG_LIST, CREATE_CREDIT_LOG } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getCreditLogList = (data = {}) => ({
  type: GET_CREDIT_LOG_LIST,
  payload: data,
});

export const getCreditLogListSuccess = data => ({
  type: SUCCESS(GET_CREDIT_LOG_LIST),
  payload: data,
});

export const getCreditLogListFailed = error => ({
  type: FAILED(GET_CREDIT_LOG_LIST),
  payload: error,
});

export const resetCreditLogList = () => ({
  type: RESETED(GET_CREDIT_LOG_LIST),
});

export const getAgencyCreditLogList = (data = {}) => ({
  type: GET_AGENCY_CREDIT_LOG_LIST,
  payload: data,
});

export const getAgencyCreditLogListSuccess = data => ({
  type: SUCCESS(GET_AGENCY_CREDIT_LOG_LIST),
  payload: data,
});

export const getAgencyCreditLogListFailed = error => ({
  type: FAILED(GET_AGENCY_CREDIT_LOG_LIST),
  payload: error,
});

export const resetAgencyCreditLogList = () => ({
  type: RESETED(GET_AGENCY_CREDIT_LOG_LIST),
});

export const createCreditLog = data => ({
  type: CREATE_CREDIT_LOG,
  payload: data,
});

export const createCreditLogSuccess = () => ({
  type: SUCCESS(CREATE_CREDIT_LOG),
});

export const createCreditLogFailed = error => ({
  type: FAILED(CREATE_CREDIT_LOG),
  payload: error,
});

export const resetCreateCreditLog = () => ({
  type: RESETED(CREATE_CREDIT_LOG),
});
