import React, { lazy, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DOCUMENT_TITLE } from 'constants/global';
import { useSelector } from 'react-redux';
import {
  getAuthUserAvailableRoutesSelector,
  getAuthUserRolesNameSelector
} from 'redux/auth/auth/reducers';
import { isUserAuthenticated, routeHasAccessRights } from './helpers/index';

const Login = lazy(() => import('./pages/auth/auth/container/LoginContainer'));
const Logout = lazy(() => import('./pages/auth/auth/container/LogoutContainer'));
const Page404 = lazy(() => import('./components/Page404'));
const LockScreen = lazy(() => import('./components/LockScreen'));
const AdminDashboard = lazy(() => import('./pages/application/dashboard/container/DashboardContainer'));
const MyAccount = lazy(() => import('./pages/application/account/container/AccountContainer'));
const MySettings = lazy(() => import('./pages/application/account/presentational/Settings'));
const LogList = lazy(() => import('./pages/log/log/container/LogListContainer'));
const ClientList = lazy(() => import('./pages/acl/client/container/ClientListContainer'));
const Client = lazy(() => import('./pages/acl/client/container/ClientContainer'));
const RoleList = lazy(() => import('./pages/acl/role/container/RoleListContainer'));
const Role = lazy(() => import('./pages/acl/role/container/RoleContainer'));
const ForgetPassword = lazy(() => import('./pages/auth/forget-password/container/ForgetPasswordContainer'));
const ChangePassword = lazy(() => import('./pages/auth/change-password/container/ChangePasswordContainer'));
const UserList = lazy(() => import('pages/acl/user/container/UserListContainer'));
const User = lazy(() => import('pages/acl/user/container/UserContainer'));
const AgencyList = lazy(() => import('pages/broker/agency/container/AgencyListContainer'));
const Agency = lazy(() => import('pages/broker/agency/container/AgencyContainer'));
const AgencySettings = lazy(() => import('pages/broker/agency/container/AgencySettingContainer'));
const AgencyRestriction = lazy(() => import('pages/broker/agency/container/AgencyRestrictionContainer'));
const AgencyChild = lazy(() => import('pages/broker/agency/container/AgencyChildContainer'));
const AgencyChildList = lazy(() => import('pages/broker/agency/container/AgencyChildListContainer'));
const ProducerList = lazy(() => import('pages/broker/producer/container/ProducerListContainer'));
const Producer = lazy(() => import('pages/broker/producer/container/ProducerContainer'));
const AllProducersList = lazy(() => import('pages/broker/all-producers/container/AllProducersListContainer'));
const ProducerRestrictions = lazy(() => import('pages/broker/producer/container/ProducerRestrictionsContainer'));
const QuestionList = lazy(() => import('pages/eligibility/question/container/QuestionListContainer'));
const Question = lazy(() => import('pages/eligibility/question/container/QuestionContainer'));
const TermsAndConditionsList = lazy(() =>
  import('pages/eligibility/terms-and-conditions/container/TermsAndConditionsListContainer')
);
const IssuerList = lazy(() => import('pages/broker/issuer/container/IssuerListContainer'));
const ApiList = lazy(() => import('pages/broker/api/container/ApiListContainer'));
const Api = lazy(() => import('pages/broker/api/container/ApiContainer'));
const LineOfCoverageList = lazy(() =>
  import('pages/broker/line-of-coverage/container/LineOfCoverageListContainer')
);
const LineOfCoverage = lazy(() => import('pages/broker/line-of-coverage/container/LineOfCoverageContainer'));
const GlobalAlertList = lazy(() => import('pages/auth/global-alert/container/GlobalAlertListContainer'));
const GlobalAlert = lazy(() => import('pages/auth/global-alert/container/GlobalAlertContainer'));
const EffectiveDatesList = lazy(() => import('pages/effective-dates/container/EffectiveDatesListContainer'));
const EffectiveDates = lazy(() => import('pages/effective-dates/container/EffectiveDatesContainer'));
const DocumentList = lazy(() => import('pages/broker/baa/container/DocumentListContainer'));
const BaaSignatureList = lazy(() => import('pages/broker/baa-signature/container/BaaSignatureListContainer'));
const BaaSignature = lazy(() => import('pages/broker/baa-signature/container/BaaSignatureContainer'));
const DocumentLogList = lazy(() => import('pages/broker/baa/container/DocumentLogListContainer'));
const Document = lazy(() => import('pages/broker/baa/container/DocumentContainer'));
const AgencyCreditLogList = lazy(() => import('pages/broker/credit-log/container/AgencyCreditLogListContainer'));
const CreditTransactionList = lazy(() => import('pages/broker/credit-log/container/CreditTransactionListContainer'));
const CreditPackageList = lazy(() => import('pages/billing/CreditPackage/container/CreditPackageListContainer'));
const CreditPackage = lazy(() => import('pages/billing/CreditPackage/container/CreditPackageContainer'));
const AgencyCreditPackageList = lazy(() => import('pages/billing/CreditPackage/container/AgencyCreditPacageListContainer'));
const PoliciesList = lazy(() => import('pages/policy/container/PoliciesListContainer'));
const Policy = lazy(() => import('pages/policy/container/PolicyContainer'));
const SubmissionIssuesList = lazy(() => import('pages/quote/container/SubmissionIssuesListContainer'));
const SubmissionIssue = lazy(() => import('pages/quote/container/SubmissionIssueContainer'));
const ActivityList = lazy(() => import('pages/activity/container/ActivityListContainer'));
const ApplicationsList = lazy(() => import('pages/application/application/container/ApplicationsListContainer'));
const OffExApplicationsList = lazy(() => import('pages/application/application/container/OffExApplicationsListContainer'));
const QuotingActivityList = lazy(() => import('pages/application/application/container/QuotingActivityListContainer'));
const OffExApplication = lazy(() => import('pages/application/application/container/OffExApplicationContainer'));
const Application = lazy(() => import('pages/application/application/container/ApplicationContainer'));
const PrivateExcList = lazy(() => import('pages/application/application/container/PrivateExListContainer'));
const TwoFA = lazy(() => import('pages/auth/two-fa/container/TwoFAContainer'));
const Encryption = lazy(() => import('pages/encryption/container/EncryptionContainer'));
const CarrierList = lazy(() => import('pages/broker/carrier/container/CarrierListContainer'));
const Carrier = lazy(() => import('pages/broker/carrier/container/CarrierContainer'));
const ReportingList = lazy(() => import('pages/reporting/container/ReportingListContainer'));
const Impersonate = lazy(() => import('pages/acl/impersonate/container/ImpersonateContainer'));
const MoveProducer = lazy(() => import('pages/broker/producer/container/MoveProducerContainer'));
const ZipCodeList = lazy(() => import('pages/zip-code/container/ZipCodeListContainer'));
const ZipCode = lazy(() => import('pages/zip-code/container/ZipCodeContainer'));
const BenefitSectionList = lazy(() => import('pages/broker/benefit-section/container/BenefitSectionListContainer'));
const BenefitSection = lazy(() => import('pages/broker/benefit-section/container/BenefitSectionContainer'));
const DMIs = lazy(() => import('pages/dmi/container/DmiListing'));
const AgentOfRecord = lazy(() => import('pages/application/application/container/AgentOfRecord'));
const ConsumerConsentList = lazy(() => import('pages/broker/consumer-consent/container/ConsumerConsentListContainer'));
const AgentRepList = lazy(() => import('pages/application/application/container/AgentRepListContainer'));
const AgentRep = lazy(() => import('pages/application/application/container/AgentRepContainer'));
const PinLogList = lazy(() => import('./pages/pin-log/container/PinLogListContainer'));
const ConsumerConsentsList = lazy(() => import('pages/consumer-consent/container/ConsumerConsentListContainer'));
const IdeonLogList = lazy(() => import('pages/log/ideonLog/container/IdeonLogListContainer'));
const DashboardAlertList = lazy(() => import('pages/application/dashboard/container/DashboardAlertListContainer'));
const DashboardAlert = lazy(() => import('pages/application/dashboard/container/DashboardAlertContainer'));
const Advertising = lazy(() => import('pages/application/advertising/container/AdvertisingContainer'));
const CarrierFormsList = lazy(() => import('pages/broker/carrier-forms/container/CarrierFormsListContainer'));
const CarrierForms = lazy(() => import('pages/broker/carrier-forms/container/CarrierFormsContainer'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { routeName, apiRoute, roles } = rest;

  document.title = useMemo(() => routeName ? `${DOCUMENT_TITLE} - ${routeName}` : DOCUMENT_TITLE, [routeName]);

  const availableRoutes = useSelector(getAuthUserAvailableRoutesSelector);
  const authRoles = useSelector(getAuthUserRolesNameSelector);

  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthTokenValid = isUserAuthenticated();
        if (!isAuthTokenValid) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        if (!routeHasAccessRights(apiRoute, roles, availableRoutes, authRoles)) {
          return <Redirect to={{ pathname: '/account' }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

const routesObj = [
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/locked', name: 'Lock Screen', component: LockScreen, route: Route },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/account" />,
    route: PrivateRoute,
  },

  { path: '/dashboard', exact: true, name: 'Dashboard', component: AdminDashboard, route: PrivateRoute },

  {
    path: '/account',
    exact: true,
    name: 'My Account',
    component: MyAccount,
    route: PrivateRoute,
    children: [
      {
        path: '/settings',
        exact: true,
        name: 'Account Settings',
        component: MySettings,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/forgot-password',
    exact: true,
    name: 'Forgot Password',
    component: ForgetPassword,
    route: Route,
    children: [
      {
        path: '/:id',
        exact: true,
        name: 'Change Password',
        component: ChangePassword,
        route: Route,
      },
    ],
  },

  {
    path: '/log',
    apiRoute: '/v1/log',
    name: 'Logs',
    component: LogList,
    route: PrivateRoute
  },

  {
    path: '/pin-log',
    exact: true,
    name: 'Pin Logs',
    component: PinLogList,
    route: PrivateRoute,
  },

  {
    path: '/policies',
    apiRoute: '/v1/policy',
    exact: true,
    name: 'Policies',
    component: PoliciesList,
    route: PrivateRoute,
    children: [
      {
        path: '/view/:policyId?',
        exact: true,
        name: 'View Policy',
        component: Policy,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/manage-submission-issues',
    apiRoute: '/v1/manage-submission-issues',
    exact: true,
    name: 'Manage Submission Issues',
    component: SubmissionIssuesList,
    route: PrivateRoute,
    children: [
      {
        path: '/view/:submissionIssueId?',
        exact: true,
        name: 'View Submission Issue',
        component: SubmissionIssue,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/reporting',
    apiRoute: '/v1/reporting',
    exact: true,
    name: 'Reporting',
    component: ReportingList,
    route: PrivateRoute,
  },

  {
    path: '/agent-rep',
    apiRoute: '/v1/agent-rep',
    exact: true,
    name: 'Agent Rep',
    component: AgentRepList,
    route: PrivateRoute,
    children: [
      {
        path: '/:agencyId?/:producerId?',
        exact: true,
        name: 'Agent Rep View',
        component: AgentRep,
        route: PrivateRoute,
      },
    ],
  },


  {
    path: '/applications',
    apiRoute: '/v1/ede-application',
    exact: true,
    name: 'Applications',
    component: ApplicationsList,
    route: PrivateRoute,
    children: [
      {
        path: '/view/:applicationId?',
        exact: true,
        name: 'View Application',
        component: Application,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/off-exchange-applications',
    apiRoute: '/v1/quote',
    exact: true,
    name: 'Off Exchange Applications',
    component: OffExApplicationsList,
    route: PrivateRoute,
    children: [
      {
        path: '/view/:applicationId?',
        exact: true,
        name: 'View Off Exchange Application',
        component: OffExApplication,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/private-exchange',
    apiRoute: '/v1/private-exchange',
    exact: true,
    name: 'PrivateExchange',
    component: PrivateExcList,
    route: PrivateRoute,
  },
  {
    path: '/quoting-activity',
    apiRoute: '/v1/quoting-activity',
    exact: true,
    name: 'Quoting Activity List',
    component: QuotingActivityList,
    route: PrivateRoute,
  },

  {
    path: '/activity-errors',
    apiRoute: '/v1/activity-errors',
    exact: true,
    name: 'Activity Errors',
    component: ActivityList,
    route: PrivateRoute,
  },

  {
    path: '/client',
    apiRoute: '/v1/client',
    exact: true,
    name: 'Clients',
    component: ClientList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:clientId?',
        exact: true,
        name: 'Manage Client',
        component: Client,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/role',
    apiRoute: '/v1/role',
    roles: ['Administrator'],
    exact: true,
    name: 'Roles',
    component: RoleList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:id?',
        exact: true,
        name: 'Manage Role',
        component: Role,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/user',
    apiRoute: '/v1/user',
    roles: ['Administrator'],
    exact: true,
    name: 'Users',
    component: UserList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:userId?',
        exact: true,
        name: 'Manage User',
        component: User,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/producers',
    apiRoute: '/v1/producers',
    exact: true,
    name: 'Producers',
    component: AllProducersList,
    route: PrivateRoute,
  },

  {
    path: '/move-producer',
    apiRoute: '/v1/move-producer',
    exact: true,
    name: 'Move Producer',
    component: MoveProducer,
    route: PrivateRoute,
  },

  {
    path: '/agency',
    apiRoute: '/v1/agency',
    exact: true,
    name: 'Agencies',
    component: AgencyList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:agencyId?',
        exact: true,
        name: 'Manage Agency',
        component: Agency,
        route: PrivateRoute,
        children: [
          {
            path: '/child',
            exact: true,
            name: 'Child Agencies',
            component: AgencyChildList,
            route: PrivateRoute,
            children: [
              {
                path: '/manage/:childId?',
                exact: true,
                name: 'Manage Child Agency',
                component: AgencyChild,
                route: PrivateRoute,
              },
            ],
          },
          {
            path: '/producer',
            exact: true,
            name: 'Producers',
            component: ProducerList,
            route: PrivateRoute,
            children: [
              {
                path: '/manage/:producerId?',
                exact: true,
                name: 'Manage Producer',
                component: Producer,
                route: PrivateRoute,
                children: [
                  {
                    path: '/restrictions',
                    exact: true,
                    name: 'Producer Restrictions',
                    component: ProducerRestrictions,
                    route: PrivateRoute,
                  },
                  {
                    path: '/document',
                    exact: true,
                    name: 'Documents',
                    component: DocumentList,
                    route: PrivateRoute,
                  },
                  {
                    path: '/consumer-consent',
                    exact: true,
                    name: 'Consumer Consent',
                    component: ConsumerConsentList,
                    route: PrivateRoute,
                  }
                ],
              },
            ],
          },
          {
            path: '/restriction',
            exact: true,
            name: 'Agency Restrictions',
            component: AgencyRestriction,
            route: PrivateRoute,
          },
          {
            path: '/settings',
            exact: true,
            name: 'Agency Settings',
            component: AgencySettings,
            route: PrivateRoute,
          },
          {
            path: '/billing',
            exact: true,
            route: PrivateRoute,
            children: [
              {
                path: '/credit-transaction',
                exact: true,
                name: 'Agency Transactions',
                component: AgencyCreditLogList,
                route: PrivateRoute,
              },
              {
                path: '/credit-package',
                exact: true,
                name: 'Billing',
                component: AgencyCreditPackageList,
                route: PrivateRoute,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/question',
    apiRoute: '/v1/question',
    exact: true,
    name: 'Questions',
    component: QuestionList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:questionId?',
        exact: true,
        name: 'Manage Question',
        component: Question,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/terms-and-conditions',
    apiRoute: '/v1/terms-and-conditions',
    exact: true,
    name: 'Terms And Conditions',
    component: TermsAndConditionsList,
    route: PrivateRoute,
  },

  {
    path: '/issuer',
    apiRoute: '/v1/issuer',
    exact: true,
    name: 'Issuer',
    component: IssuerList,
    route: PrivateRoute,
  },

  {
    path: '/api',
    apiRoute: '/v1/api',
    exact: true,
    name: 'APIs',
    component: ApiList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:apiId?',
        exact: true,
        name: 'Manage API',
        component: Api,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/line-of-coverage',
    apiRoute: '/v1/line-of-coverage',
    exact: true,
    name: 'Lines Of Coverage',
    component: LineOfCoverageList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:lineOfCoverageId?',
        exact: true,
        name: 'Manage Line of Coverage',
        component: LineOfCoverage,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/global-alert',
    apiRoute: '/v1/global-alerts',
    exact: true,
    name: 'Global Alerts',
    component: GlobalAlertList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:globalAlertId?',
        exact: true,
        name: 'Manage Global Alert',
        component: GlobalAlert,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/dashboard-alert',
    apiRoute: '/v1/dashboard-alerts',
    exact: true,
    name: 'Dashboard',
    component: DashboardAlertList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:dashboardAlertId?',
        exact: true,
        name: 'Manage Dashboard Alert',
        component: DashboardAlert,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/advertising',
    apiRoute: '/v1/advertising',
    exact: true,
    name: 'Advertising',
    component: Advertising,
    route: PrivateRoute,
  },

  {
    path: '/effective-dates',
    apiRoute: '/v1/effective-dates',
    exact: true,
    name: 'Effective Dates',
    component: EffectiveDatesList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:effectiveDateId?',
        exact: true,
        name: 'Manage Effective Dates',
        component: EffectiveDates,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/carrier',
    apiRoute: '/v1/carrier',
    exact: true,
    name: 'Manage Carrier',
    component: CarrierList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:carrierId?',
        exact: true,
        name: 'Manage Carrier',
        component: Carrier,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/carrier-forms',
    apiRoute: '/v1/carrier-forms',
    exact: true,
    name: 'Manage Carrier Forms',
    component: CarrierFormsList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:carrierFormsId?',
        exact: true,
        name: 'Manage Carrier Forms',
        component: CarrierForms,
        route: PrivateRoute,
      },
    ],
  },
  
  {
    path: '/benefit-section',
    apiRoute: '/v1/benefit-section',
    exact: true,
    name: 'Manage Benefit Sections',
    component: BenefitSectionList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:benefitSectionId?',
        exact: true,
        name: 'Manage Benefit Sections',
        component: BenefitSection,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/document',
    apiRoute: '/v1/document',
    exact: true,
    name: 'Documents',
    component: DocumentList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:documentId?',
        exact: true,
        name: 'Manage Document',
        component: Document,
        route: PrivateRoute,
        children: [
          {
            path: '/document-log',
            exact: true,
            name: 'Document Log',
            component: DocumentLogList,
            route: PrivateRoute,
          },
        ],
      },
    ],
  },

  {
    path: '/baa-signature',
    apiRoute: '/v1/signature',
    exact: true,
    name: 'Owner Signatures',
    component: BaaSignatureList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:signatureId?',
        exact: true,
        name: 'Manage Owner Signature',
        component: BaaSignature,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/credit-package',
    exact: true,
    name: 'Credit Packages',
    component: CreditPackageList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:creditPackageId?',
        exact: true,
        name: 'Manage Credit Package',
        component: CreditPackage,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/credit-transaction',
    exact: true,
    name: 'Credit Transactions',
    component: CreditTransactionList,
    route: PrivateRoute,
  },
  {
    path: '/encryption',
    apiRoute: '/v1/encryption',
    exact: true,
    name: 'Encrypt / Decrypt',
    component: Encryption,
    route: PrivateRoute,
  },

  {
    path: '/verification-code',
    exact: true,
    name: 'Verification Code',
    component: TwoFA,
    route: Route,
  },

  {
    path: '/impersonate',
    apiRoute: '/v1/impersonate',
    exact: true,
    name: 'Impersonate',
    component: Impersonate,
    route: PrivateRoute,
  },

  {
    path: '/zip-code',
    apiRoute: '/v1/zip-code',
    exact: true,
    name: 'Zip Codes',
    component: ZipCodeList,
    route: PrivateRoute,
    children: [
      {
        path: '/manage/:zipCodeId?',
        exact: true,
        name: 'Manage Zip Code',
        component: ZipCode,
        route: PrivateRoute,
      },
    ],
  },

  {
    path: '/dmi-tracking',
    apiRoute: '/v1/dmi-tracking',
    exact: true,
    name: 'DMI Tracking',
    component: DMIs,
    route: PrivateRoute,
  },

  {
    path: '/agent-of-record',
    apiRoute: '/v1/agent-of-record',
    exact: true,
    name: 'AOR Tracking',
    component: AgentOfRecord,
    route: PrivateRoute,
  },

  {
    path: '/consumer-consent',
    apiRoute: '/v1/consumer-consent',
    exact: true,
    name: 'Consumer Consent',
    component: ConsumerConsentsList,
    route: PrivateRoute,
  },

  {
    path: '/ideon-log',
    apiRoute: '/v1/ideon-log',
    exact: true,
    name: 'Ideon Logs',
    component: IdeonLogList,
    route: PrivateRoute,
  },

  {
    name: '404',
    component: Page404,
    route: Route,
  },
];

const getRoutes = (routes, previousPath = '') => {
  let flattenRoutes = [];

  routes.forEach((route) => {
    const alteredRoute = route;
    alteredRoute.path = `${previousPath}${route.path}`;
    flattenRoutes.push(alteredRoute);
    if ('children' in alteredRoute) {
      const childrenRoutes = getRoutes(alteredRoute.children, alteredRoute.path);
      if (childrenRoutes.length > 0) {
        flattenRoutes = [...flattenRoutes, ...childrenRoutes];
      }
    }
  });

  return flattenRoutes;
};

const routes = getRoutes(routesObj);

export { routes, PrivateRoute, routesObj };
