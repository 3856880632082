import { GET_DMI_LIST } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getDMIList = data => ({
  type: GET_DMI_LIST,
  payload: data,
});

export const getDMIListSuccess = data => ({
  type: SUCCESS(GET_DMI_LIST),
  payload: data,
});

export const getDMIListFailed = data => ({
  type: FAILED(GET_DMI_LIST),
  payload: data,
});

export const resetDMIList = () => ({
  type: RESETED(GET_DMI_LIST),
});
