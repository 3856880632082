import { GET_EFFECTIVE_DATES_LIST, GET_EFFECTIVE_DATES, MANAGE_EFFECTIVE_DATES, DELETE_EFFECTIVE_DATES, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  successDeleted: false,
  data: {
    data: [],
  },
};

const EffectiveDatesList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_EFFECTIVE_DATES_LIST:
      return { ...state, loading: true, successDeleted: false };
    case SUCCESS(GET_EFFECTIVE_DATES_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_EFFECTIVE_DATES_LIST):
      return { ...state, loading: false };
    case DELETE_EFFECTIVE_DATES:
      return { ...state, loading: true };
    case SUCCESS(DELETE_EFFECTIVE_DATES):
      return { ...state, loading: false, successDeleted: true };
    case FAILED(DELETE_EFFECTIVE_DATES):
      return { ...state, loading: false, successDeleted: false };
    case RESETED(GET_EFFECTIVE_DATES_LIST):
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE = {
  loading: false,
  success: false,
  errors: false,
  isSubmitting: false,
  data: {
    data: {
      id: '',
      effectiveDate: '',
      fromDate: '',
      toDate: '',
    },
  },
};

const EffectiveDates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EFFECTIVE_DATES:
      return { ...state, loading: true };
    case SUCCESS(GET_EFFECTIVE_DATES):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_EFFECTIVE_DATES):
      return { ...state, loading: false, errors: true };
    case MANAGE_EFFECTIVE_DATES:
      return { ...state, isSubmitting: true };
    case SUCCESS(MANAGE_EFFECTIVE_DATES):
      return { ...state, data: action.payload, isSubmitting: false, success: true };
    case FAILED(MANAGE_EFFECTIVE_DATES):
      return { ...state, errors: action.payload, isSubmitting: false };
    case RESETED(GET_EFFECTIVE_DATES):
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  EffectiveDatesList,
  EffectiveDates,
});

/**
 * LIST SELECTORS
 */
const getEffectiveDatesListFromState = state => state.EffectiveDates.EffectiveDatesList.data;
export const getEffectiveDatesListSelector = createSelector([getEffectiveDatesListFromState], effectiveDatesList => effectiveDatesList);

export const getEffectiveDatesListLoadingSelector = createSelector(
  [state => state.EffectiveDates.EffectiveDatesList.loading],
  loading => loading
);

export const getEffectiveDatesListSuccessDeletedSelector = createSelector(
  [state => state.EffectiveDates.EffectiveDatesList.successDeleted],
  successDeleted => successDeleted
);

/**
 * VIEW SELECTORS
 */

const getEffectiveDatesFromState = state => state.EffectiveDates.EffectiveDates.data;
export const getEffectiveDatesSelector = createSelector([getEffectiveDatesFromState], effectiveDate => effectiveDate);

export const getEffectiveDatesLoadingSelector = createSelector(
  state => state.EffectiveDates.EffectiveDates.loading,
  loading => loading
);

export const getEffectiveDatesErrorsSelector = createSelector(
  state => state.EffectiveDates.EffectiveDates.errors,
  errors => errors
);

export const getEffectiveDatesIsSubmittingSelector = createSelector(
  state => state.EffectiveDates.EffectiveDates.isSubmitting,
  isSubmitting => isSubmitting
);

export const getEffectiveDatesSuccessSelector = createSelector(
  state => state.EffectiveDates.EffectiveDates.success,
  success => success
);
