import { all, call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { getMethod } from 'helpers';

import { GET_LOCATION, RESET } from 'constants/actionTypes';

import { getLocationFailed, getLocationSuccess } from './actions';

function* getLocation(action) {
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/location`;

    const paramString = new URLSearchParams(action.payload).toString();

    if (paramString.length) {
      request += `?${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request);

    yield put(getLocationSuccess(response, action.payload.source));
  } catch (error) {
    yield put(getLocationFailed('Invalid credentials'));
  }
}

export function* watchGetLocation() {
  yield takeEvery(GET_LOCATION, getLocation);
}

function* locationSaga() {
  while (true) {
    const tasks = yield all([fork(watchGetLocation)]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default locationSaga;
