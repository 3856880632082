import {
  GET_USER_LIST,
  GET_USER,
  DELETE_USER,
  REACTIVATE_USER,
  MANAGE_USER,
  RESET_USER,
  RESET_USER_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getUserList = data => ({
  type: GET_USER_LIST,
  payload: data,
});

export const getUserListSuccess = data => ({
  type: SUCCESS(GET_USER_LIST),
  payload: data,
});

export const getUserListFailed = error => ({
  type: FAILED(GET_USER_LIST),
  payload: error,
});

export const getUser = userId => ({
  type: GET_USER,
  payload: { userId },
});

export const getUserSuccess = user => ({
  type: SUCCESS(GET_USER),
  payload: user,
});

export const getUserFailed = error => ({
  type: FAILED(GET_USER),
  payload: error,
});

export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = () => ({
  type: SUCCESS(DELETE_USER),
});

export const deleteUserFailed = () => ({
  type: FAILED(DELETE_USER),
});

export const deleteUserReset = () => ({
  type: RESETED(DELETE_USER),
});

export const reactivateUser = data => ({
  type: REACTIVATE_USER,
  payload: data,
});

export const reactivateUserSuccess = () => ({
  type: SUCCESS(REACTIVATE_USER),
});

export const reactivateUserFailed = () => ({
  type: FAILED(REACTIVATE_USER),
});

export const reactivateUserReset = () => ({
  type: RESETED(REACTIVATE_USER),
});

export const manageUser = user => ({
  type: MANAGE_USER,
  payload: user,
});

export const manageUserSuccess = user => ({
  type: SUCCESS(MANAGE_USER),
  payload: user,
});

export const manageUserFailed = (error = null) => ({
  type: FAILED(MANAGE_USER),
  payload: error,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const resetUserList = () => ({
  type: RESET_USER_LIST,
});
