export const GET_AGENCY_LIST = 'GET_AGENCY_LIST';

export const RESET_AGENCY_LIST = 'RESET_AGENCY_LIST';

export const DELETE_AGENCY = 'DELETE_AGENCY';

export const REACTIVATE_AGENCY = 'REACTIVATE_AGENCY';

export const APPROVE_AGENCY = 'APPROVE_AGENCY';

export const GET_AGENCY = 'GET_AGENCY';

export const RESET_AGENCY = 'RESET_AGENCY';

export const MANAGE_AGENCY = 'MANAGE_AGENCY';

export const GET_AGENCY_POSSIBLE_PARENTS = 'GET_AGENCY_POSSIBLE_PARENTS';

export const RESET_AGENCY_POSSIBLE_PARENTS = 'RESET_AGENCY_POSSIBLE_PARENTS';

// CHILD AGENCY BELOW

export const GET_CHILD_AGENCY_LIST = 'GET_CHILD_AGENCY_LIST';

export const RESET_CHILD_AGENCY_LIST = 'RESET_CHILD_AGENCY_LIST';

export const DELETE_CHILD_AGENCY = 'DELETE_CHILD_AGENCY';

export const REACTIVATE_CHILD_AGENCY = 'REACTIVATE_CHILD_AGENCY';

export const APPROVE_CHILD_AGENCY = 'APPROVE_CHILD_AGENCY';

export const GET_CHILD_AGENCY = 'GET_CHILD_AGENCY';

export const RESET_CHILD_AGENCY = 'RESET_CHILD_AGENCY';

export const MANAGE_CHILD_AGENCY = 'MANAGE_CHILD_AGENCY';

// AGENCY RESTRICTIONS

export const GET_AGENCY_RESTRICTION_LIST = 'GET_AGENCY_RESTRICTION_LIST';

export const GET_AGENCY_RESTRICTION_FORM_CONFIG = 'GET_AGENCY_RESTRICTION_FORM_CONFIG';

export const GET_AGENCY_RESTRICTION = 'GET_AGENCY_RESTRICTION';

export const MANAGE_AGENCY_RESTRICTION = 'MANAGE_AGENCY_RESTRICTION';

export const RESET_AGENCY_RESTRICTION = 'RESET_AGENCY_RESTRICTION';

// AGENCY SETTINGS

export const GET_AGENCY_SETTING = 'GET_AGENCY_SETTING';

export const MANAGE_AGENCY_SETTING = 'MANAGE_AGENCY_SETTING';
