import {
  GET_AGENCY_LIST,
  DELETE_AGENCY,
  REACTIVATE_AGENCY,
  APPROVE_AGENCY,
  GET_AGENCY,
  MANAGE_AGENCY,
  RESET_AGENCY,
  GET_AGENCY_RESTRICTION_FORM_CONFIG,
  GET_AGENCY_RESTRICTION,
  MANAGE_AGENCY_RESTRICTION,
  RESET_AGENCY_RESTRICTION,
  GET_AGENCY_POSSIBLE_PARENTS,
  RESET_AGENCY_POSSIBLE_PARENTS,
  GET_CHILD_AGENCY_LIST,
  DELETE_CHILD_AGENCY,
  REACTIVATE_CHILD_AGENCY,
  APPROVE_CHILD_AGENCY,
  GET_CHILD_AGENCY,
  RESET_CHILD_AGENCY,
  MANAGE_CHILD_AGENCY,
  GET_AGENCY_SETTING,
  MANAGE_AGENCY_SETTING,
  RESET_AGENCY_LIST,
  RESET_CHILD_AGENCY_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getAgencyList = (agencyId, pagination) => ({
  type: GET_AGENCY_LIST,
  payload: { pagination }
});

export const getAgencyListSuccess = data => ({
  type: SUCCESS(GET_AGENCY_LIST),
  payload: data,
});

export const getAgencyListFailed = error => ({
  type: FAILED(GET_AGENCY_LIST),
  payload: error,
});

export const resetAgencyList = () => ({
  type: RESET_AGENCY_LIST,
});

export const deleteAgency = data => ({
  type: DELETE_AGENCY,
  payload: data,
});

export const deleteAgencySuccess = () => ({
  type: SUCCESS(DELETE_AGENCY),
});

export const deleteAgencyFailed = () => ({
  type: FAILED(DELETE_AGENCY),
});

export const deleteAgencyReset = () => ({
  type: RESETED(DELETE_AGENCY),
});

export const reactivateAgency = data => ({
  type: REACTIVATE_AGENCY,
  payload: data,
});

export const reactivateAgencySuccess = () => ({
  type: SUCCESS(REACTIVATE_AGENCY),
});

export const reactivateAgencyFailed = () => ({
  type: FAILED(REACTIVATE_AGENCY),
});

export const reactivateAgencyReset = () => ({
  type: RESETED(REACTIVATE_AGENCY),
});

export const approveAgency = data => ({
  type: APPROVE_AGENCY,
  payload: data,
});

export const approveAgencySuccess = () => ({
  type: SUCCESS(APPROVE_AGENCY),
});

export const approveAgencyFailed = () => ({
  type: FAILED(APPROVE_AGENCY),
});

export const approveAgencyReset = () => ({
  type: RESETED(APPROVE_AGENCY),
});

export const getAgency = agencyId => ({
  type: GET_AGENCY,
  payload: { agencyId },
});

export const getAgencySuccess = agency => ({
  type: SUCCESS(GET_AGENCY),
  payload: agency,
});

export const getAgencyFailed = () => ({
  type: FAILED(GET_AGENCY),
});

export const manageAgency = agency => ({
  type: MANAGE_AGENCY,
  payload: agency,
});

export const manageAgencySuccess = agency => ({
  type: SUCCESS(MANAGE_AGENCY),
  payload: agency,
});

export const manageAgencyFailed = (error = null) => ({
  type: FAILED(MANAGE_AGENCY),
  payload: error,
});

export const resetAgency = () => ({
  type: RESET_AGENCY,
});

// CHILD AGENCY BELOW

export const getAgencyPossibleParents = (agencyId = null, parentId = null) => ({
  type: GET_AGENCY_POSSIBLE_PARENTS,
  payload: { agencyId, parentId },
});

export const getAgencyPossibleParentsSuccess = data => ({
  type: SUCCESS(GET_AGENCY_POSSIBLE_PARENTS),
  payload: data,
});

export const getAgencyPossibleParentsFailed = error => ({
  type: FAILED(GET_AGENCY_POSSIBLE_PARENTS),
  payload: error,
});

export const resetAgencyPossibleParents = () => ({
  type: RESET_AGENCY_POSSIBLE_PARENTS,
});

export const getChildAgencyList = (agencyId, pagination) => ({
  type: GET_CHILD_AGENCY_LIST,
  payload: { agencyId, pagination },
});

export const getChildAgencyListSuccess = data => ({
  type: SUCCESS(GET_CHILD_AGENCY_LIST),
  payload: data,
});

export const getChildAgencyListFailed = error => ({
  type: FAILED(GET_CHILD_AGENCY_LIST),
  payload: error,
});

export const resetChildAgencyList = () => ({
  type: RESET_CHILD_AGENCY_LIST,
});

export const deleteChildAgency = data => ({
  type: DELETE_CHILD_AGENCY,
  payload: data,
});

export const deleteChildAgencySuccess = () => ({
  type: SUCCESS(DELETE_CHILD_AGENCY),
});

export const deleteChildAgencyFailed = () => ({
  type: FAILED(DELETE_CHILD_AGENCY),
});

export const deleteChildAgencyReset = () => ({
  type: RESETED(DELETE_CHILD_AGENCY),
});

export const reactivateChildAgency = data => ({
  type: REACTIVATE_CHILD_AGENCY,
  payload: data,
});

export const reactivateChildAgencySuccess = () => ({
  type: SUCCESS(REACTIVATE_CHILD_AGENCY),
});

export const reactivateChildAgencyFailed = () => ({
  type: FAILED(REACTIVATE_CHILD_AGENCY),
});

export const reactivateChildAgencyReset = () => ({
  type: RESETED(REACTIVATE_CHILD_AGENCY),
});

export const approveChildAgency = data => ({
  type: APPROVE_CHILD_AGENCY,
  payload: data,
});

export const approveChildAgencySuccess = () => ({
  type: SUCCESS(APPROVE_CHILD_AGENCY),
});

export const approveChildAgencyFailed = () => ({
  type: FAILED(APPROVE_CHILD_AGENCY),
});

export const approveChildAgencyReset = () => ({
  type: RESETED(APPROVE_CHILD_AGENCY),
});

export const getChildAgency = agencyId => ({
  type: GET_CHILD_AGENCY,
  payload: { agencyId },
});

export const getChildAgencySuccess = agency => ({
  type: SUCCESS(GET_CHILD_AGENCY),
  payload: agency,
});

export const getChildAgencyFailed = () => ({
  type: FAILED(GET_CHILD_AGENCY),
});

export const manageChildAgency = agency => ({
  type: MANAGE_CHILD_AGENCY,
  payload: agency,
});

export const manageChildAgencySuccess = agency => ({
  type: SUCCESS(MANAGE_CHILD_AGENCY),
  payload: agency,
});

export const manageChildAgencyFailed = (error = null) => ({
  type: FAILED(MANAGE_CHILD_AGENCY),
  payload: error,
});

export const resetChildAgency = () => ({
  type: RESET_CHILD_AGENCY,
});

// AGENCY RESTRICTIONS

export const getAgencyRestrictionFormConfig = () => ({
  type: GET_AGENCY_RESTRICTION_FORM_CONFIG,
});

export const getAgencyRestrictionFormConfigSuccess = data => ({
  type: SUCCESS(GET_AGENCY_RESTRICTION_FORM_CONFIG),
  payload: data,
});

export const getAgencyRestrictionFormConfigFailed = () => ({
  type: FAILED(GET_AGENCY_RESTRICTION_FORM_CONFIG),
});

export const getAgencyRestriction = (agencyId, callback = null) => ({
  type: GET_AGENCY_RESTRICTION,
  payload: { agencyId, callback },
});

export const manageAgencyRestriction = data => ({
  type: MANAGE_AGENCY_RESTRICTION,
  payload: data,
});

export const resetAgencyRestriction = () => ({
  type: RESET_AGENCY_RESTRICTION,
});

export const getAgencySetting = agencyId => ({
  type: GET_AGENCY_SETTING,
  payload: { agencyId },
});

export const getAgencySettingSuccess = agency => ({
  type: SUCCESS(GET_AGENCY_SETTING),
  payload: agency,
});

export const getAgencySettingFailed = () => ({
  type: FAILED(GET_AGENCY_SETTING),
});

export const manageAgencySetting = agency => ({
  type: MANAGE_AGENCY_SETTING,
  payload: agency,
});

export const manageAgencySettingSuccess = () => ({
  type: SUCCESS(MANAGE_AGENCY_SETTING),
});

export const manageAgencySettingFailed = (error = null) => ({
  type: FAILED(MANAGE_AGENCY_SETTING),
  payload: error,
});
