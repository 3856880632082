import { combineReducers } from 'redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  GET_PRODUCER_LIST,
  GET_PRODUCER,
  MANAGE_PRODUCER,
  DELETE_PRODUCER,
  REACTIVATE_PRODUCER,
  RESET_PRODUCER,
  RESET,
  GET_PRODUCER_POSSIBLE_PARENTS,
  APPROVE_PRODUCER,
  MANUAL_APPROVE_PRODUCER,
  GET_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_PRODUCER_RESTRICTIONS,
  MANAGE_PRODUCER_RESTRICTIONS,
  APPROVE_PRODUCER_NIPR,
  APPROVE_PRODUCER_FFM,
  RESET_PRODUCER_LIST,
  GET_ALL_PRODUCERS_LIST,
  RESET_ALL_PRODUCERS_LIST,
  PRODUCER_TEMPLATE_IMPORT_CSV,
  GET_ALL_PRODUCERS_AND_AGENCIES_LIST,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { getDefaultProducerAgentNumbers } from 'helpers';

const processAgentNumbers = (agentNumbers = []) => {
  const processedAgentNumbers = [];
  const agentNumberOrder = getDefaultProducerAgentNumbers();

  agentNumberOrder.forEach((agentNumberType) => {
    const agentNumber = agentNumbers.find(({ type }) => type === agentNumberType);
    if (agentNumber) {
      processedAgentNumbers.push(agentNumber);
    } else {
      processedAgentNumbers.push({
        id: '',
        type: agentNumberType,
        value: '',
        status: 'Not Approved',
      });
    }
  });

  return processedAgentNumbers;
};

const addDefaultValues = (payload) => {
  const producer = _.defaultsDeep(payload, INIT_STATE_PRODUCER.producer);
  const agentNumbers = _.get(producer, 'data.agentNumbers.data', []);
  _.set(producer, 'data.agentNumbers.data', processAgentNumbers(agentNumbers));

  return producer;
};

const INIT_STATE = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  error: false,
  loading: false,
  deletedProducer: false,
  reactivatedProducer: false,
  needsRedirect: false,
  approvedProducer: false,
  isApproving: false,
  producertemplateImportedCsv: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCER_LIST:
    case GET_ALL_PRODUCERS_LIST:
      return { ...state, loading: true, needsRedirect: false, approveProducer: false };
    case GET_ALL_PRODUCERS_AND_AGENCIES_LIST:
      return { ...state, loading: true, needsRedirect: false, approveProducer: false };
    case SUCCESS(GET_PRODUCER_LIST):
    case SUCCESS(GET_ALL_PRODUCERS_LIST):
      return { ...state, data: action.payload, error: false, loading: false, needsRedirect: false, producertemplateImportedCsv: false };
    case SUCCESS(GET_ALL_PRODUCERS_AND_AGENCIES_LIST):
      return { ...state, data: action.payload, error: false, loading: false, needsRedirect: false, producertemplateImportedCsv: false };
    case FAILED(GET_PRODUCER_LIST):
    case FAILED(GET_ALL_PRODUCERS_LIST):
      return { ...state, error: action.payload, loading: false, needsRedirect: true, producertemplateImportedCsv: false };
    case FAILED(GET_ALL_PRODUCERS_AND_AGENCIES_LIST):
      return { ...state, error: action.payload, loading: false, needsRedirect: true, producertemplateImportedCsv: false };
    case DELETE_PRODUCER:
      return { ...state, loading: true };
    case SUCCESS(DELETE_PRODUCER):
      return { ...state, deletedProducer: true };
    case FAILED(DELETE_PRODUCER):
      return { ...state, deletedProducer: false };
    case RESETED(DELETE_PRODUCER):
      return { ...state, deletedProducer: false };
    case REACTIVATE_PRODUCER:
      return { ...state, loading: true };
    case SUCCESS(REACTIVATE_PRODUCER):
      return { ...state, reactivatedProducer: true, loading: false };
    case FAILED(REACTIVATE_PRODUCER):
      return { ...state, reactivatedProducer: false, loading: false };
    case RESETED(REACTIVATE_PRODUCER):
      return { ...state, reactivatedProducer: false };
    case APPROVE_PRODUCER:
    case APPROVE_PRODUCER_NIPR:
    case APPROVE_PRODUCER_FFM:
      return { ...state, loading: true, isApproving: true };
    case SUCCESS(APPROVE_PRODUCER):
    case SUCCESS(APPROVE_PRODUCER_NIPR):
    case SUCCESS(APPROVE_PRODUCER_FFM):
      return { ...state, loading: false, approvedProducer: true, isApproving: false };
    case FAILED(APPROVE_PRODUCER):
    case FAILED(APPROVE_PRODUCER_NIPR):
    case FAILED(APPROVE_PRODUCER_FFM):
      return { ...state, loading: false, approvedProducer: false, error: true, isApproving: false };
    case RESETED(APPROVE_PRODUCER):
      return { ...state, loading: false, approvedProducer: false, error: false, isApproving: false };
    case PRODUCER_TEMPLATE_IMPORT_CSV:
        return { ...state, loading: true };
    case SUCCESS(PRODUCER_TEMPLATE_IMPORT_CSV):
        return { ...state, producertemplateImportedCsv: true, error: false, loading: false, errors: null };
    case FAILED(PRODUCER_TEMPLATE_IMPORT_CSV):
        return { ...state, error: action.payload, loading: false, errors: action.payload };
    case RESET:
    case RESET_PRODUCER_LIST:
    case RESET_ALL_PRODUCERS_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_PRODUCER = {
  producer: {
    data: {
      id: '',
      isDeleted: '0',
      isDefaultProducer: '0',
      status: 'Suspended',
      isFfmVerified: false,
      forceDeRedirect: false,
      allowReferrals: false,
      niprCheckRequestTimestamp: '',
      ffmCheckRequestTimestamp: '',
      parent: {
        data: {
          id: '',
          isDeleted: '0',
          isDefaultProducer: '0',
        },
      },
      restricted: {
        data: [],
      },
      person: {
        data: {
          id: '',
          title: '',
          suffix: '',
          lastName: '',
          firstName: '',
          middleName: '',
          gender: '',
          birthDate: '',
          maritalStatus: '',
          addresses: {
            data: [
              {
                id: '',
                addressType: 'Address',
                addressLine1: '',
                addressLine2: '',
                city: '',
                countyFips: '',
                zipCode: '',
                state: '',
              },
            ],
          },
          contacts: {
            data: [
              { id: '', type: 'Email', value: '' },
              { id: '', type: 'Phone', value: '' },
              { id: '', type: 'Secondary Phone', value: '' },
            ],
          },
          proof: {
            data: {
              id: '',
              isIdProofed: '',
              channelType: '',
              notes: '',
              requestTimestamp: '',
            },
          },
        },
      },
      user: {
        data: {
          id: '',
          email: '',
          password: '',
          verifyPassword: '',
          roles: {
            data: [],
          },
          oktaToken: {
            data: {}
          },
        },
      },
      agentNumbers: {
        data: processAgentNumbers(),
      },
      producerFfmChecks: {
        data: [
          {
            id: '',
            year: '',
            individualMarketCompletionDate: '',
            individualMarketRegistrationEnddate: '',
            terminationOrSuspensionDate: '',
            terminationReconsideredOrSuspensionLiftedDate: '',
          },
        ],
      },
      producerGaChecks: {
        data: [
          {
            id: '',
            year: '',
            individualMarketCompletionDate: '',
            individualMarketRegistrationEnddate: '',
            terminationOrSuspensionDate: '',
            terminationReconsideredOrSuspensionLiftedDate: '',
          },
        ],
      },
      producerStates: {
        data: [],
      },
      producerRestrictedIssuers: {
        data: [],
      },
      producerApis: {
        data: [
          {
            id: '',
            isActive: false,
            api: {
              data: {
                id: '',
                apiName: '',
                isDeleted: true,
              },
            },
            producerApiLineOfCoverages: {
              data: [
                {
                  id: '',
                  isActive: false,
                  apiLineOfCoverage: {
                    data: {
                      id: '',
                      machineName: '',
                      isDeleted: true,
                      lineOfCoverage: {
                        data: [
                          {
                            id: '',
                            name: '',
                            displayName: '',
                            displayOrder: 0,
                            isDeleted: true,
                          },
                        ],
                      },
                    },
                  },
                },
              ],
            },
            producerApiHiddenPlans: {
              data: [],
            },
          },
        ],
      },
    },
  },
  success: false,
  errors: null,
  loading: false,
  isSubmitting: false,
  needsRedirect: false,
};

const Producer = (state = INIT_STATE_PRODUCER, action) => {
  switch (action.type) {
    case GET_PRODUCER:
    case GET_PRODUCER_RESTRICTIONS:
      return { ...state, success: false, loading: true, needsRedirect: false };
    case GET_AGENT_REP_PRODUCER_RESTRICTIONS:
      return { ...state, success: false, loading: true, needsRedirect: false };
    case SUCCESS(GET_PRODUCER):
      return {
        ...state,
        producer: addDefaultValues(action.payload),
        loading: false,
        needsRedirect: false,
      };
    case FAILED(GET_PRODUCER):
      return { ...state, loading: false, needsRedirect: true };
    case MANAGE_PRODUCER:
    case MANAGE_PRODUCER_RESTRICTIONS:
    case MANUAL_APPROVE_PRODUCER:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_PRODUCER):
    case SUCCESS(MANAGE_PRODUCER_RESTRICTIONS):
    case SUCCESS(MANUAL_APPROVE_PRODUCER):
      return {
        ...state,
        producer: addDefaultValues(action.payload),
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_PRODUCER):
    case FAILED(MANAGE_PRODUCER_RESTRICTIONS):
    case FAILED(MANUAL_APPROVE_PRODUCER):
      return { ...state, errors: action.payload, isSubmitting: false };
    case RESETED(MANUAL_APPROVE_PRODUCER):
      return { ...state, success: false, errors: null, loading: false, isSubmitting: false, needsRedirect: false };
    case RESET_PRODUCER:
    case RESET:
      return INIT_STATE_PRODUCER;
    default:
      return state;
  }
};

const INIT_STATE_POSSIBLE_PARENTS = {
  data: {
    data: [],
  },
  error: false,
  loading: false,
};

const PossibleParents = (state = INIT_STATE_POSSIBLE_PARENTS, action) => {
  switch (action.type) {
    case GET_PRODUCER_POSSIBLE_PARENTS:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_PRODUCER_POSSIBLE_PARENTS):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_PRODUCER_POSSIBLE_PARENTS):
      return { ...state, loading: false };
    case RESETED(GET_PRODUCER_POSSIBLE_PARENTS):
    case RESET:
      return { ...INIT_STATE_POSSIBLE_PARENTS };
    default:
      return state;
  }
};

const INIT_STATE_PRODUCER_RESTRICTIONS_FORM_CONFIG = {
  loading: false,
  error: false,
  apis: [],
  issuers: [],
};

const ProducerRestrictionsFormConfig = (state = INIT_STATE_PRODUCER_RESTRICTIONS_FORM_CONFIG, action) => {
  switch (action.type) {
    case GET_PRODUCER_RESTRICTIONS_FORM_CONFIG:
    case GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG:
      return { ...state, loading: true, error: false };

    case SUCCESS(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG):
    case SUCCESS(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG):
      return {
        ...state,
        apis: action.payload.apis,
        issuers: action.payload.issuers,
        loading: false,
        error: false,
      };

    case FAILED(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG):
    case FAILED(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG):
      return { ...state, loading: false, error: true };

    case RESET_PRODUCER:
    case RESET:
      return INIT_STATE_PRODUCER_RESTRICTIONS_FORM_CONFIG;

    default:
      return state;
  }
};

export default combineReducers({
  List,
  Producer,
  PossibleParents,
  ProducerRestrictionsFormConfig,
});

// selectors
const hasLastActiveAdminFromState = (state) => state.Broker.Producers.List.data;
export const hasLastActiveAdminSelector = createSelector([hasLastActiveAdminFromState], (data) => {
  let flag = false;
  let count = 0;
  data.data.forEach(function (producer) {
    if (producer.isDeleted === 0) {
      const roles = _.get(producer, 'user.data.roles.data', []);
      roles.forEach(async function (role) {
        if (role.name === 'Admin') {
          count += 1;
        }
      });
    }
  });
  if (count === 1) {
    flag = true;
  }

  return flag;
});

const getProducerProducerFromState = (state) => state.Broker.Producers.Producer.producer;
export const getProducerSelector = createSelector([getProducerProducerFromState], (producer) => {
  let undefinedFlag = false;
  let producerFfmChecks = producer.data.producerFfmChecks.data;
  const producerGaChecks = producer.data.producerGaChecks.data;
  const modifiedValues = producer.data.producerFfmChecks.data.map((producerFfmCheck) => {
    const producerFfmCheckKeys = Object.keys(producerFfmCheck);
    const modifiedValue = {};

    producerFfmCheckKeys.forEach((value) => {
      if (
        value === 'individualMarketCompletionDate' ||
        value === 'individualMarketRegistrationEnddate' ||
        value === 'terminationOrSuspensionDate' ||
        value === 'terminationReconsideredOrSuspensionLiftedDate'
      ) {
        if (producerFfmCheck[value]) {
          if (producerFfmCheck[value].date) {
            modifiedValue[value] = producerFfmCheck[value].date.slice(0, 10);
          } else {
            undefinedFlag = true;
          }
        } else {
          modifiedValue[value] = '';
        }
      } else {
        modifiedValue[value] = producerFfmCheck[value];
      }
    });

    return modifiedValue;
  });

  if (!undefinedFlag) {
    producerFfmChecks = modifiedValues;
  }

  return {
    ...producer.data,
    parent: producer.data.parent?.data?.id,
    isDeleted: producer.data.isDeleted.toString(),
    isDefaultProducer: producer.data.isDefaultProducer.toString(),
    person: {
      ...producer.data.person.data,
      addresses: producer.data.person.data.addresses.data,
      contacts: producer.data.person.data.contacts.data,
      proof: producer.data.person.data.proof.data,
    },
    user: { ...producer.data.user.data, password: '', verifyPassword: '', roles: producer.data.user.data.roles.data },
    agentNumbers: producer.data.agentNumbers.data,
    producerFfmChecks,
    producerGaChecks,
    producerStates: producer.data.producerStates.data,
  };
});

const getProducerPossibleParentsDataFromState = (state) => state.Broker.Producers.PossibleParents.data;
const getProducerPossibleParentsLoadingFromState = (state) => state.Broker.Producers.PossibleParents.loading;
export const getProducerPossibleParentsSelector = createSelector(
  [getProducerPossibleParentsDataFromState, getProducerPossibleParentsLoadingFromState],
  (data, loading) => {
    return {
      ...data,
      loading,
    };
  }
);

const getProducerNameProducerFromState = (state) => state.Broker.Producers.Producer.producer;
export const getProducerNameSelector = createSelector([getProducerNameProducerFromState], (producer) => {
  const { firstName, lastName } = producer.data.person.data;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return '';
});

// producer restrictions form config
const getBrokerProducerProducerRestrictionsFormConfigFromState = (state) =>
  state.Broker.Producers.ProducerRestrictionsFormConfig; // all slice

export const getProducerRestrictionsFormConfigSelector = createSelector(
  [getBrokerProducerProducerRestrictionsFormConfigFromState],
  (producerRestrictionsFormConfig) => {
    // forward
    return {
      ...producerRestrictionsFormConfig,
    };
  }
);

// selectors restrictions
export const getProducerRestrictionsSelector = createSelector([getProducerProducerFromState], (producer) => {
  return {
    ...producer.data,
    agentNumbers: producer.data.agentNumbers.data,
    isDeleted: producer.data.isDeleted.toString(),
    isDefaultProducer: producer.data.isDefaultProducer.toString(),
    person: {
      ...producer.data.person.data,
    },
    producerFfmChecks: producer.data.producerFfmChecks.data,
    producerGaChecks: producer.data.producerGaChecks.data,
    producerStates: producer.data.producerStates.data,
    producerRestrictedIssuers: producer.data.producerRestrictedIssuers.data.map((producerRestrictedIssuer) => {
      return { ...producerRestrictedIssuer, issuer: producerRestrictedIssuer.issuer.data.id };
    }),

    producerApis: producer.data.producerApis.data.map((producerApi) => {
      return {
        ...producerApi,
        api: producerApi.api.data.id,
        producerApiLineOfCoverages: producerApi.producerApiLineOfCoverages.data.map((producerApiLineOfCoverage) => {
          return {
            ...producerApiLineOfCoverage,
            apiLineOfCoverage: producerApiLineOfCoverage.apiLineOfCoverage.data.id,
          };
        }),
        producerApiHiddenPlans: producerApi.producerApiHiddenPlans.data,
      };
    }),
  };
});

// mno sa-mi bag...
export const getProducerRestrictionsOrdered = createSelector(
  [getProducerRestrictionsSelector, getProducerRestrictionsFormConfigSelector, (state) => state.Broker.Issuers.List.data],
  (producerRestrictions, producerRestrictionsFormConfig, issuers) => {
    // process apis
    const producerApis = producerRestrictionsFormConfig.apis.map((api) => {
      const producerApi = producerRestrictions.producerApis.find((element) => element.api === api.id && !element.privateExchange);

      if (producerApi) {
        return {
          ...producerApi,
          apiName: api.apiName,
          producerApiLineOfCoverages: api.linesOfCoverage.map((lineOfCoverage) => {
            const apiLineOfCoverage = producerApi.producerApiLineOfCoverages
              .filter((producerApiLineOfCoverage) => lineOfCoverage.id === producerApiLineOfCoverage.apiLineOfCoverage)
              .shift();

            if (apiLineOfCoverage) {
              return {
                ...apiLineOfCoverage,
                lineOfCoverageName: lineOfCoverage.name,
              };
            }

            return {
              apiLineOfCoverage: lineOfCoverage.id,
              id: '',
              isActive: false,
              lineOfCoverageName: lineOfCoverage.name,
            };
          }),
        };
      }

      return {
        id: '',
        api: api.id,
        apiName: api.apiName,
        isActive: false,
        producerApiHiddenPlans: [],
        producerApiLineOfCoverages: api.linesOfCoverage.map((lineOfCoverage) => {
          return {
            apiLineOfCoverage: lineOfCoverage.id,
            id: '',
            isActive: false,
            lineOfCoverageName: lineOfCoverage.name,
          };
        }),
      };
    });

    const producerRestrictionsFormConfigFiltered = issuers.data.filter((issuer) => {
      const producerStatesFiltered = producerRestrictions.producerStates.filter(
        (state) => state.stateAbbreviation === issuer.state
      );

      return producerStatesFiltered.length > 0;
    });

    // process restricted issuers
    const producerRestrictedIssuers = producerRestrictionsFormConfigFiltered.map((issuer, index) => {
      const producerHiddenIssuer = producerRestrictions.producerRestrictedIssuers
        .filter((element) => element.issuer === issuer.id)
        .shift();
        
      if (producerHiddenIssuer) {
        return {
          uniqueKey: index,
          id: producerHiddenIssuer.id,
          issuer: issuer.id,
          isHidden: true,
          name: issuer.name,
          year: issuer.year,
          state: issuer.state,
        };
      }

      return {
        uniqueKey: index,
        id: '',
        issuer: issuer.id,
        isHidden: false,
        name: issuer.name,
        year: issuer.year,
        state: issuer.state,
      };
    });

    return { ...producerRestrictions, producerApis, producerRestrictedIssuers };
  }
);

export const getProducerUserIdSelector = createSelector([getProducerNameProducerFromState], (producer) => {
  return producer.data.user.data.id;
});
