export const GET_BAA_SIGNATURE_LIST = 'GET_BAA_SIGNATURE_LIST';

export const RESET_BAA_SIGNATURE_LIST = 'RESET_BAA_SIGNATURE_LIST';

export const DELETE_BAA_SIGNATURE = 'DELETE_BAA_SIGNATURE';

export const REACTIVATE_BAA_SIGNATURE = 'REACTIVATE_BAA_SIGNATURE';

export const GET_BAA_SIGNATURE = 'GET_BAA_SIGNATURE';

export const MANAGE_BAA_SIGNATURE = 'MANAGE_BAA_SIGNATURE';

export const RESET_BAA_SIGNATURE = 'RESET_BAA_SIGNATURE';
