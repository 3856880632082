import {
  GET_LINE_OF_COVERAGE_LIST,
  DELETE_LINE_OF_COVERAGE,
  GET_LINE_OF_COVERAGE,
  MANAGE_LINE_OF_COVERAGE,
  RESET_LINE_OF_COVERAGE,
  REACTIVATE_LINE_OF_COVERAGE,
  RESET_LINE_OF_COVERAGE_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getLineOfCoverageList = () => ({
  type: GET_LINE_OF_COVERAGE_LIST,
});

export const getLineOfCoverageListSuccess = data => ({
  type: SUCCESS(GET_LINE_OF_COVERAGE_LIST),
  payload: data,
});

export const getLineOfCoverageListFailed = error => ({
  type: FAILED(GET_LINE_OF_COVERAGE_LIST),
  payload: error,
});

export const resetLineOfCoverageList = () => ({
  type: RESET_LINE_OF_COVERAGE_LIST,
});

export const deleteLineOfCoverage = lineOfCoverage => ({
  type: DELETE_LINE_OF_COVERAGE,
  payload: lineOfCoverage,
});

export const deleteLineOfCoverageSuccess = () => ({
  type: SUCCESS(DELETE_LINE_OF_COVERAGE),
});

export const deleteLineOfCoverageFailed = () => ({
  type: FAILED(DELETE_LINE_OF_COVERAGE),
});

export const resetDeleteLineOfCoverage = () => ({
  type: RESETED(DELETE_LINE_OF_COVERAGE),
});

export const reactivateLineOfCoverage = lineOfCoverage => ({
  type: REACTIVATE_LINE_OF_COVERAGE,
  payload: lineOfCoverage,
});

export const reactivateLineOfCoverageSuccess = () => ({
  type: SUCCESS(REACTIVATE_LINE_OF_COVERAGE),
});

export const reactivateLineOfCoverageFailed = () => ({
  type: FAILED(REACTIVATE_LINE_OF_COVERAGE),
});

export const resetReactivateLineOfCoverage = () => ({
  type: RESETED(REACTIVATE_LINE_OF_COVERAGE),
});

export const getLineOfCoverage = lineOfCoverageId => ({
  type: GET_LINE_OF_COVERAGE,
  payload: lineOfCoverageId,
});

export const getLineOfCoverageSuccess = data => ({
  type: SUCCESS(GET_LINE_OF_COVERAGE),
  payload: data,
});

export const getLineOfCoverageFailed = () => ({
  type: FAILED(GET_LINE_OF_COVERAGE),
});

export const manageLineOfCoverage = data => ({
  type: MANAGE_LINE_OF_COVERAGE,
  payload: data,
});

export const manageLineOfCoverageSuccess = () => ({
  type: SUCCESS(MANAGE_LINE_OF_COVERAGE),
});

export const manageLineOfCoverageFailed = data => ({
  type: FAILED(MANAGE_LINE_OF_COVERAGE),
  payload: data,
});

export const resetLineOfCoverage = () => ({
  type: RESET_LINE_OF_COVERAGE,
});
