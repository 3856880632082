import {
  GET_DASHBOARD_ALERT,
  GET_DASHBOARD_ALERT_LIST,
  DELETE_DASHBOARD_ALERT,
  RESET_DASHBOARD_ALERT,
  RESET_DASHBOARD_ALERT_LIST,
  MANAGE_DASHBOARD_ALERT,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getDashboardAlertList = () => ({
  type: GET_DASHBOARD_ALERT_LIST,
});

export const getDashboardAlertListSuccess = (data) => ({
  type: SUCCESS(GET_DASHBOARD_ALERT_LIST),
  payload: data,
});

export const getDashboardAlertListFailed = (error) => ({
  type: FAILED(GET_DASHBOARD_ALERT_LIST),
  payload: error,
});

export const resetDashboardAlertList = () => ({
  type: RESET_DASHBOARD_ALERT_LIST,
});

export const deleteDashboardAlert = (globalAlert) => ({
  type: DELETE_DASHBOARD_ALERT,
  payload: globalAlert,
});

export const deleteDashboardAlertSuccess = () => ({
  type: SUCCESS(DELETE_DASHBOARD_ALERT),
});

export const deleteDashboardAlertFailed = () => ({
  type: FAILED(DELETE_DASHBOARD_ALERT),
});

export const resetDeleteDashboardAlert = () => ({
  type: RESETED(DELETE_DASHBOARD_ALERT),
});

export const getDashboardAlert = (globalAlertId) => ({
  type: GET_DASHBOARD_ALERT,
  payload: globalAlertId,
});

export const getDashboardAlertSuccess = (data) => ({
  type: SUCCESS(GET_DASHBOARD_ALERT),
  payload: data,
});

export const getDashboardAlertFailed = () => ({
  type: FAILED(GET_DASHBOARD_ALERT),
});

export const manageDashboardAlert = (data) => ({
  type: MANAGE_DASHBOARD_ALERT,
  payload: data,
});

export const manageDashboardAlertSuccess = () => ({
  type: SUCCESS(MANAGE_DASHBOARD_ALERT),
});

export const manageDashboardAlertFailed = (data) => ({
  type: FAILED(MANAGE_DASHBOARD_ALERT),
  payload: data,
});

export const resetDashboardAlert = () => ({
  type: RESET_DASHBOARD_ALERT,
});
