import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { flashSuccessMessage } from 'redux-flash';
import { fetchJSON } from 'helpers';
import { FORGET_PASSWORD } from 'constants/actionTypes';
import { forgetPasswordSuccess, forgetPasswordFailed } from './actions';

function* forgetPassword({ payload: data }) {
  const body = JSON.stringify(data);

  try {
    const infoOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_API_BASIC_AUTH}`,
      },
      body,
    };

    yield call(fetchJSON, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/password-token`, infoOptions);
    yield put(flashSuccessMessage('An Email with the reset password link has been sent!'));
    yield put(forgetPasswordSuccess());
  } catch (error) {
    const message = error.statusText;
    yield put(forgetPasswordFailed(message));
  }
}

export function* watchForgetPassword() {
  yield takeLeading(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
  yield all([fork(watchForgetPassword)]);
}

export default authSaga;
