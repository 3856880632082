import React, { PureComponent } from 'react';
import _ from 'lodash';

class Footer extends PureComponent {
  render() {
    const { className } = this.props;

    return (
      <footer className={_.isEmpty(className) ? 'footer' : className}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              2020 &copy; <a href="https://insxcloud.com">INSXCloud</a>, developed by <a href="https://my1.ro">My1</a>
            </div>
            <div className="col-md-6">
              <div className="text-md-right footer-links d-none d-sm-block text-capitalize">
                {process.env.REACT_APP_NAME}: {process.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
