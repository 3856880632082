import { GET_QUOTE_PIN_LIST } from "constants/quotePin";
import { FAILED, RESETED, SUCCESS }    from "helpers";

export const getQuotePinList =  (data) => ({
  type: GET_QUOTE_PIN_LIST,
  payload: data,
});
export const getQuotePinListSuccess = (data) => ({
  type: SUCCESS(GET_QUOTE_PIN_LIST),
  payload: data,
});

export const getQuotePinListFailed = () => ({
  type: FAILED(GET_QUOTE_PIN_LIST),
});

export const resetGetQuotePinList = () => ({
  type: RESETED(GET_QUOTE_PIN_LIST),
});