import { EXPORT_SERVER_SIDE } from 'constants/exportServerSide';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const exportServerSide = callback => ({
  type: EXPORT_SERVER_SIDE,
  payload: callback,
});

export const exportServerSideSuccess = () => ({
  type: SUCCESS(EXPORT_SERVER_SIDE),
});

export const exportServerSideFailed = () => ({
  type: FAILED(EXPORT_SERVER_SIDE),
});
