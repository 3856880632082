/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
export const fetchJSON = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw response;
    }
    const json = await response.json();

    return json;
  } catch (error) {
    return error;
  }
};
