import { CONSUMER_CONSENT_LIST, GET_CONSUMER_CONSENT_LIST } from 'constants/consumerConsent';
import { FAILED, RESETED, SUCCESS } from 'helpers';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  success: false,
  errors: false,
}

const List = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_CONSUMER_CONSENT_LIST:
      return {...state, loading: true, success: false, errors: false};
    case SUCCESS(GET_CONSUMER_CONSENT_LIST):
      return {...state, loading: false, success: true, errors: false, data: action.payload};
    case FAILED(GET_CONSUMER_CONSENT_LIST):
      return {...state, loading: false, success: false, errors: action.payload};
    case RESETED(CONSUMER_CONSENT_LIST):
      return INIT_STATE_LIST;
    default:
      return state;
  }
}

export default combineReducers({
  List,
});

const consumerConsentListFromState = (state) => state.ConsumerConsent.List;

export const getConsumerConsentListSelector = createSelector(
  [consumerConsentListFromState],
  consumerConsent => consumerConsent.data.data
);

export const getConsumerConsentListLoadingSelector = createSelector(
  [consumerConsentListFromState],
  consumerConsent => consumerConsent.loading
);

export const getConsumerConsentListMetaSelector = createSelector(
  [consumerConsentListFromState],
  consumerConsent => consumerConsent.data.meta
);