import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, updateMethod, resolveErrorPromise, patchMethod, createMethod } from 'helpers';
import {
  GET_GLOBAL_ALERT_LIST,
  DELETE_GLOBAL_ALERT,
  GET_GLOBAL_ALERT,
  MANAGE_GLOBAL_ALERT,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getGlobalAlertListSuccess,
  getGlobalAlertListFailed,
  getGlobalAlertSuccess,
  getGlobalAlertFailed,
  manageGlobalAlertSuccess,
  manageGlobalAlertFailed,
  deleteGlobalAlertSuccess,
  deleteGlobalAlertFailed,
} from 'redux/actions';

function* globalAlertGetList() {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/global-alerts`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getGlobalAlertListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getGlobalAlertListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* globalAlertGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/global-alerts/${action.payload}`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getGlobalAlertSuccess(response));
  } catch (error) {
    yield put(getGlobalAlertFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* globalAlertDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/global-alerts/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteGlobalAlertSuccess());
  } catch (error) {
    yield put(deleteGlobalAlertFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* globalAlertManage(action) {
  try {
    if (action.payload.id.length > 0) {
      yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/global-alerts/${action.payload.id}`,
        JSON.stringify(
          action.payload
          // {
          //   title: action.payload.title,
          //   message: action.payload.message,
          //   style: action.payload.style,
          //   roles: action.payload.roles,
          // }
        )
      );
    } else {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/global-alerts`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageGlobalAlertSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageGlobalAlertFailed(message));
    } else {
      yield put(manageGlobalAlertFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetGlobalAlertList() {
  yield takeLatest(GET_GLOBAL_ALERT_LIST, globalAlertGetList);
}

export function* watchGetGlobalAlert() {
  yield takeLatest(GET_GLOBAL_ALERT, globalAlertGet);
}

export function* watchDeleteGlobalAlert() {
  yield takeLeading(DELETE_GLOBAL_ALERT, globalAlertDelete);
}

export function* watchManageGlobalAlert() {
  yield takeLeading(MANAGE_GLOBAL_ALERT, globalAlertManage);
}

function* globalAlertSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetGlobalAlertList),
      fork(watchGetGlobalAlert),
      fork(watchDeleteGlobalAlert),
      fork(watchManageGlobalAlert),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default globalAlertSaga;
