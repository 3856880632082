import { combineReducers } from 'redux';
import Agencies from './agency/reducers';
import Apis from './api/reducers';
import Producers from './producer/reducers';
import Issuers from './issuer/reducers';
import LinesOfCoverage from './line-of-coverage/reducers';
import Baa from './baa/reducers';
import BaaSignatures from './baa-signature/reducers';
import CreditLogs from './credit-log/reducers';
import Carrier from './carrier/reducers';
import BenefitSection from './benefit-section/reducers';
import Advertising from '../advertising/reducers';
import CarrierForm from './carrier-form/reducers';

export default combineReducers({
  Agencies,
  Apis,
  Producers,
  Issuers,
  LinesOfCoverage,
  Baa,
  BaaSignatures,
  CreditLogs,
  Carrier,
  BenefitSection,
  Advertising,
  CarrierForm,
});
