import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import ActiveSession from './active-session/reducers';
import ChangePassword from './change-password/reducers';
import ForgetPassword from './forget-password/reducers';
import LoginHistory from './login-history/reducers';
import Inactivity from './inactivity/reducers';
import GlobalAlert from './global-alert/reducers';
import DashboardAlert from './dashboard-alert/reducers';
import TwoFA from './two-fa/reducers';

export default combineReducers({
  Auth,
  ActiveSession,
  ChangePassword,
  ForgetPassword,
  LoginHistory,
  Inactivity,
  GlobalAlert,
  DashboardAlert,
  TwoFA,
});
