import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, updateMethod, resolveErrorPromise, patchMethod, createMethod, deleteMethod } from 'helpers';
import {
  GET_DASHBOARD_ALERT_LIST,
  DELETE_DASHBOARD_ALERT,
  GET_DASHBOARD_ALERT,
  MANAGE_DASHBOARD_ALERT,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getDashboardAlertListSuccess,
  getDashboardAlertListFailed,
  getDashboardAlertSuccess,
  getDashboardAlertFailed,
  manageDashboardAlertSuccess,
  manageDashboardAlertFailed,
  deleteDashboardAlertSuccess,
  deleteDashboardAlertFailed,
} from 'redux/actions';

function* dashboardAlertGetList() {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/notifications?showExpired=true`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getDashboardAlertListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getDashboardAlertListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* dashboardAlertGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/notifications/${action.payload}`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getDashboardAlertSuccess(response));
  } catch (error) {
    yield put(getDashboardAlertFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* dashboardAlertDelete(action) {
  try {
    yield call(
      deleteMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/notifications/${action.payload.id}`
    );
    yield put(deleteDashboardAlertSuccess());
  } catch (error) {
    yield put(deleteDashboardAlertFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* dashboardAlertManage(action) {
  const expireAtDate = new Date(action.payload.expireAt);
  const formattedExpireAt = `${expireAtDate.getMonth() + 1}/${expireAtDate.getDate()}/${expireAtDate.getFullYear()}`;

  const payload = {
    ...action.payload,
    expireAt: formattedExpireAt
  };

  try {
    if (action.payload.id.length > 0) {

      yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/notifications/${action.payload.id}`,
        JSON.stringify(
          payload
        )
      );
    } else {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/notifications`,
        JSON.stringify(payload)
      );
    }

    yield put(manageDashboardAlertSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageDashboardAlertFailed(message));
    } else {
      yield put(manageDashboardAlertFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetDashboardAlertList() {
  yield takeLatest(GET_DASHBOARD_ALERT_LIST, dashboardAlertGetList);
}

export function* watchGetDashboardAlert() {
  yield takeLatest(GET_DASHBOARD_ALERT, dashboardAlertGet);
}

export function* watchDeleteDashboardAlert() {
  yield takeLeading(DELETE_DASHBOARD_ALERT, dashboardAlertDelete);
}

export function* watchManageDashboardAlert() {
  yield takeLeading(MANAGE_DASHBOARD_ALERT, dashboardAlertManage);
}

function* dashboardAlertSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetDashboardAlertList),
      fork(watchGetDashboardAlert),
      fork(watchDeleteDashboardAlert),
      fork(watchManageDashboardAlert),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default dashboardAlertSaga;
