import {
  WILL_LOG_OUT_INACTIVITY_TWO_MINUTES,
  RESET_INACTIVITY,
  RESET,
  REINITIALIZE_INACTIVITY,
} from 'constants/actionTypes';
import { createSelector } from 'reselect';

const INIT_STATE = {
  willLogOutInTwoMinutes: false,
  lastActionTime: 0,
};

const Inactivity = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WILL_LOG_OUT_INACTIVITY_TWO_MINUTES:
      return { ...state, willLogOutInTwoMinutes: true };
    case RESET_INACTIVITY:
    case REINITIALIZE_INACTIVITY:
    case RESET:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

export default Inactivity;

export const getInactivityWillLogOutInTwoMinutes = createSelector(
  [state => state.Auth.Inactivity.willLogOutInTwoMinutes],
  v => v
);
