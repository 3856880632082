import { all, call, fork, put, takeLeading, takeLatest, cancelled, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, deleteMethod, createMethod, updateMethod, resolveErrorPromise } from 'helpers';
import {
  GET_CLIENT_LIST,
  GET_CLIENT,
  MANAGE_CLIENT,
  DELETE_CLIENT,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getClientList,
  getClientListSuccess,
  getClientListFailed,
  getClientSuccess,
  getClientFailed,
  manageClientSuccess,
  manageClientFailed,
  deleteClientFailed,
  deleteClientSuccess,
} from 'redux/actions';

function* clientGetList() {
  try {
    const response = yield call(getMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/client`);

    yield put(getClientListSuccess(response));
  } catch (error) {
    yield put(getClientListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* clientGet(action) {
  const abortController = new AbortController();

  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/client/${action.payload.clientId}?include=routes`,
      abortController.signal
    );
    yield put(getClientSuccess(response)); // save to redux
  } catch (error) {
    yield put(getClientFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* clientManage(action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/client/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/client`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageClientSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageClientFailed(message));
    } else {
      yield put(manageClientFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* clientDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/client/${action.payload.clientId}`);

    yield put(deleteClientSuccess()); // save to redux
    yield put(getClientList());
  } catch (error) {
    yield put(deleteClientFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetClients() {
  yield takeLeading(GET_CLIENT_LIST, clientGetList);
}

export function* watchGetClient() {
  yield takeLatest(GET_CLIENT, clientGet);
}

export function* watchManageClient() {
  yield takeLeading(MANAGE_CLIENT, clientManage);
}

export function* watchDeleteClient() {
  yield takeLeading(DELETE_CLIENT, clientDelete);
}

function* clientSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetClients),
      fork(watchGetClient),
      fork(watchManageClient),
      fork(watchDeleteClient),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default clientSaga;
