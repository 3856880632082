import { all, call, fork, put, takeLatest, takeLeading, cancelled, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  FAILED_MESSAGE,
  GET_CREDIT_PACKAGE_LIST,
  DELETE_CREDIT_PACKAGE,
  RESET,
  GET_CREDIT_PACKAGE,
  MANAGE_CREDIT_PACKAGE,
} from 'constants/actionTypes';
import {
  deleteCreditPackageFailed,
  deleteCreditPackageSuccess,
  getCreditPackageList,
  getCreditPackageListFailed,
  getCreditPackageListSuccess,
  getCreditPackageSuccess,
  getCreditPackageFailed,
  manageCreditPackageSuccess,
  manageCreditPackageFailed,
} from 'redux/actions';
import { createMethod, getMethod, patchMethod, updateMethod, resolveErrorPromise } from 'helpers';

function* creditPackageGetList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-package?include=agency`;
    if (action.payload) {
      request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload}/credit-package?include=agency`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getCreditPackageListSuccess(response));
  } catch (error) {
    yield put(getCreditPackageListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* creditPackageDelete(action) {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-package/${action.payload.id}`;
    yield call(patchMethod, endpoint, JSON.stringify({ isDeleted: action.payload.isDeleted }));
    yield put(deleteCreditPackageSuccess()); // save to redux
    yield put(getCreditPackageList()); // save to redux
  } catch (error) {
    yield put(deleteCreditPackageFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* creditPackageGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-package/${action.payload.creditPackageId}`,
      abortController.signal
    );
    yield put(getCreditPackageSuccess(data));
  } catch (error) {
    yield put(getCreditPackageFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* creditPackageManage(action) {
  try {
    let response;
    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-package/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/credit-package`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageCreditPackageSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageCreditPackageFailed(message));
    } else {
      yield put(manageCreditPackageFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetCreditPackageList() {
  yield takeLatest(GET_CREDIT_PACKAGE_LIST, creditPackageGetList);
}

export function* watchDeleteCreditPackage() {
  yield takeLeading(DELETE_CREDIT_PACKAGE, creditPackageDelete);
}

export function* watchGetCreditPackage() {
  yield takeLatest(GET_CREDIT_PACKAGE, creditPackageGet);
}

export function* watchManageCreditPackage() {
  yield takeLeading(MANAGE_CREDIT_PACKAGE, creditPackageManage);
}

function* creditPackagesSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetCreditPackageList),
      fork(watchDeleteCreditPackage),
      fork(watchGetCreditPackage),
      fork(watchManageCreditPackage),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default creditPackagesSaga;
