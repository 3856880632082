export const GET_ISSUER_LIST = 'GET_ISSUER_LIST';

export const RESET_ISSUER_LIST = 'RESET_ISSUER_LIST';

export const MANAGE_ISSUER = 'MANAGE_ISSUER';

export const DELETE_ISSUER = 'DELETE_ISSUER';

export const RESET_ISSUER = 'RESET_ISSUER';

export const MANAGE_ALL_ISSUER_RESTRICTIONS = 'MANAGE_ALL_ISSUER_RESTRICTIONS';

export const MANAGE_ISSUER_RESTRICTIONS = 'MANAGE_ISSUER_RESTRICTIONS';

export const DELETE_ISSUER_RESTRICTIONS = 'DELETE_ISSUER_RESTRICTIONS';
