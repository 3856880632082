import { all, call, cancel, cancelled, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { getProducerSelector } from 'redux/broker/producer/reducers';
import { FAILED, getMethod, resolveErrorPromise, SUCCESS } from 'helpers';
import { GET_PRODUCER } from 'constants/producer';
import { getConsumerConsentListFailed, getConsumerConsentListSuccess } from 'redux/broker/consumer-consent/actions';
import { flashErrorMessage } from 'redux-flash';
import { FAILED_MESSAGE, RESET } from 'constants/global';
import { GET_CONSUMER_CONSENT_LIST } from 'constants/consumerConsent';

function* getConsumerConsentList(action) {
  const abortController = new AbortController();

  let producer = yield select(getProducerSelector);
  let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/consumer-consent?`;

  try {
    const { agencyId, producerId } = action.payload;

    if (agencyId && producerId) {
      if (action.payload.callback && !producer.id) {
        yield call(action.payload.callback, agencyId, producerId);
        yield race({
          success: take(SUCCESS(GET_PRODUCER)),
          failed: take(FAILED(GET_PRODUCER)),
        });

        producer = yield select(getProducerSelector);
      }

      request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/consumer-consent?producer=${producer.id}`;
    }

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}&withPagination=true`;
    }

    const response = yield call(getMethod, request, abortController.signal);

    yield put(getConsumerConsentListSuccess(response));
  } catch (error) {
    yield put(getConsumerConsentListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(!message?.detail ? flashErrorMessage(FAILED_MESSAGE) : flashErrorMessage(message.detail));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetConsumerConsentList() {
  yield takeLatest(GET_CONSUMER_CONSENT_LIST, getConsumerConsentList);
}

function* consumerConsentSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetConsumerConsentList),
    ]);

    yield take(RESET);
    yield cancel(tasks);
  }
}

export default consumerConsentSaga;