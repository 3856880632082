import {
  GET_ISSUER_LIST,
  MANAGE_ALL_ISSUER_RESTRICTIONS,
  MANAGE_ISSUER,
  DELETE_ISSUER,
  MANAGE_ISSUER_RESTRICTIONS,
  RESET_ISSUER,
  RESET_ISSUER_LIST,
  DELETE_ISSUER_RESTRICTIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getIssuerList = (data = {}) => ({
  type: GET_ISSUER_LIST,
  payload: data,
});

export const getIssuerListSuccess = (data) => ({
  type: SUCCESS(GET_ISSUER_LIST),
  payload: data,
});

export const getIssuerListFailed = (error) => ({
  type: FAILED(GET_ISSUER_LIST),
  payload: error,
});

export const resetIssuerList = () => ({
  type: RESET_ISSUER_LIST,
});

export const manageIssuer = (year) => ({
  type: MANAGE_ISSUER,
  payload: year,
});

export const manageIssuerSuccess = () => ({
  type: SUCCESS(MANAGE_ISSUER),
});

export const manageIssuerFailed = () => ({
  type: FAILED(MANAGE_ISSUER),
});

export const deleteIssuer = payload => ({
  type: DELETE_ISSUER,
  payload,
});

export const deleteIssuerSuccess = () => ({
  type: SUCCESS(DELETE_ISSUER),
});

export const deleteIssuerFailed = () => ({
  type: FAILED(DELETE_ISSUER),
});

export const resetDeleteIssuer = () => ({
  type: RESETED(DELETE_ISSUER),
});

export const resetIssuer = () => ({
  type: RESET_ISSUER,
});

export const manageAllIssuerRestrictions = (data) => ({
  type: MANAGE_ALL_ISSUER_RESTRICTIONS,
  payload: data,
});

export const manageAllIssuerRestrictionsSuccess = (data) => ({
  type: SUCCESS(MANAGE_ALL_ISSUER_RESTRICTIONS),
  payload: data,
});

export const manageAllIssuerRestrictionsFailed = (error = null) => ({
  type: FAILED(MANAGE_ALL_ISSUER_RESTRICTIONS),
  payload: error,
});

export const manageIssuerRestrictions = (agency) => ({
  type: MANAGE_ISSUER_RESTRICTIONS,
  payload: agency,
});

export const manageIssuerRestrictionsSuccess = (data) => ({
  type: SUCCESS(MANAGE_ISSUER_RESTRICTIONS),
  payload: data,
});

export const manageIssuerRestrictionsFailed = (error = null) => ({
  type: FAILED(MANAGE_ISSUER_RESTRICTIONS),
  payload: error,
});

export const deleteIssuerRestrictions = (agency) => ({
  type: DELETE_ISSUER_RESTRICTIONS,
  payload: agency,
});

export const deleteIssuerRestrictionsSuccess = (data) => ({
  type: SUCCESS(DELETE_ISSUER_RESTRICTIONS),
  payload: data,
});

export const deleteIssuerRestrictionsFailed = (error = null) => ({
  type: FAILED(DELETE_ISSUER_RESTRICTIONS),
  payload: error,
});
