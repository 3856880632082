import { all, call, cancel, fork, put, take, takeLeading } from 'redux-saga/effects';
import { basicGetMethod, getMethod, getUserInfo, resolveErrorPromise } from 'helpers';
import { FAILED_MESSAGE, RESEND_CODE, RESET, TWO_FA_VERIFICATION } from 'constants/actionTypes';
import _ from 'lodash';
import { loginUserSuccess } from 'redux/auth/auth/actions';
import { flashErrorMessage } from 'redux-flash';
import dayjs from 'dayjs';
import { resendCodeFailed, resendCodeSuccess, verifyTwoFaFailed, verifyTwoFaSuccess } from './actions';

function* verifyTwoFa(action) {
  try {
    const response = yield call(
      basicGetMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/two-fa-verification?token=${action.payload.tokenVerification}&code=${action.payload.code}`
    );

    yield put(verifyTwoFaSuccess(response));
    localStorage.setItem(
      'token',
      JSON.stringify({
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresIn: response.expires_in,
        tokenType: response.token_type,
        scope: response.scope,
      })
    );
    const dataUser = yield call(getMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/info`);

    const user = yield call(getUserInfo, dataUser.id);
    if (_.isEmpty(user)) {
      throw new Error('Invalid Credentials');
    }
    yield put(loginUserSuccess(user));
    yield action.history.push('/user');
  } catch (error) {
    let message;
    const err = yield call(resolveErrorPromise, error);

    if (error.status === 403) {
      message = 'Unauthorized';
    } else {
      message = err?.details || FAILED_MESSAGE;
    }

    yield put(flashErrorMessage(message));

    yield put(verifyTwoFaFailed(error));
  }
}

function* resendCode(action) {
  try {
    yield call(
      basicGetMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/resend-two-fa-verification?token=${
        action.payload
      }&tz=${dayjs(new Date()).format()}`
    );

    yield put(resendCodeSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(flashErrorMessage(message.detail || FAILED_MESSAGE));
    yield put(resendCodeFailed());
  }
}

export function* watchVerify() {
  yield takeLeading(TWO_FA_VERIFICATION, verifyTwoFa);
}

export function* resendVerificationCode() {
  yield takeLeading(RESEND_CODE, resendCode);
}

function* twoFaVerifySaga() {
  while (true) {
    const tasks = yield all([fork(watchVerify), fork(resendVerificationCode)]);

    yield take(RESET);

    yield cancel(tasks);
  }
}
export default twoFaVerifySaga;
