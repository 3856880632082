import { GET_REPORTING_LIST, GET_REPORTING_FILTER } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const getReportingList = (data) => ({
  type: GET_REPORTING_LIST,
  payload: data,
});

export const getReportingListSuccess = (data) => ({
  type: SUCCESS(GET_REPORTING_LIST),
  payload: data,
});

export const getReportingListFailed = (data) => ({
  type: FAILED(GET_REPORTING_LIST),
  payload: data,
});

export const getReportingFilter = (data) => ({
  type: GET_REPORTING_FILTER,
  payload: data,
});

export const getReportingFilterSuccess = (data) => ({
  type: SUCCESS(GET_REPORTING_FILTER),
  payload: data,
});

export const getReportingFilterFailed = (data) => ({
  type: FAILED(GET_REPORTING_FILTER),
  payload: data,
});
