import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  GET_API_LIST,
  GET_API_FORM_CONFIG,
  GET_API,
  MANAGE_API,
  RESET_API,
  RESET,
  DELETE_API,
  REACTIVATE_API,
  RESET_API_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE_LIST = {
  data: {
    data: [],
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
  reactivatedSuccess: false,
  reactivatedFailed: false,
};

const List = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_API_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_API_LIST):
      return { ...state, data: action.payload, source: action.source, loading: false };
    case FAILED(GET_API_LIST):
      return { ...state, loading: false };
    case DELETE_API:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_API):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_API):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_API):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_API:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_API):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_API):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_API):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case RESET:
    case RESET_API_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_API_FORM_CONFIG = {
  loading: false,
  error: false,
  states: [],
  linesOfCoverage: [],
};

const ApiFormConfig = (state = INIT_STATE_API_FORM_CONFIG, action) => {
  switch (action.type) {
    case GET_API_FORM_CONFIG:
      return { ...state, loading: true, error: false };
    case SUCCESS(GET_API_FORM_CONFIG):
      return {
        ...state,
        states: action.payload.states,
        linesOfCoverage: action.payload.linesOfCoverage,
        loading: false,
        error: false,
      };
    case FAILED(GET_API_FORM_CONFIG):
      return { ...state, loading: false, error: true };
    case RESET_API:
    case RESET:
      return INIT_STATE_API_FORM_CONFIG;
    default:
      return state;
  }
};

const INIT_STATE_API = {
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
  api: {
    data: {
      id: '',
      apiName: '',
      isDeleted: false,
      disableFfm: false,
      disabledSbeEnrollmentStates: [],
      enableGa: false,
      apiLineOfCoverages: {
        data: [],
      },
    },
  },
};

const Api = (state = INIT_STATE_API, action) => {
  switch (action.type) {
    case GET_API:
      return { ...state, success: false, error: false, loading: true };
    case SUCCESS(GET_API):
      return {
        ...state,
        api: _.defaultsDeep(action.payload, INIT_STATE_API.api),
        loading: false,
        error: false,
      };
    case FAILED(GET_API):
      return { ...state, loading: false, error: true };
    case MANAGE_API:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_API):
      return {
        ...state,
        api: _.defaultsDeep(action.payload, INIT_STATE_API.api),
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_API):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_API:
    case RESET:
      return INIT_STATE_API;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  ApiFormConfig,
  Api,
});

const getApisApiFormConfigFromState = (state) => state.Broker.Apis.ApiFormConfig; // all slice
export const getApiFormConfigSelector = createSelector([getApisApiFormConfigFromState], (apiFormConfig) => {
  // forward
  return {
    ...apiFormConfig,
  };
});

const getApisApiFromState = (state) => state.Broker.Apis.Api.api;

export const getApiSelector = createSelector(
  [getApisApiFromState, getApisApiFormConfigFromState],
  (api, formConfig) => {
    const apiLineOfCoverages = [];
    formConfig.linesOfCoverage.map((loc, index) => {
      const apiLineOfCoverage = api.data.apiLineOfCoverages.data
        .filter((element) => element.lineOfCoverage.data.id === loc.id)
        .shift();

      if (apiLineOfCoverage) {
        apiLineOfCoverages[index] = {
          ...apiLineOfCoverage,
          isActive: !apiLineOfCoverage.isDeleted,
          apiLineOfCoverageStates: apiLineOfCoverage.apiLineOfCoverageStates.data,
          lineOfCoverage: apiLineOfCoverage.lineOfCoverage.data.id,
          whitelistedPlans: apiLineOfCoverage.whitelistedPlans
        };
      } else {
        apiLineOfCoverages[index] = {
          id: '',
          machineName: '',
          isActive: false,
          isDeleted: true,
          lineOfCoverage: loc.id,
          apiLineOfCoverageStates: [],
          whitelistedPlans: null
        };
      }

      return loc;
    });

    return {
      ...api.data,
      apiLineOfCoverages,
    };
  }
);

const getApisListFromState = (state) => state.Broker.Apis.List.data.data;

export const getApiNameListSelector = createSelector([getApisListFromState], (data) => {
  return Object.fromEntries(data.map((api) => [api.apiName, api.apiName.toUpperCase()]));
});
