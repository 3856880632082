import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { RESET, CREATE_CREDIT_LOG } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
};

const INIT_STATE_AGENCY = {
  data: { data: [] },
  loading: false,
};

const createListing = listName => {
  return (state = listName === 'CREDIT_LOG' ? INIT_STATE : INIT_STATE_AGENCY, action) => {
    switch (action.type) {
      case `GET_${listName}_LIST`:
        return { ...state, loading: true };
      case SUCCESS(`GET_${listName}_LIST`):
        return { ...state, data: action.payload, loading: false };
      case FAILED(`GET_${listName}_LIST`):
        return { ...state, loading: false };
      case RESETED(`GET_${listName}_LIST`):
      case RESET:
        return listName === 'CREDIT_LOG' ? INIT_STATE : INIT_STATE_AGENCY;
      default:
        return state;
    }
  };
};

const INIT_STATE_LOG = {
  data: {
    id: '',
    quantity: '',
    type: '',
    agency: '',
    offExchange: '',
    creditTransaction: {
      type: '',
      status: 'accepted',
      stripeReason: '',
      amount: '',
      stripeTransaction: '',
      discountCode: '',
      id: '',
    },
  },
  loading: false,
  createdSuccess: false,
  error: false,
};

const Log = (state = INIT_STATE_LOG, action) => {
  switch (action.type) {
    case CREATE_CREDIT_LOG:
      return { ...state, loading: true };
    case SUCCESS(CREATE_CREDIT_LOG):
      return { ...state, loading: false, createdSuccess: true };
    case FAILED(CREATE_CREDIT_LOG):
      return { ...state, loading: false, error: true };
    case RESETED(CREATE_CREDIT_LOG):
    case RESET:
      return INIT_STATE_LOG;
    default:
      return state;
  }
};

export default combineReducers({
  List: createListing('CREDIT_LOG'),
  AgencyList: createListing('AGENCY_CREDIT_LOG'),
  Log,
});

const getBrokerCreditLogsListFromState = state => state.Broker.CreditLogs.List;

export const getCreditLogListSelector = createSelector([getBrokerCreditLogsListFromState], creditLogs => {
  return {
    ...creditLogs.data,
  };
});

const getBrokerCreditLogsAgencyListFromState = state => state.Broker.CreditLogs.AgencyList;

export const getAgencyCreditLogListSelector = createSelector([getBrokerCreditLogsAgencyListFromState], creditLogs => {
  return {
    ...creditLogs.data,
  };
});

const getCreditLogFromState = state => state.Broker.CreditLogs.Log;

export const getCreditLogSelector = createSelector([getCreditLogFromState], creditLog => {
  return {
    ...creditLog,
  };
});
