import { CHANGE_PASSWORD, RESET_CHANGE_PASSWORD, CHECK_TOKEN, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  loading: false,
  data: {
    email: '',
    password: '',
    confirmPassword: '',
    id: '',
  },
  redirect: false,
  error: null,
};

const ChangePassword = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case SUCCESS(CHANGE_PASSWORD):
      return { ...state, redirect: '/', data: action.payload, loading: false };
    case FAILED(CHANGE_PASSWORD):
      return { ...state, error: action.payload, loading: false };
    case CHECK_TOKEN:
      return { ...state, loading: true };
    case SUCCESS(CHECK_TOKEN):
      return { ...state, loading: false };
    case FAILED(CHECK_TOKEN):
      return { ...state, error: action.payload, loading: false, redirect: '/forgot-password' };
    case RESET_CHANGE_PASSWORD:
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default ChangePassword;
