import { select, all, call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { processDataForForm } from 'helpers/formaters';
import {
  DELETE_CARRIER_FORMS,
  FAILED_MESSAGE,
  GET_CARRIER_FORMS,
  GET_CARRIER_FORMS_LIST,
  MANAGE_CARRIER_FORMS,
  RESET,
} from 'constants/actionTypes';
import { createMethod, deleteMethod, getMethod, resolveErrorPromise, updateMethod } from 'helpers';
import { getCarrierFormSelector } from 'redux/broker/carrier-form/reducers';
import {
  deleteCarrierFormsFailed,
  deleteCarrierFormsSuccess,
  getCarrierFormsFailed,
  getCarrierFormsListFailed,
  getCarrierFormsListSuccess,
  getCarrierFormsSuccess,
  manageCarrierFormsFailed,
  manageCarrierFormsSuccess,
} from './actions';

function* carrierFormsGetList(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier-form?`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}&withPagination=true`;
    }

    const response = yield call(getMethod, request, abortController.signal);

    yield put(getCarrierFormsListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getCarrierFormsListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* carrierFormsGet(action) {
  const abortController = new AbortController();

  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier-form/${action.payload}`,
      abortController.signal
    );
    yield put(getCarrierFormsSuccess(processDataForForm(response)));
  } catch (error) {
    yield put(getCarrierFormsFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* carrierFormsManage(action) {
  try {
    let response;
    if (action.payload.id === '') {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier-form`,
        JSON.stringify(action.payload)
      );
    } else {
      const carrierForm = yield select(getCarrierFormSelector);

      const payload = {
        ...action.payload,
        oldDocument: null,
      }

      if (carrierForm.document !== action.payload.document) {
        payload.oldDocument = carrierForm.document;
      }

      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier-form/${action.payload.id}`,
        JSON.stringify(payload)
      );
    }

    yield put(manageCarrierFormsSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageCarrierFormsFailed(message));
    } else {
      yield put(manageCarrierFormsFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* carrierFormsDelete(action) {
  try {
    const response = yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier-form/${action.payload}`);

    yield put(deleteCarrierFormsSuccess(response));
  } catch (error) {
    yield put(deleteCarrierFormsFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchCarrierFormsGetList() {
  yield takeLatest(GET_CARRIER_FORMS_LIST, carrierFormsGetList);
}

export function* watchCarrierFormsGet() {
  yield takeLatest(GET_CARRIER_FORMS, carrierFormsGet);
}

export function* watchCarrierFormsManage() {
  yield takeLatest(MANAGE_CARRIER_FORMS, carrierFormsManage);
}

export function* watchCarrierFormsDelete() {
  yield takeLatest(DELETE_CARRIER_FORMS, carrierFormsDelete);
}

function* carrierFormsSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchCarrierFormsGetList),
      fork(watchCarrierFormsGet),
      fork(watchCarrierFormsManage),
      fork(watchCarrierFormsDelete),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default carrierFormsSaga;
