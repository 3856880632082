import { GET_POLICY_LIST, VIEW_POLICY } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const viewPolicy = policyId => ({
  type: VIEW_POLICY,
  payload: policyId,
});

export const viewPolicySuccess = data => ({
  type: SUCCESS(VIEW_POLICY),
  payload: data,
});

export const viewPolicyFailed = data => ({
  type: FAILED(VIEW_POLICY),
  payload: data,
});

export const getPolicyList = data => ({
  type: GET_POLICY_LIST,
  payload: data,
});

export const getPolicyListSuccess = data => ({
  type: SUCCESS(GET_POLICY_LIST),
  payload: data,
});

export const getPolicyListFailed = data => ({
  type: FAILED(GET_POLICY_LIST),
  payload: data,
});
