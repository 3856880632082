import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, createMethod, resolveErrorPromise, updateMethod, patchMethod, deleteMethod } from 'helpers';
import {
  GET_ISSUER_LIST,
  MANAGE_ISSUER,
  FAILED_MESSAGE,
  RESET,
  MANAGE_ISSUER_RESTRICTIONS,
  MANAGE_ALL_ISSUER_RESTRICTIONS,
  DELETE_ISSUER_RESTRICTIONS, DELETE_ISSUER,
} from 'constants/actionTypes';
import {
  getIssuerListSuccess,
  getIssuerListFailed,
  manageIssuerSuccess,
  manageIssuerFailed,
  manageIssuerRestrictionsSuccess,
  manageIssuerRestrictionsFailed,
  manageAllIssuerRestrictionsSuccess,
  manageAllIssuerRestrictionsFailed,
  deleteIssuerRestrictionsSuccess,
  deleteIssuerRestrictionsFailed,
  deleteIssuerSuccess,
  deleteIssuerFailed,
} from 'redux/actions';

function* issuerGetList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/issuer?include=api,producerRestrictedIssuers`;
    const params = {
      ...action.payload.filters,
      ...action.payload.pagination,
      ...action.payload.order,
      agencyId: action.payload.agencyId === undefined ? '' : action.payload.agencyId,
      producerId: action.payload.producerId === undefined ? '' : action.payload.producerId,
    };

    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getIssuerListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getIssuerListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* issuerManage(action) {
  try {
    yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/issuer?include=api`,
      JSON.stringify(action.payload)
    );

    yield put(manageIssuerSuccess());
  } catch (error) {
    yield put(manageIssuerFailed());
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(flashErrorMessage(message?.detail?.formErrorMessages, { timeout: 0 }));
    } else {
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* issuerRestrictionsManage(action) {
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/producer-restricted-issuer/${action.payload.issuerId}?include=api,producerRestrictedIssuers`;
    const params = {
      agencyId: action.payload.agencyId === undefined ? '' : action.payload.agencyId,
      producerId: action.payload.producerId === undefined ? '' : action.payload.producerId,
      ...action.payload.filters,
      ...action.payload.pagination,
      ...action.payload.order,
    };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }

    const response = yield call(updateMethod, request, JSON.stringify(action.payload));
    yield put(manageIssuerRestrictionsSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageIssuerRestrictionsFailed(message));
    } else {
      yield put(manageIssuerRestrictionsFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* allIssuerRestrictionsManage(action) {
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/producer-restricted-issuer?include=api,producerRestrictedIssuers`;
    const params = {
      ...action.payload.filters,
      ...action.payload.pagination,
      ...action.payload.order,
      agencyId: action.payload.agencyId === undefined ? '' : action.payload.agencyId,
      producerId: action.payload.producerId === undefined ? '' : action.payload.producerId,
      checkAll: action.payload.checkAll,
    };

    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }

    const response = yield call(createMethod, request, JSON.stringify(action.payload));
    yield put(manageAllIssuerRestrictionsSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageAllIssuerRestrictionsFailed(message));
    } else {
      yield put(manageAllIssuerRestrictionsFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* issuerRestrictionDelete(action) {
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/producer-restricted-issuer/${action.payload.producerRestrictedIssuerId}?include=api,producerRestrictedIssuers`;
    const params = {
      agencyId: action.payload.agencyId === undefined ? '' : action.payload.agencyId,
      producerId: action.payload.producerId === undefined ? '' : action.payload.producerId,
      ...action.payload.filters,
      ...action.payload.pagination,
      ...action.payload.order,
    };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }

    const response = yield call(patchMethod, request);
    yield put(deleteIssuerRestrictionsSuccess(response));
  } catch (error) {
    yield put(deleteIssuerRestrictionsFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* issuerDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/issuer/${action.payload.id}`);

    yield put(deleteIssuerSuccess());
  } catch (error) {
    yield put(deleteIssuerFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetIssuerList() {
  yield takeLatest(GET_ISSUER_LIST, issuerGetList);
}

export function* watchManageIssuer() {
  yield takeLeading(MANAGE_ISSUER, issuerManage);
}

export function* watchManageIssuerRestrictions() {
  yield takeLeading(MANAGE_ISSUER_RESTRICTIONS, issuerRestrictionsManage);
}

export function* watchDeleteIssuerRestrictions() {
  yield takeLatest(DELETE_ISSUER_RESTRICTIONS, issuerRestrictionDelete);
}

export function* watchManageAllIssuerRestrictions() {
  yield takeLeading(MANAGE_ALL_ISSUER_RESTRICTIONS, allIssuerRestrictionsManage);
}

export function* watchDeleteIssuer() {
  yield takeLeading(DELETE_ISSUER, issuerDelete);
}

function* issuerSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetIssuerList),
      fork(watchManageIssuer),
      fork(watchManageIssuerRestrictions),
      fork(watchManageAllIssuerRestrictions),
      fork(watchDeleteIssuerRestrictions),
      fork(watchDeleteIssuer),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default issuerSaga;
