import { createMethod } from 'helpers/backend';
import { base64toBlob } from 'helpers/downloadBase64Document';
import _ from 'lodash';

export const getBase64AzureFile = (pathName, returnBlob = false, needsEncode = true) => {
  return createMethod(`${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/get-azure-file`, JSON.stringify({ pathName }))
    .then((result) => {
      if (returnBlob) {
        return base64toBlob(result);
      }
      let base64File = result.file.replaceAll('\\', '');

      if (needsEncode) {
        base64File = encodeURIComponent(base64File);
      }

      return `data:${result.mimeType};base64,${base64File}`;
    })
    .catch((error) => {
      throw error;
    });
};

export const downloadAzureFile = (pathName, name = '') => {
  getBase64AzureFile(pathName)
    .then((base64) => {
      const download = document.createElement('a');

      download.href = base64;
      download.download = !_.isEmpty(name) ? name : pathName.split('/').pop();
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    })
    .catch((error) => {
      throw error;
    });
};
