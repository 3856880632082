import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, patchMethod, updateMethod, resolveErrorPromise, SUCCESS } from 'helpers';
import {
  GET_LINE_OF_COVERAGE_LIST,
  DELETE_LINE_OF_COVERAGE,
  REACTIVATE_LINE_OF_COVERAGE,
  GET_LINE_OF_COVERAGE,
  MANAGE_LINE_OF_COVERAGE,
  FAILED_MESSAGE,
  RESET,
  GET_API_LIST,
} from 'constants/actionTypes';
import {
  getLineOfCoverageListSuccess,
  getLineOfCoverageListFailed,
  getLineOfCoverageSuccess,
  getLineOfCoverageFailed,
  manageLineOfCoverageSuccess,
  manageLineOfCoverageFailed,
  deleteLineOfCoverageSuccess,
  deleteLineOfCoverageFailed,
  reactivateLineOfCoverageSuccess,
  reactivateLineOfCoverageFailed,
} from 'redux/actions';

function* lineOfCoverageGetList() {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/line-of-coverage?include=apiLineOfCoverages`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getLineOfCoverageListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getLineOfCoverageListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* lineOfCoverageGet(action) {
  const abortController = new AbortController();
  try {
    yield take(SUCCESS(GET_API_LIST));
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/line-of-coverage/${action.payload}?include=benefits.benefitSection,lineOfCoverageFilters`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getLineOfCoverageSuccess(response));
  } catch (error) {
    yield put(getLineOfCoverageFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* lineOfCoverageDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/line-of-coverage/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteLineOfCoverageSuccess());
  } catch (error) {
    yield put(deleteLineOfCoverageFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* lineOfCoverageReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/line-of-coverage/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(reactivateLineOfCoverageSuccess());
  } catch (error) {
    yield put(reactivateLineOfCoverageFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* lineOfCoverageManage(action) {
  try {
    yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/line-of-coverage/${action.payload.id}?include=benefits.benefitSection,lineOfCoverageFilters`,
      JSON.stringify(action.payload)
    );

    yield put(manageLineOfCoverageSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageLineOfCoverageFailed(message));
    } else {
      yield put(manageLineOfCoverageFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetLineOfCoverageList() {
  yield takeLatest(GET_LINE_OF_COVERAGE_LIST, lineOfCoverageGetList);
}

export function* watchGetLineOfCoverage() {
  yield takeLatest(GET_LINE_OF_COVERAGE, lineOfCoverageGet);
}

export function* watchDeleteLineOfCoverage() {
  yield takeLeading(DELETE_LINE_OF_COVERAGE, lineOfCoverageDelete);
}

export function* watchReactivateLineOfCoverage() {
  yield takeLeading(REACTIVATE_LINE_OF_COVERAGE, lineOfCoverageReactivate);
}

export function* watchManageLineOfCoverage() {
  yield takeLeading(MANAGE_LINE_OF_COVERAGE, lineOfCoverageManage);
}

function* lineOfCoverageSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetLineOfCoverageList),
      fork(watchGetLineOfCoverage),
      fork(watchDeleteLineOfCoverage),
      fork(watchReactivateLineOfCoverage),
      fork(watchManageLineOfCoverage),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default lineOfCoverageSaga;
