import { all, call, put, takeLeading, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage, clearMessages } from 'redux-flash';
import { getMethod, createMethod, resolveErrorPromise, patchMethod } from 'helpers';
import {
  GET_TERMS_AND_CONDITIONS_LIST,
  IMPORT_TERMS_AND_CONDITIONS_CSV,
  RESET,
  FAILED_MESSAGE,
  DELETE_TERMS_AND_CONDITIONS,
  REACTIVATE_TERMS_AND_CONDITIONS,
} from 'constants/actionTypes';
import {
  getTermsAndContionsListSuccess,
  getTermsAndConditionsListFailed,
  importTermsAndConditionsCSVSuccess,
  importTermsAndConditionsCSVFailed,
  reactivateTermsAndConditionsSuccess,
  reactivateTermsAndConditionsFailed,
  deleteTermsAndConditionsSuccess,
  deleteTermsAndConditionsFailed,
} from 'redux/actions';

function* termsAndConditionsGetList() {
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/terms-and-conditions`;
    // request data
    const response = yield call(getMethod, request);
    yield put(getTermsAndContionsListSuccess(response));
  } catch (error) {
    yield put(getTermsAndConditionsListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* termsAndConditionsDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/terms-and-conditions/${action.payload.id}`,
      JSON.stringify({ status: action.payload.status })
    );
    yield put(deleteTermsAndConditionsSuccess()); // save to redux
  } catch (error) {
    yield put(deleteTermsAndConditionsFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* termsAndConditionsReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/terms-and-conditions/${action.payload.id}`,
      JSON.stringify({ status: action.payload.status })
    );
    yield put(reactivateTermsAndConditionsSuccess()); // save to redux
  } catch (error) {
    yield put(reactivateTermsAndConditionsFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* sendImportCSV(action) {
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/terms-and-conditions`,
      JSON.stringify(action.payload)
    );

    yield put(importTermsAndConditionsCSVSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(importTermsAndConditionsCSVFailed(message));
    } else if (message?.detail) {
      yield put(importTermsAndConditionsCSVFailed(message.detail));
      yield put(clearMessages());
      yield put(flashErrorMessage(message.detail, { timeout: 30000 }));
    } else {
      yield put(importTermsAndConditionsCSVSuccess());
    }
  }
}

export function* watchGetTermsAndConditions() {
  yield takeLeading(GET_TERMS_AND_CONDITIONS_LIST, termsAndConditionsGetList);
}

export function* watchDeleteTermsAndConditions() {
  yield takeLeading(DELETE_TERMS_AND_CONDITIONS, termsAndConditionsDelete);
}

export function* watchReactivateTermsAndConditions() {
  yield takeLeading(REACTIVATE_TERMS_AND_CONDITIONS, termsAndConditionsReactivate);
}

export function* watchSendImportCSV() {
  yield takeLeading(IMPORT_TERMS_AND_CONDITIONS_CSV, sendImportCSV);
}

function* usersSaga() {
  while (true) {
    const tasks = yield all([
      call(watchGetTermsAndConditions),
      call(watchDeleteTermsAndConditions),
      call(watchReactivateTermsAndConditions),
      call(watchSendImportCSV),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default usersSaga;
