export const GET_ZIP_CODE_LIST = 'GET_ZIP_CODE_LIST';

export const RESET_ZIP_CODE_LIST = 'RESET_ZIP_CODE_LIST';

export const DELETE_ZIP_CODE = 'DELETE_ZIP_CODE';

export const GET_ZIP_CODE = 'GET_ZIP_CODE';

export const RESET_ZIP_CODE = 'RESET_ZIP_CODE';

export const MANAGE_ZIP_CODE = 'MANAGE_ZIP_CODE';
