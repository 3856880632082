import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  GET_ADVERTISMENT,
  MANAGE_ADVERTISMENT,
  RESET_ADVERTISMENT,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE_ADVERTISING = {
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
  data: {
    id: '',
    logo: '',
    url: '',
  },
};

const Advertising = (state = INIT_STATE_ADVERTISING, action) => {
  switch (action.type) {
    case GET_ADVERTISMENT:
      return { ...state, success: false, error: false, loading: true };
    case SUCCESS(GET_ADVERTISMENT):
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILED(GET_ADVERTISMENT):
      return { ...state, loading: false, error: true };
    case MANAGE_ADVERTISMENT:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_ADVERTISMENT):
      return {
        ...state,
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_ADVERTISMENT):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_ADVERTISMENT:
    case RESET:
      return INIT_STATE_ADVERTISING;
    default:
      return state;
  }
};

export default combineReducers({
  Advertising,
});

const getAdvertisingLogoFromState = (state) => state.Broker.Advertising.Advertising; // all slice
export const getAdvertisingLogoFromStateSelector = createSelector([getAdvertisingLogoFromState], (carrier) => {
  // forward
  return {
    ...carrier.data,
  };
});
