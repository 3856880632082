import { RESEND_CODE, TWO_FA, TWO_FA_VERIFICATION } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const twoFa = () => ({
  type: TWO_FA,
  payload: null,
});

export const twoFaSuccess = tokenVerification => ({
  type: SUCCESS(TWO_FA),
  payload: tokenVerification,
});

export const twoFaFailed = error => ({
  type: FAILED(TWO_FA),
  payload: error,
});

export const resetTwoFa = () => ({
  type: RESETED(TWO_FA),
});

export const verifyTwoFa = (tokenVerification, history) => ({
  type: TWO_FA_VERIFICATION,
  payload: tokenVerification,
  history,
});

export const verifyTwoFaSuccess = tokenVerification => ({
  type: SUCCESS(TWO_FA_VERIFICATION),
  payload: tokenVerification,
});

export const verifyTwoFaFailed = error => ({
  type: FAILED(TWO_FA_VERIFICATION),
  payload: error,
});

export const resendCode = tokenVerification => ({
  type: RESEND_CODE,
  payload: tokenVerification,
});

export const resendCodeSuccess = () => ({
  type: SUCCESS(RESEND_CODE),
});
export const resendCodeFailed = () => ({
  type: FAILED(RESEND_CODE),
});
