import { LOGIN_USER, LOGOUT_USER, INFO_USER, UPDATE_USER, RESET, IMPERSONATE_USER } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const loginUser = (username, password, history) => ({
  type: LOGIN_USER,
  payload: { username, password },
  history,
});

export const loginUserSuccess = user => ({
  type: SUCCESS(LOGIN_USER),
  payload: user,
});

export const loginUserFailed = error => ({
  type: FAILED(LOGIN_USER),
  payload: error,
});

export const resetLoginUser = () => ({
  type: RESETED(LOGIN_USER),
});

export const infoUser = () => ({
  type: INFO_USER,
  payload: null,
});

export const infoUserSuccess = user => ({
  type: SUCCESS(INFO_USER),
  payload: user,
});

export const infoUserFailed = error => ({
  type: FAILED(INFO_USER),
  payload: error,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const reset = () => ({
  type: RESET,
});

export const logoutUserSuccess = () => ({
  type: SUCCESS(LOGOUT_USER),
});

export const logoutUserFailed = error => ({
  type: FAILED(LOGOUT_USER),
  payload: error,
});

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserSuccess = user => ({
  type: SUCCESS(UPDATE_USER),
  payload: user,
});

export const updateUserFailed = (error = null) => ({
  type: FAILED(UPDATE_USER),
  payload: error,
});

export const impersonateUser = data => ({
  type: IMPERSONATE_USER,
  payload: data,
});

export const impersonateUserSuccess = () => ({
  type: SUCCESS(IMPERSONATE_USER),
});

export const impersonateUserFailed = (error = null) => ({
  type: FAILED(IMPERSONATE_USER),
  payload: error,
});
