import { GET_LOGIN_HISTORY_LIST, RESET, RESET_LOGIN_HISTORY_LIST } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
  data: {},
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN_HISTORY_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_LOGIN_HISTORY_LIST):
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: false,
        error: false,
      };
    case FAILED(GET_LOGIN_HISTORY_LIST):
      return { ...state, loading: false };
    case RESET_LOGIN_HISTORY_LIST:
    case RESET:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

export default List;
