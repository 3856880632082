import { combineReducers } from 'redux';
import { GET_LOG_LIST, RESET, RESET_LOG_LIST } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  data: {
    data: [],
    meta: {
      pagination: {
        total: 0
      }
    },
  },
  loading: false,
};

const LogList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOG_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_LOG_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_LOG_LIST):
      return { ...state, loading: false };
    case RESET:
    case RESET_LOG_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  LogList,
});
