import {
  GET_BAA_SIGNATURE_LIST,
  DELETE_BAA_SIGNATURE,
  GET_BAA_SIGNATURE,
  MANAGE_BAA_SIGNATURE,
  RESET_BAA_SIGNATURE,
  REACTIVATE_BAA_SIGNATURE,
  RESET_BAA_SIGNATURE_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getBaaSignatureList = () => ({
  type: GET_BAA_SIGNATURE_LIST,
});

export const getBaaSignatureListSuccess = data => ({
  type: SUCCESS(GET_BAA_SIGNATURE_LIST),
  payload: data,
});

export const getBaaSignatureListFailed = error => ({
  type: FAILED(GET_BAA_SIGNATURE_LIST),
  payload: error,
});

export const resetBaaSignatureList = () => ({
  type: RESET_BAA_SIGNATURE_LIST,
});

export const deleteBaaSignature = BaaSignature => ({
  type: DELETE_BAA_SIGNATURE,
  payload: BaaSignature,
});

export const deleteBaaSignatureSuccess = () => ({
  type: SUCCESS(DELETE_BAA_SIGNATURE),
});

export const deleteBaaSignatureFailed = () => ({
  type: FAILED(DELETE_BAA_SIGNATURE),
});

export const resetDeleteBaaSignature = () => ({
  type: RESETED(DELETE_BAA_SIGNATURE),
});

export const reactivateBaaSignature = baaSignature => ({
  type: REACTIVATE_BAA_SIGNATURE,
  payload: baaSignature,
});

export const reactivateBaaSignatureSuccess = () => ({
  type: SUCCESS(REACTIVATE_BAA_SIGNATURE),
});

export const reactivateBaaSignatureFailed = () => ({
  type: FAILED(REACTIVATE_BAA_SIGNATURE),
});

export const resetReactivateBaaSignature = () => ({
  type: RESETED(REACTIVATE_BAA_SIGNATURE),
});

export const getBaaSignature = baaSignatureId => ({
  type: GET_BAA_SIGNATURE,
  payload: baaSignatureId,
});

export const getBaaSignatureSuccess = data => ({
  type: SUCCESS(GET_BAA_SIGNATURE),
  payload: data,
});

export const getBaaSignatureFailed = () => ({
  type: FAILED(GET_BAA_SIGNATURE),
});

export const manageBaaSignature = data => ({
  type: MANAGE_BAA_SIGNATURE,
  payload: data,
});

export const manageBaaSignatureSuccess = () => ({
  type: SUCCESS(MANAGE_BAA_SIGNATURE),
});

export const manageBaaSignatureFailed = error => ({
  type: FAILED(MANAGE_BAA_SIGNATURE),
  payload: error,
});

export const resetBaaSignature = () => ({
  type: RESET_BAA_SIGNATURE,
});
