import {
  GET_CARRIER_FORMS_LIST,
  DELETE_CARRIER_FORMS,
  GET_CARRIER_FORMS,
  MANAGE_CARRIER_FORMS,
  RESET_CARRIER_FORMS,
  RESET_CARRIER_FORMS_LIST,
} from 'constants/actionTypes';

import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getCarrierFormsList = (data = {}) => ({
  type: GET_CARRIER_FORMS_LIST,
  payload: data,
});

export const getCarrierFormsListSuccess = data => ({
  type: SUCCESS(GET_CARRIER_FORMS_LIST),
  payload: data,
});

export const getCarrierFormsListFailed = data => ({
  type: FAILED(GET_CARRIER_FORMS_LIST),
  payload: data,
});

export const resetCarrierFormsList = () => ({
  type: RESET_CARRIER_FORMS_LIST,
});

export const deleteCarrierForms = (carrierFormId) => ({
  type: DELETE_CARRIER_FORMS,
  payload: carrierFormId,
});

export const deleteCarrierFormsSuccess = () => ({
  type: SUCCESS(DELETE_CARRIER_FORMS),
});

export const deleteCarrierFormsFailed = () => ({
  type: FAILED(DELETE_CARRIER_FORMS),
});

export const resetDeleteCarrierForms = () => ({
  type: RESETED(DELETE_CARRIER_FORMS),
});

export const getCarrierForms = (carrierFormId) => ({
  type: GET_CARRIER_FORMS,
  payload: carrierFormId,
});

export const getCarrierFormsSuccess = data => ({
  type: SUCCESS(GET_CARRIER_FORMS),
  payload: data,
});

export const getCarrierFormsFailed = () => ({
  type: FAILED(GET_CARRIER_FORMS),
});

export const manageCarrierForms = data => ({
  type: MANAGE_CARRIER_FORMS,
  payload: data,
});

export const manageCarrierFormsSuccess = data => ({
  type: SUCCESS(MANAGE_CARRIER_FORMS),
  payload: data,
});

export const manageCarrierFormsFailed = (error = null) => ({
  type: FAILED(MANAGE_CARRIER_FORMS),
  payload: error,
});

export const resetCarrierForms = () => ({
  type: RESET_CARRIER_FORMS,
});
