import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import { RESETED, createMethod } from 'helpers';

import { GET_REPORTING_LIST, RESET, FAILED_MESSAGE, GET_REPORTING_FILTER } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import {
  getReportingListSuccess,
  getReportingListFailed,
  getReportingFilterSuccess,
  getReportingFilterFailed,
} from './actions';

function* reportingListGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/reporting`;

    const response = yield call(createMethod, request, JSON.stringify(action.payload));

    yield put(getReportingListSuccess(response));
  } catch (error) {
    yield put(getReportingListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* reportingFilterGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/reporting/filters`;

    const response = yield call(createMethod, request, JSON.stringify(action.payload));

    yield put(getReportingFilterSuccess(response));
  } catch (error) {
    yield put(getReportingFilterFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetReportingList() {
  yield takeLeading(GET_REPORTING_LIST, reportingListGet);
}

export function* watchGetReportingFilter() {
  yield takeLeading(GET_REPORTING_FILTER, reportingFilterGet);
}

function* reportingSaga() {
  while (true) {
    const tasks = yield all([fork(watchGetReportingList), fork(watchGetReportingFilter)]);

    yield all([take(RESET), take(RESETED(GET_REPORTING_LIST)), take(RESETED(GET_REPORTING_FILTER))]);

    yield cancel(tasks);
  }
}

export default reportingSaga;
