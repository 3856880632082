import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  GET_BENEFIT_SECTION_LIST,
  DELETE_BENEFIT_SECTION,
  GET_BENEFIT_SECTION,
  MANAGE_BENEFIT_SECTION,
  RESET_BENEFIT_SECTION,
  RESET_BENEFIT_SECTION_LIST,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE_LIST = {
  data: {
    data: [],
  },
  loading: false,
  success: false,
  error: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
};

const List = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_BENEFIT_SECTION_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_BENEFIT_SECTION_LIST):
      return { ...state, data: action.payload, source: action.source, loading: false, success: true };
    case FAILED(GET_BENEFIT_SECTION_LIST):
      return { ...state, loading: false, error: true };
    case DELETE_BENEFIT_SECTION:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_BENEFIT_SECTION):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_BENEFIT_SECTION):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_BENEFIT_SECTION):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_BENEFIT_SECTION_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_BENEFIT_SECTION = {
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
  data: {
    id: '',
    benefitSectionName: '',
    displayOrder: '',
  },
};

const Benefit = (state = INIT_STATE_BENEFIT_SECTION, action) => {
  switch (action.type) {
    case GET_BENEFIT_SECTION:
      return { ...state, success: false, error: false, loading: true };
    case SUCCESS(GET_BENEFIT_SECTION):
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case FAILED(GET_BENEFIT_SECTION):
      return { ...state, loading: false, error: true };
    case MANAGE_BENEFIT_SECTION:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_BENEFIT_SECTION):
      return {
        ...state,
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_BENEFIT_SECTION):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_BENEFIT_SECTION:
    case RESET:
      return INIT_STATE_BENEFIT_SECTION;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  Benefit,
});

const getBenefitSectionListFromState = (state) => state.Broker.BenefitSection.List;

export const getBenefitSectionListSelector = createSelector([getBenefitSectionListFromState], (data) => {
  return data.data;
});

export const getLoadingBenefitSectionListSelector = createSelector([getBenefitSectionListFromState], (data) => {
  return data.loading;
});
