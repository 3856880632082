export const GET_CARRIER_FORMS_LIST = 'GET_CARRIER_FORMS_LIST';

export const RESET_CARRIER_FORMS_LIST = 'RESET_CARRIER_FORMS_LIST';

export const GET_CARRIER_FORMS = 'GET_CARRIER_FORMS';

export const MANAGE_CARRIER_FORMS = 'MANAGE_CARRIER_FORMS';

export const DELETE_CARRIER_FORMS = 'DELETE_CARRIER_FORMS';

export const RESET_CARRIER_FORMS = 'RESET_CARRIER_FORMS';