import React, { PureComponent } from 'react';

/**
 * Renders the preloader
 */
class PreLoaderWidget extends PureComponent {
  render() {
    return (
      // <div className="preloader">
      //     <div className="status">
      //         <div className="spinner-border avatar-sm text-primary m-2" role="status"></div>
      //     </div>
      // </div>

      <div id="preloader">
        <div id="status">
          <div className="bouncingLoader">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }
}

export default PreLoaderWidget;
