import { createMethod, getMethod } from 'helpers/backend';

export const downloadBase64Document = (name, endpoint, body = null) => {
  let request;
  if (body !== null) {
    request = createMethod(endpoint, JSON.stringify(body));
  } else {
    request = getMethod(endpoint);
  }

  return request
    .then((result) => {
      const base64File = result.file.replaceAll('\\', '');
      let mimeType = 'application/pdf';
      if (result.mimeType) {
        mimeType = result.mimeType;
      }
      const download = document.createElement('a');

      download.href = `data:${mimeType};base64,${encodeURIComponent(base64File)}`;
      download.download = name;
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    })
    .catch((error) => {
      throw error;
    });
};

export const base64toBlob = (base64Data) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data.file);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  // eslint-disable-next-line no-plusplus
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    // eslint-disable-next-line no-plusplus
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: base64Data.mimeType });
};

export const getBase64DocumentBlob = (endpoint, body = null) => {
  let request;
  if (body !== null) {
    request = createMethod(endpoint, JSON.stringify(body));
  } else {
    request = getMethod(endpoint);
  }

  return request
    .then((result) => base64toBlob(result))
    .catch((error) => {
      throw error;
    });
};
