import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import { createMethod, deleteMethod, getMethod, RESETED, resolveErrorPromise, updateMethod } from 'helpers';

import {
  GET_EFFECTIVE_DATES_LIST,
  GET_EFFECTIVE_DATES,
  MANAGE_EFFECTIVE_DATES,
  DELETE_EFFECTIVE_DATES,
  RESET,
  FAILED_MESSAGE,
} from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import {
  getEffectiveDatesListSuccess,
  getEffectiveDatesListFailed,
  getEffectiveDatesSuccess,
  getEffectiveDatesFailed,
  manageEffectiveDatesSuccess,
  manageEffectiveDatesFailed,
  deleteEffectiveDatesFailed,
  deleteEffectiveDatesSuccess,
  getEffectiveDatesList
} from './actions';

function* effectiveDatesListGet() {
  const abortController = new AbortController();
  try {
    // request data
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/effective-dates`,
      abortController.signal
    );

    yield put(getEffectiveDatesListSuccess(response));
  } catch (error) {
    yield put(getEffectiveDatesListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* effectiveDatesGet(action) {
  const abortController = new AbortController();

  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/effective-dates/${action.payload}`,
      abortController.signal
    );

    yield put(getEffectiveDatesSuccess(response));
  } catch (error) {
    yield put(getEffectiveDatesFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* effectiveDatesManage(action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/effective-dates/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/effective-dates`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageEffectiveDatesSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageEffectiveDatesFailed(message));
    } else {
      yield put(manageEffectiveDatesFailed());

      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* effectiveDatesDelete(action) {
  try {
    yield call(
      deleteMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/effective-dates/${action.payload}`
    );

    yield put(deleteEffectiveDatesSuccess());
    yield put(getEffectiveDatesList());
  } catch (error) {
    yield put(deleteEffectiveDatesFailed());

    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetEffectiveDatesList() {
  yield takeLeading(GET_EFFECTIVE_DATES_LIST, effectiveDatesListGet);
}

export function* watchGetEffectiveDates() {
  yield takeLeading(GET_EFFECTIVE_DATES, effectiveDatesGet);
}

export function* watchManageEffectiveDates() {
  yield takeLeading(MANAGE_EFFECTIVE_DATES, effectiveDatesManage);
}

export function* watchDeleteEffectiveDates() {
  yield takeLeading(DELETE_EFFECTIVE_DATES, effectiveDatesDelete);
}

function* effectiveDatesSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetEffectiveDatesList),
      fork(watchGetEffectiveDates),
      fork(watchManageEffectiveDates),
      fork(watchDeleteEffectiveDates)
    ]);

    yield all([take(RESET), take(RESETED(GET_EFFECTIVE_DATES_LIST)), take(RESETED(GET_EFFECTIVE_DATES))]);

    yield cancel(tasks);
  }
}

export default effectiveDatesSaga;
