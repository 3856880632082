export const GET_USER = 'GET_USER';

export const GET_USER_LIST = 'GET_USER_LIST';

export const RESET_USER_LIST = 'RESET_USER_LIST';

export const DELETE_USER = 'DELETE_USER';

export const REACTIVATE_USER = 'REACTIVATE_USER';

export const MANAGE_USER = 'MANAGE_USER';

export const RESET_USER = 'RESET_USER';
