import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  GET_BAA_SIGNATURE_LIST,
  DELETE_BAA_SIGNATURE,
  REACTIVATE_BAA_SIGNATURE,
  GET_BAA_SIGNATURE,
  MANAGE_BAA_SIGNATURE,
  RESET_BAA_SIGNATURE,
  RESET,
  RESET_BAA_SIGNATURE_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  data: {
    data: [],
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
  reactivatedSuccess: false,
  reactivatedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BAA_SIGNATURE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_BAA_SIGNATURE_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_BAA_SIGNATURE_LIST):
      return { ...state, loading: false };
    case DELETE_BAA_SIGNATURE:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_BAA_SIGNATURE):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_BAA_SIGNATURE):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_BAA_SIGNATURE):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_BAA_SIGNATURE:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_BAA_SIGNATURE):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_BAA_SIGNATURE):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_BAA_SIGNATURE):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case RESET:
    case RESET_BAA_SIGNATURE_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_BAA_SIGNATURE = {
  baaSignature: {
    data: {
      id: '',
      path: '',
      authorizedSignature: '',
      printName: '',
      createdAt: '',
      isDeleted: false,
    },
  },
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
};

const BaaSignature = (state = INIT_STATE_BAA_SIGNATURE, action) => {
  switch (action.type) {
    case GET_BAA_SIGNATURE:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_BAA_SIGNATURE):
      return {
        ...state,
        baaSignature: _.defaultsDeep(action.payload, INIT_STATE_BAA_SIGNATURE.baaSignature),
        loading: false,
        error: false,
      };
    case FAILED(GET_BAA_SIGNATURE):
      return { ...state, loading: false, error: true };
    case MANAGE_BAA_SIGNATURE:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_BAA_SIGNATURE):
      return {
        ...state,
        baaSignature: _.defaultsDeep(action.payload, INIT_STATE_BAA_SIGNATURE.baaSignature),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_BAA_SIGNATURE):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_BAA_SIGNATURE:
    case RESET:
      return INIT_STATE_BAA_SIGNATURE;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  BaaSignature,
});

const getBaaSignatureBaaSignatureFromState = state => state.Broker.BaaSignatures.BaaSignature.baaSignature;
export const getBaaSignatureSelector = createSelector([getBaaSignatureBaaSignatureFromState], baaSignature => {
  return {
    ...baaSignature.data,
  };
});
const getBaaSignatureListBaaSignatureFromState = state => state.Broker.BaaSignatures.List;

export const getBaaSignatureListSelector = createSelector([getBaaSignatureListBaaSignatureFromState], baaSignatures => {
  return {
    ...baaSignatures.data,
  };
});

const getAuthAuthUserDataPersonDataIdFromState = state => state.Auth.Auth.user.data.person.data.id;
export const ownSignatureSelector = createSelector(
  [getBaaSignatureListBaaSignatureFromState, getAuthAuthUserDataPersonDataIdFromState],
  (baaSignatures, id) => {
    const ownSignature = baaSignatures.data.data.filter(element => element.person === id && element.isDeleted === 0);
    if (Array.isArray(ownSignature) && ownSignature.length > 0) {
      return ownSignature[0].id;
    }

    return undefined;
  }
);
