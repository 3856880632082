import React, { Component, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import { getBase64AzureFile } from 'helpers/azure';
import { routes } from 'routes';
import _ from 'lodash';
import Loader from './components/Loader';
import { isUserAuthenticated } from './helpers/index';
import './assets/scss/DefaultTheme.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import './assets/libs/datatables/dataTables.bootstrap4.css';
import ErrorBoundary from './components/Custom/ErrorBoundary';
import ScrollToTop from './components/Custom/ScrollToTop';

_.VERSION = undefined;

const loading = () => <div />;

const NonAuthLayout = Loadable({
  loader: () => import('./components/NonAuthLayout'),
  render(loaded, props) {
    const NonAuthComponent = loaded.default;

    return <NonAuthComponent {...props} />;
  },
  loading,
});

const AuthLayout = Loadable({
  loader: () => import('./components/AuthLayout'),
  render(loaded, props) {
    const AuthComponent = loaded.default;

    return <AuthComponent {...props} />;
  },
  loading,
});

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = WrappedComponent => {
  function HOC(props) {
    return <WrappedComponent {...props} />;
  }

  return connect()(HOC);
};

class App extends Component {
  componentDidMount() {
    const preloader = document.getElementById('preloader');
    if (preloader) preloader.remove();
  }

  getLayout = () => {
    return isUserAuthenticated() ? AuthLayout : NonAuthLayout;
  };

  componentDidUpdate() {
    const { hasInfo, user } = this.props;
    if (hasInfo && user.data.person.data.profilePicture ) {

      sessionStorage.setItem('ProfileImage', user.data.person.data.profilePicture);
    } else {
      sessionStorage.removeItem('ProfileImage');
    }
  }

  render() {
    const { hasInfo } = this.props;

    return (
      <BrowserRouter>
        <>
          <ErrorBoundary>
            <Switch>
              {routes.map((route, index) => {
                return (
                  <route.route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    routeName={route.name}
                    apiRoute={route.apiRoute}
                    roles={route.roles}
                    component={withLayout(props => {
                      const Layout = this.getLayout();
                      if (isUserAuthenticated()) {
                        return (
                          <Suspense fallback={<Loader />}>
                            <Layout {...props}>{hasInfo ? <route.component {...props} /> : <Loader />}</Layout>
                          </Suspense>
                        );
                      }

                      return (
                        <Suspense fallback={<Loader />}>
                          <Layout {...props}>
                            <route.component {...props} />
                          </Layout>
                        </Suspense>
                      );
                    })}
                  />
                );
              })}
            </Switch>
          </ErrorBoundary>
          <ScrollToTop />
        </>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  const { hasInfo, user } = state.Auth.Auth;

  return { hasInfo, user };
};

export default connect(mapStateToProps)(App);
