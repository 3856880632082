import {DELETE_ACTIVITY, GET_ACTIVITY_LIST} from 'constants/actionTypes';
import {SUCCESS, FAILED} from 'helpers/actionTypesUtils';

export const getActivityList = data => ({
  type: GET_ACTIVITY_LIST,
  payload: data,
});

export const getActivityListSuccess = data => ({
  type: SUCCESS(GET_ACTIVITY_LIST),
  payload: data,
});

export const getActivityListFailed = data => ({
  type: FAILED(GET_ACTIVITY_LIST),
  payload: data,
});

export const deleteActivity = id => ({
  type: DELETE_ACTIVITY,
  payload: { id },
});

export const deleteActivitySuccess = () => ({
  type: SUCCESS(DELETE_ACTIVITY),
});

export const deleteActivityFailed = () => ({
  type: FAILED(DELETE_ACTIVITY),
});
