import React from 'react';

class ScrollToTop extends React.Component {
  state = { isVisible: false };

  componentDidMount() {
    document.addEventListener('scroll', this.toggleVisibility);
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      this.setState({
        isVisible: true,
      });
    } else {
      this.setState({
        isVisible: false,
      });
    }
  };

  render() {
    const { isVisible } = this.state;

    return (
      <div>
        {isVisible && (
          <button onClick={() => this.scrollToTop()} type="button" id="ScrollToTop">
            <i className="ti-angle-double-up" />
          </button>
        )}
      </div>
    );
  }
}

export default ScrollToTop;
