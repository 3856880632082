import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { patchMethod, getMethod, createMethod, updateMethod, resolveErrorPromise } from 'helpers';
import {
  GET_DOCUMENT,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LOG_LIST,
  MANAGE_DOCUMENT,
  FAILED_MESSAGE,
  DELETE_DOCUMENT,
  RESET,
} from 'constants/actionTypes';
import {
  getDocumentListSuccess,
  getDocumentListFailed,
  manageDocumentSuccess,
  manageDocumentFailed,
  deleteDocumentSuccess,
  deleteDocumentFailed,
  getDocumentLogListSuccess,
  getDocumentLogListFailed,
  getDocumentList,
  getDocumentSuccess,
  getDocumentFailed,
} from './actions';

function* documentGetList(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/document`;

    if (action.payload.producer && action.payload.producer.length > 0) {
      const paramString = new URLSearchParams({ ...action.payload }).toString();
      request += `?${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getDocumentListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getDocumentListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* documentGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/document/${action.payload.documentId}`,
      abortController.signal
    );
    yield put(getDocumentSuccess(data));
  } catch (error) {
    yield put(getDocumentFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* documentDelete(action) {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/document/${action.payload.id}`;
    yield call(patchMethod, endpoint, JSON.stringify({ isDeleted: action.payload.isDeleted }));
    yield put(deleteDocumentSuccess()); // save to redux
    yield put(getDocumentList(action)); // save to redux
  } catch (error) {
    yield put(deleteDocumentFailed()); // save to redux
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* documentManage(action) {
  try {
    let response;
    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/document/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/document`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageDocumentSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageDocumentFailed(message));
    } else {
      yield put(manageDocumentFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* documentLogGetList(action) {
  const abortController = new AbortController();
  const { documentId } = action.payload;
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/document/${documentId}/document-log?include=user.person`;
    let params = {};
    params = { ...params, ...action.payload.filters, ...action.payload.pagination, ...action.payload.order };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getDocumentLogListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getDocumentLogListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetDocumentList() {
  yield takeLatest(GET_DOCUMENT_LIST, documentGetList);
}

export function* watchManageDocument() {
  yield takeLeading(MANAGE_DOCUMENT, documentManage);
}

export function* watchDeleteDocument() {
  yield takeLeading(DELETE_DOCUMENT, documentDelete);
}

export function* watchGetDocumentLogList() {
  yield takeLatest(GET_DOCUMENT_LOG_LIST, documentLogGetList);
}

export function* watchGetDocument() {
  yield takeLatest(GET_DOCUMENT, documentGet);
}

function* baaSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetDocumentList),
      fork(watchManageDocument),
      fork(watchDeleteDocument),
      fork(watchGetDocumentLogList),
      fork(watchGetDocument),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default baaSaga;
