export const GET_API_LIST = 'GET_API_LIST';

export const RESET_API_LIST = 'RESET_API_LIST';

export const GET_API = 'GET_API';

export const GET_API_FORM_CONFIG = 'GET_API_FORM_CONFIG';

export const MANAGE_API = 'MANAGE_API';

export const DELETE_API = 'DELETE_API';

export const REACTIVATE_API = 'REACTIVATE_API';

export const RESET_API = 'RESET_API';
