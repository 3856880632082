export const GET_CREDIT_PACKAGE_LIST = 'GET_CREDIT_PACKAGE_LIST';

export const EXPORT_CREDIT_PACKAGE = 'EXPORT_CREDIT_PACKAGE';

export const DELETE_CREDIT_PACKAGE = 'DELETE_CREDIT_PACKAGE';

export const GET_CREDIT_PACKAGE = 'GET_CREDIT_PACKAGE';

export const MANAGE_CREDIT_PACKAGE = 'MANAGE_CREDIT_PACKAGE';

export const RESET_CREDIT_PACKAGE = 'RESET_CREDIT_PACKAGE';
