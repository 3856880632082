import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import {deleteMethod, getMethod, RESETED, resolveErrorPromise} from 'helpers';

import {GET_ACTIVITY_LIST, RESET, FAILED_MESSAGE, DELETE_ACTIVITY} from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import { getActivityListSuccess, getActivityListFailed, deleteActivitySuccess, deleteActivityFailed, getActivityList } from './actions';

function* activityListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/activity-errors?include=producer.person.contacts,producer.agency`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getActivityListSuccess(response));
  } catch (error) {
    yield put(getActivityListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* activityDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/activity-errors/${action.payload.id}`);

    yield put(deleteActivitySuccess()); // save to redux
    yield put(getActivityList());
  } catch (error) {
    yield put(deleteActivityFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetActivityList() {
  yield takeLeading(GET_ACTIVITY_LIST, activityListGet);
}

export function* watchDeleteActivity() {
  yield takeLeading(DELETE_ACTIVITY, activityDelete);
}

function* activitySaga() {
  while (true) {
    const tasks = yield all([fork(watchGetActivityList), fork(watchDeleteActivity)]);

    yield all([take(RESET), take(RESETED(GET_ACTIVITY_LIST))]);

    yield cancel(tasks);
  }
}

export default activitySaga;
