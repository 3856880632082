import {
  GET_QUESTION_LIST,
  RESET_QUESTION_LIST,
  DELETE_QUESTION,
  REACTIVATE_QUESTION,
  GET_QUESTION,
  MANAGE_QUESTION,
  RESET_QUESTION,
  IMPORT_CSV,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const getQuestionList = data => ({
  type: GET_QUESTION_LIST,
  payload: data,
});

export const getQuestionListSuccess = data => ({
  type: SUCCESS(GET_QUESTION_LIST),
  payload: data,
});

export const getQuestionListFailed = error => ({
  type: FAILED(GET_QUESTION_LIST),
  payload: error,
});

export const resetQuestionList = () => ({
  type: RESET_QUESTION_LIST,
});

export const deleteQuestion = data => ({
  type: DELETE_QUESTION,
  payload: data,
});

export const deleteQuestionSuccess = () => ({
  type: SUCCESS(DELETE_QUESTION),
});

export const deleteQuestionFailed = () => ({
  type: FAILED(DELETE_QUESTION),
});

export const deleteQuestionReset = () => ({
  type: RESETED(DELETE_QUESTION),
});

export const reactivateQuestion = data => ({
  type: REACTIVATE_QUESTION,
  payload: data,
});

export const reactivateQuestionSuccess = () => ({
  type: SUCCESS(REACTIVATE_QUESTION),
});

export const reactivateQuestionFailed = () => ({
  type: FAILED(REACTIVATE_QUESTION),
});

export const reactivateQuestionReset = () => ({
  type: RESETED(REACTIVATE_QUESTION),
});

export const getQuestion = questionId => ({
  type: GET_QUESTION,
  payload: { questionId },
});

export const getQuestionSuccess = question => ({
  type: SUCCESS(GET_QUESTION),
  payload: question,
});

export const getQuestionFailed = () => ({
  type: FAILED(GET_QUESTION),
});

export const manageQuestion = question => ({
  type: MANAGE_QUESTION,
  payload: question,
});

export const manageQuestionSuccess = question => ({
  type: SUCCESS(MANAGE_QUESTION),
  payload: question,
});

export const manageQuestionFailed = (error = null) => ({
  type: FAILED(MANAGE_QUESTION),
  payload: error,
});

export const resetQuestion = () => ({
  type: RESET_QUESTION,
});

export const importCSV = data => ({
  type: IMPORT_CSV,
  payload: data,
});

export const importCSVSuccess = data => ({
  type: SUCCESS(IMPORT_CSV),
  payload: data,
});

export const importCSVFailed = error => ({
  type: FAILED(IMPORT_CSV),
  payload: error,
});
