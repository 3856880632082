import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  GET_ROLE_LIST,
  MANAGE_ROLE,
  GET_ROLE,
  RESET_ROLE,
  DELETE_ROLE,
  RESET,
  RESET_ROLE_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  loading: false,
  error: false,
  deletedRole: false,
  data: {
    data: [],
  },
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_ROLE_LIST):
      return { ...state, data: action.payload, loading: false, error: false };
    case FAILED(GET_ROLE_LIST):
      return { ...state, error: action.payload, loading: false };
    case SUCCESS(DELETE_ROLE):
      return { ...state, loading: false, error: false, deletedRole: true };
    case FAILED(DELETE_ROLE):
      return { ...state, error: action.payload, loading: false, deletedRole: false };
    case RESETED(DELETE_ROLE):
      return { ...state, loading: false, deletedRole: false };
    case RESET:
    case RESET_ROLE_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const ROLE_INIT_STATE = {
  loading: false,
  isSubmitting: false,
  error: false,
  success: false,
  role: {
    data: {
      id: '',
      name: '',
      isAdmin: false,
      routes: {
        data: [],
      },
    },
  },
};

const Role = (state = ROLE_INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ROLE:
    case RESET:
      return ROLE_INIT_STATE;

    case GET_ROLE:
      return { ...state, error: false, loading: true, success: false };
    case SUCCESS(GET_ROLE):
      return { ...state, role: action.payload, loading: false, error: null };
    case FAILED(GET_ROLE):
      return { ...state, error: action.payload, loading: false };

    case MANAGE_ROLE:
      return { ...state, loading: false, isSubmitting: true };
    case SUCCESS(MANAGE_ROLE):
      return { ...state, role: action.payload, loading: false, error: null, success: 'Successfully saved role' };
    case FAILED(MANAGE_ROLE):
      return { ...state, error: action.payload, loading: false, isSubmitting: false };

    default:
      return state;
  }
};

export default combineReducers({
  List,
  Role,
});

// selectors
const getRoleRoleFromState = state => state.Acl.Roles.Role.role;
export const getRoleSelector = createSelector([getRoleRoleFromState], role => {
  return { ...role.data, routes: role.data.routes.data };
});

const getRolesDataFromState = state => state.Acl.Roles.List.data;
export const getRolesSelector = createSelector([getRolesDataFromState], data => {
  return data;
});

export const getRolesNameSelector = createSelector([getRolesDataFromState], data => {
  return data.data.map(role => role.name);
});

// Producer Roles [Admin, Producer]
const getProducerRolesDataFromState = state => state.Acl.Roles.List.data;
export const getProducerRolesSelector = createSelector([getProducerRolesDataFromState], data => {
  const producerRoles = [];
  producerRoles.data = [];
  if (data.data.length > 0) {
    data.data.forEach(function(element) {
      if (element.name === 'Admin' || element.name === 'Producer') {
        const elementArray = {};
        elementArray.id = element.id;
        elementArray.name = element.name;
        producerRoles.data.push(elementArray);
      }
    });
  }

  return producerRoles;
});
