import { combineReducers } from 'redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  GET_USER,
  GET_USER_LIST,
  DELETE_USER,
  REACTIVATE_USER,
  MANAGE_USER,
  RESET_USER,
  RESET,
  RESET_USER_LIST, IMPERSONATE_USER,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  deletedUser: false,
  reactivatedUser: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_LIST:
    case IMPERSONATE_USER:
      return { ...state, loading: true };
    case SUCCESS(GET_USER_LIST):
      return { ...state, data: action.payload, error: false, loading: false };
    case FAILED(GET_USER_LIST):
    case FAILED(IMPERSONATE_USER):
      return { ...state, error: action.payload, loading: false };
    case DELETE_USER:
      return { ...state, loading: true };
    case SUCCESS(DELETE_USER):
      return { ...state, deletedUser: true, loading: false };
    case FAILED(DELETE_USER):
      return { ...state, deletedUser: false };
    case RESETED(DELETE_USER):
      return { ...state, deletedUser: false };
    case REACTIVATE_USER:
      return { ...state, loading: true };
    case SUCCESS(REACTIVATE_USER):
      return { ...state, reactivatedUser: true, loading: false };
    case FAILED(REACTIVATE_USER):
      return { ...state, reactivatedUser: false };
    case RESETED(REACTIVATE_USER):
      return { ...state, reactivatedUser: false };
    case RESET:
    case RESET_USER_LIST:
    case SUCCESS(IMPERSONATE_USER):
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_USER = {
  user: {
    data: {
      id: '',
      email: '',
      isDeleted: '',
      password: '',
      verifyPassword: '',
      hasTwoFactorAuthentication: false,
      daysOfInactiveAccount: '',
      daysUntilPasswordChange: '',
      hasMoreLoginAttempts: false,
      lastPasswordDate: '',
      isSuspended: false,
      code: '',
      expires: '',
      createdBy: '',
      createdAt: '',
      updatedBy: '',
      updatedAt: '',
      person: {
        data: {
          id: '',
          title: '',
          suffix: '',
          lastName: '',
          firstName: '',
          middleName: '',
          ssn: '',
          gender: '',
          birthDate: '',
          maritalStatus: '',
          profilePicture: '',
          contacts: {
            data: [
              { id: '', type: 'Email', value: '' },
              { id: '', type: 'Phone', value: '' },
            ],
          },
        },
      },
      roles: {
        data: [],
      },
    },
  },
  success: false,
  errors: null,
  loading: false,
  isSubmitting: false,
  needsRedirect: false,
};

const User = (state = INIT_STATE_USER, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, success: false, loading: true, needsRedirect: false };
    case SUCCESS(GET_USER):
      return {
        ...state,
        user: _.defaultsDeep(action.payload, INIT_STATE_USER.user),
        loading: false,
        needsRedirect: false,
      };
    case FAILED(GET_USER):
      return { ...state, loading: false, needsRedirect: true };
    case MANAGE_USER:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_USER):
      return {
        ...state,
        user: _.defaultsDeep(action.payload, INIT_STATE_USER.user),
        success: true,
        errors: null,
        isSubmitting: false,
      };
    case FAILED(MANAGE_USER):
      return { ...state, errors: action.payload, isSubmitting: false };
    case RESET_USER:
    case RESET:
      return INIT_STATE_USER;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  User,
});

// selectors
const getUserUserFromState = state => state.Acl.Users.User.user;
export const getUserSelector = createSelector([getUserUserFromState], user => {
  return {
    ...user.data,
    person: { ...user.data.person.data, profilePicture: '', contacts: user.data.person.data.contacts.data },
    password: '',
    verifyPassword: '',
    roles: user.data.roles.data,
  };
});

export function isLoggedUser(state) {
  const { user: aclUser } = state.Acl.Users.User;
  const { user: authUser } = state.Auth.Auth;

  return aclUser.id === authUser.id;
}
