import { all, call, fork, put, cancelled, take, cancel, takeLatest, select } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  GET_ADVERTISMENT,
  MANAGE_ADVERTISMENT,
  RESET_ADVERTISMENT,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import { getMethod, updateMethod, resolveErrorPromise, createMethod } from 'helpers';
import {
  manageAdvertismentSuccess,
  manageAdvertismentFailed,
  getAdvertismentSuccess,
  getAdvertismentFailed,
} from './actions';
import { getAdvertisingLogoFromStateSelector } from './reducers';



function* advertismentGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/advertising`,
      abortController.signal
    );
    yield put(getAdvertismentSuccess(data.data));
  } catch (error) {
    yield put(getAdvertismentFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* advertismentManage(action) {
  const old = yield select(getAdvertisingLogoFromStateSelector);
  try {
    if (action.payload.id === '') {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/advertising`,
        JSON.stringify(action.payload)
      );
    } else {
      if (old.logo !== action.payload.logo) {
        action.payload.oldLogo = old.logo;
      }
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/advertising`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageAdvertismentSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageAdvertismentFailed(message));
    } else {
      yield put(manageAdvertismentFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchAdvertismentGet() {
  yield takeLatest(GET_ADVERTISMENT, advertismentGet);
}

export function* watchAdvertismentManage() {
  yield takeLatest(MANAGE_ADVERTISMENT, advertismentManage);
}

function* advertismentSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchAdvertismentGet),
      fork(watchAdvertismentManage),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default advertismentSaga;
