import { all, call, cancel, fork, put, take, takeLeading } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, deleteMethod, resolveErrorPromise } from 'helpers';

import {
  FAILED_MESSAGE,
  GET_ACTIVE_SESSION_LIST,
  REVOKE_ACTIVE_SESSION,
  REVOKE_ALL_ACTIVE_SESSIONS,
  RESET,
} from 'constants/actionTypes';

import {
  getActiveSessionListSuccess,
  getActiveSessionListFailed,
  revokeActiveSessionSuccess,
  revokeActiveSessionFailed,
  revokeAllActiveSessionsSuccess,
  revokeAllActiveSessionsFailed,
} from './actions';

function* activeSessionsGetList(action) {
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/active-session/${action.payload}`
    );
    yield put(getActiveSessionListSuccess(response));
  } catch (error) {
    yield put(getActiveSessionListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* activeSessionRevoke(action) {
  try {
    const response = yield call(
      deleteMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/revoke-session/${action.payload}`
    );

    yield put(revokeActiveSessionSuccess(response));
  } catch (error) {
    yield put(revokeActiveSessionFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* activeSessionsRevokeAll(action) {
  try {
    const response = yield call(
      deleteMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/active-session/${action.payload.userId}?refreshToken=${action.payload.refreshToken}`
    );
    yield put(revokeAllActiveSessionsSuccess(response));
  } catch (error) {
    yield put(revokeAllActiveSessionsFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetActiveSessions() {
  yield takeLeading(GET_ACTIVE_SESSION_LIST, activeSessionsGetList);
}

export function* watchRevokeActiveSession() {
  yield takeLeading(REVOKE_ACTIVE_SESSION, activeSessionRevoke);
}

export function* watchRevokeAllActiveSessions() {
  yield takeLeading(REVOKE_ALL_ACTIVE_SESSIONS, activeSessionsRevokeAll);
}

function* activeSessionsSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetActiveSessions),
      fork(watchRevokeActiveSession),
      fork(watchRevokeAllActiveSessions),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default activeSessionsSaga;
