import { combineReducers } from 'redux';
import {
  GET_CREDIT_PACKAGE_LIST,
  DELETE_CREDIT_PACKAGE,
  GET_CREDIT_PACKAGE,
  MANAGE_CREDIT_PACKAGE,
  RESET_CREDIT_PACKAGE,
  RESET,
} from 'constants/actionTypes';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  data: { data: [] },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const CreditPackageList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CREDIT_PACKAGE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_CREDIT_PACKAGE_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_CREDIT_PACKAGE_LIST):
      return { ...state, loading: false };
    case DELETE_CREDIT_PACKAGE:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_CREDIT_PACKAGE):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_CREDIT_PACKAGE):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_CREDIT_PACKAGE):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESETED(GET_CREDIT_PACKAGE_LIST):
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_CREDIT_PACKAGE = {
  creditPackage: {
    data: {
      id: '',
      name: '',
      description: '',
      quantity: '',
      discount: '',
      basePrice: '',
      isCustom: false,
      agency: '',
    },
  },
  error: false,
  success: false,
  errors: null,
  isSubmitting: false,
};

const CreditPackage = (state = INIT_STATE_CREDIT_PACKAGE, action) => {
  switch (action.type) {
    case GET_CREDIT_PACKAGE:
      return { ...state, loading: true };
    case SUCCESS(GET_CREDIT_PACKAGE):
      return {
        ...state,
        creditPackage: _.defaultsDeep(action.payload, INIT_STATE_CREDIT_PACKAGE.creditPackage),
        loading: false,
        error: false,
      };
    case FAILED(GET_CREDIT_PACKAGE):
      return { ...state, loading: false, error: true };
    case MANAGE_CREDIT_PACKAGE:
      return { ...state, isSubmitting: true, errors: null };
    case SUCCESS(MANAGE_CREDIT_PACKAGE):
      return {
        ...state,
        creditPackage: _.defaultsDeep(action.payload, INIT_STATE_CREDIT_PACKAGE.creditPackage),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_CREDIT_PACKAGE):
      return { ...state, isSubmitting: false, success: false, errors: action.payload };
    case RESET_CREDIT_PACKAGE:
    case RESET:
      return INIT_STATE_CREDIT_PACKAGE;
    default:
      return state;
  }
};

export default combineReducers({
  CreditPackage,
  CreditPackageList,
});

// selectors
const getCreatePackageCreatePackageFromState = state => state.CreditPackage.CreditPackage.creditPackage;
export const getCreditPackageSelector = createSelector([getCreatePackageCreatePackageFromState], creditPackage => {
  return {
    ...creditPackage.data,
  };
});
