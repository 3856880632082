import { combineReducers } from 'redux';
import {
  DELETE_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_LIST,
  IMPORT_TERMS_AND_CONDITIONS_CSV,
  REACTIVATE_TERMS_AND_CONDITIONS,
  RESET,
  RESET_TERMS_AND_CONDITIONS_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  data: { data: [] },
  loading: false,
  importedCSV: false,
  errors: null,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITIONS_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_TERMS_AND_CONDITIONS_LIST):
      return { ...state, data: action.payload, error: false, loading: false, importedCSV: false };
    case FAILED(GET_TERMS_AND_CONDITIONS_LIST):
      return { ...state, error: action.payload, loading: false, importedCSV: false };
    case DELETE_TERMS_AND_CONDITIONS:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_TERMS_AND_CONDITIONS):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_TERMS_AND_CONDITIONS):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_TERMS_AND_CONDITIONS):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_TERMS_AND_CONDITIONS:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_TERMS_AND_CONDITIONS):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_TERMS_AND_CONDITIONS):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_TERMS_AND_CONDITIONS):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case IMPORT_TERMS_AND_CONDITIONS_CSV:
      return { ...state, loading: true };
    case SUCCESS(IMPORT_TERMS_AND_CONDITIONS_CSV):
      return { ...state, importedCSV: true, error: false, loading: false, errors: null };
    case FAILED(IMPORT_TERMS_AND_CONDITIONS_CSV):
      return { ...state, error: action.payload, loading: false, errors: action.payload };
    case RESET:
    case RESET_TERMS_AND_CONDITIONS_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  List,
});

// selectors
