import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, createMethod, patchMethod, resolveErrorPromise } from 'helpers';
import {
  GET_BAA_SIGNATURE_LIST,
  DELETE_BAA_SIGNATURE,
  REACTIVATE_BAA_SIGNATURE,
  GET_BAA_SIGNATURE,
  MANAGE_BAA_SIGNATURE,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getBaaSignatureListSuccess,
  getBaaSignatureListFailed,
  getBaaSignatureSuccess,
  getBaaSignatureFailed,
  manageBaaSignatureSuccess,
  manageBaaSignatureFailed,
  deleteBaaSignatureSuccess,
  deleteBaaSignatureFailed,
  reactivateBaaSignatureSuccess,
  reactivateBaaSignatureFailed,
} from 'redux/actions';

function* baaSignatureGetList() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature?type=Owner`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getBaaSignatureListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getBaaSignatureListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* baaSignatureGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature/${action.payload}`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getBaaSignatureSuccess(response));
  } catch (error) {
    yield put(getBaaSignatureFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* baaSignatureDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteBaaSignatureSuccess());
  } catch (error) {
    yield put(deleteBaaSignatureFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* baaSignatureReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(reactivateBaaSignatureSuccess());
  } catch (error) {
    yield put(reactivateBaaSignatureFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* baaSignatureManage(action) {
  try {
    if (action.payload.id.length > 0) {
      yield call(
        patchMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature/${action.payload.id}`,
        JSON.stringify({ authorizedSignature: action.payload.authorizedSignature, printName: action.payload.printName })
      );
    } else {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/signature`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageBaaSignatureSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageBaaSignatureFailed(message));
    } else {
      yield put(manageBaaSignatureFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetBaaSignatureList() {
  yield takeLatest(GET_BAA_SIGNATURE_LIST, baaSignatureGetList);
}

export function* watchGetBaaSignature() {
  yield takeLatest(GET_BAA_SIGNATURE, baaSignatureGet);
}

export function* watchDeleteBaaSignature() {
  yield takeLeading(DELETE_BAA_SIGNATURE, baaSignatureDelete);
}

export function* watchReactivateBaaSignature() {
  yield takeLeading(REACTIVATE_BAA_SIGNATURE, baaSignatureReactivate);
}

export function* watchManageBaaSignature() {
  yield takeLeading(MANAGE_BAA_SIGNATURE, baaSignatureManage);
}

function* baaSignatureSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetBaaSignatureList),
      fork(watchGetBaaSignature),
      fork(watchDeleteBaaSignature),
      fork(watchReactivateBaaSignature),
      fork(watchManageBaaSignature),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default baaSignatureSaga;
