import _ from 'lodash';

export const routeHasAccessRights = (apiRoute, roles, availableRoutes, authRoles) => {
  let hasRights = true;
  if (apiRoute && availableRoutes.length && availableRoutes.filter(availableRoute => availableRoute === apiRoute || availableRoute === 'unlimited').length === 0) {
    hasRights = false;
  }
  if (roles) {
    const intersection = _.intersection(authRoles, roles);
    if (intersection.length === 0) {
      hasRights = false;
    }
  }

  return hasRights;
}