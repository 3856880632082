import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import {
  GET_LINE_OF_COVERAGE_LIST,
  DELETE_LINE_OF_COVERAGE,
  REACTIVATE_LINE_OF_COVERAGE,
  GET_LINE_OF_COVERAGE,
  MANAGE_LINE_OF_COVERAGE,
  RESET_LINE_OF_COVERAGE,
  RESET,
  RESET_LINE_OF_COVERAGE_LIST,
} from 'constants/actionTypes';

const INIT_STATE = {
  data: {
    data: [],
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
  reactivatedSuccess: false,
  reactivatedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LINE_OF_COVERAGE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_LINE_OF_COVERAGE_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_LINE_OF_COVERAGE_LIST):
      return { ...state, loading: false };
    case DELETE_LINE_OF_COVERAGE:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_LINE_OF_COVERAGE):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_LINE_OF_COVERAGE):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_LINE_OF_COVERAGE):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_LINE_OF_COVERAGE:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_LINE_OF_COVERAGE):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_LINE_OF_COVERAGE):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_LINE_OF_COVERAGE):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case RESET:
    case RESET_LINE_OF_COVERAGE_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_LINE_OF_COVERAGE = {
  lineOfCoverage: {
    data: {
      id: '',
      name: '',
      displayName: '',
      spanishDisplayName: '',
      displayOrder: '',
      isDeleted: false,
      benefits: {
        data: [],
      },
      lineOfCoverageFilters: {
        data: [],
      },
    },
  },

  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
};

const LineOfCoverage = (state = INIT_STATE_LINE_OF_COVERAGE, action) => {
  switch (action.type) {
    case GET_LINE_OF_COVERAGE:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_LINE_OF_COVERAGE):
      return {
        ...state,
        lineOfCoverage: _.defaultsDeep(action.payload, INIT_STATE_LINE_OF_COVERAGE.lineOfCoverage),
        loading: false,
        error: false,
      };
    case FAILED(GET_LINE_OF_COVERAGE):
      return { ...state, loading: false, error: true };
    case MANAGE_LINE_OF_COVERAGE:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_LINE_OF_COVERAGE):
      return {
        ...state,
        lineOfCoverage: _.defaultsDeep(action.payload, INIT_STATE_LINE_OF_COVERAGE.lineOfCoverage),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_LINE_OF_COVERAGE):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_LINE_OF_COVERAGE:
    case RESET:
      return INIT_STATE_LINE_OF_COVERAGE;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  LineOfCoverage,
});

const getLineOfCoverageLineOfCoverageFromState = state => state.Broker.LinesOfCoverage.LineOfCoverage.lineOfCoverage;
export const getLineOfCoverageSelector = createSelector([getLineOfCoverageLineOfCoverageFromState], lineOfCoverage => {
  return {
    ...lineOfCoverage.data,
    benefits: [
      ...lineOfCoverage.data.benefits.data.map(benefit => ({
        ...benefit,
        benefitSection: benefit.benefitSection.data.id,
      }))
    ],
    lineOfCoverageFilters: lineOfCoverage.data.lineOfCoverageFilters.data,
  };
});

export const getLineOfCoverageListSelector = createSelector(state => state.Broker.LinesOfCoverage.List.data, lineOfCoverage => lineOfCoverage.data);
