import { GET_QUOTE_PIN_LIST } from 'constants/quotePin';
import { SUCCESS, FAILED, RESETED }    from 'helpers';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  success: false,
  errors: false,
}

const QuotePinList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_QUOTE_PIN_LIST:
      return {...state, loading: true, success: false, errors: false};
    case SUCCESS(GET_QUOTE_PIN_LIST):
      return {...state, loading: false, success: true, errors: false, data: action.payload};
    case FAILED(GET_QUOTE_PIN_LIST):
      return {...state, loading: false, success: false, errors: true};
    case RESETED(GET_QUOTE_PIN_LIST):
      return INIT_STATE_LIST;
    default:
      return state;
  }
}

const quotePinListState = (state) => state.Pin.QuotePinList;

export const getQuotePinListSelector = createSelector(
  [quotePinListState],
  quotePin => quotePin.data.data,
);

export const getQuotePinListMetaSelector = createSelector(
  [quotePinListState],
  quotePin => quotePin.data.meta,
);

export const getQuotePinListLoadingSelector = createSelector(
  [quotePinListState],
  quotePin => quotePin.loading,
);

export default combineReducers({
  QuotePinList,
});