import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest, race } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  GET_API_LIST,
  GET_API_FORM_CONFIG,
  GET_API,
  MANAGE_API,
  FAILED_MESSAGE,
  RESET,
  DELETE_API,
  REACTIVATE_API,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import { getMethod, updateMethod, patchMethod, resolveErrorPromise } from 'helpers';
import {
  getApiListSuccess,
  getApiListFailed,
  getApiFormConfigSuccess,
  getApiFormConfigFailed,
  manageApiSuccess,
  manageApiFailed,
  getApiSuccess,
  getApiFailed,
  deleteApiSuccess,
  deleteApiFailed,
  reactivateApiSuccess,
  reactivateApiFailed,
} from './actions';

function* apiGetList() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getApiListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getApiListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* apiGetFormConfig() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api-form-config`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getApiFormConfigSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getApiFormConfigFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* apiGet(action) {
  const abortController = new AbortController();
  try {
    if (action.payload.callback) {
      yield fork(action.payload.callback);
      yield race({
        success: take(SUCCESS(GET_API_FORM_CONFIG)), //
        failed: take(FAILED(GET_API_FORM_CONFIG)),
      }); // wait for succes response before continuing...
    }

    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api/${action.payload.apiId}?include=apiLineOfCoverages.lineOfCoverage,apiLineOfCoverages.apiLineOfCoverageStates`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getApiSuccess(response));
  } catch (error) {
    yield put(getApiFailed());

    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* apiManage(action) {
  try {
    yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api/${action.payload.id}`,
      JSON.stringify(action.payload)
    );

    yield put(manageApiSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageApiFailed(message));
    } else {
      yield put(manageApiFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* apiDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteApiSuccess());
  } catch (error) {
    yield put(deleteApiFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* apiReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/api/${action.payload.id}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(reactivateApiSuccess());
  } catch (error) {
    yield put(reactivateApiFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetApiList() {
  yield takeLatest(GET_API_LIST, apiGetList);
}

export function* watchDeleteApi() {
  yield takeLeading(DELETE_API, apiDelete);
}

export function* watchReactivateApi() {
  yield takeLeading(REACTIVATE_API, apiReactivate);
}

export function* watchGetApiFormConfig() {
  yield takeLatest(GET_API_FORM_CONFIG, apiGetFormConfig);
}

export function* watchGetApi() {
  yield takeLatest(GET_API, apiGet);
}

export function* watchManageApi() {
  yield takeLeading(MANAGE_API, apiManage);
}

function* apiSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetApiList),
      fork(watchDeleteApi),
      fork(watchReactivateApi),
      fork(watchGetApiFormConfig),
      fork(watchGetApi),
      fork(watchManageApi),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default apiSaga;
