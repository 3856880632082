import { GET_POLICY_LIST, VIEW_POLICY, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const PolicyList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_POLICY_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_POLICY_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_POLICY_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_VIEW = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: {
      createdAt: '',
      createdBy: {
        data: {
          person: {
            data: {
              fullName: '',
            },
          },
        },
      },
      startDate: '',
      endDate: '',
      leadSource: '',
      csrAmount: '',
      policyType: '',
      policyNumber: '',
      issuerPolicyNumber: '',
      status: '',
      hasSvi: false,
      annualHashouldIncome: '',
      submissionType: 'INSXCloud',
      policyMembers: {
        data: [],
      },
      plan: {
        data: {
          planName: '',
          issuerName: '',
          premium: '',
          aptc: '',
          premiumWithoutAptc: '',
          lineOfCoverage: '',
          isOnExchange: true,
          ratingArea: '',
          planNumber: '',
          csr: '',
          benefits: [],
        },
      },
      producer: {
        data: {
          id: '',
          agency: {
            data: {
              id: '',
              companyName: '',
              parent: null,
              address: '',
              isDeleted: 0,
              isApproved: 0,
              allowChildToEditRestrictions: 1,
              perCredit: 1,
              hasChildren: false,
              createdAt: '',
              remainingCredits: 0,
              subdomain: '',
              logo: '',
              theme: '',
            },
          },
          person: {
            data: {
              fullName: '',
            },
          },
          isDeleted: 0,
          isDefaultProducer: 0,
          hasChildren: false,
          isFfmVerified: false,
          forceDeRedirect: false,
          createdBy: null,
          updatedBy: null,
          createdAt: '',
          updatedAt: '',
          status: '',
          niprCheckRequestTimestamp: '',
          niprResponse: {
            data: [],
          },
          parent: null,
          signedBaa: false,
          agentNumbers: {
            data: [],
          },
        },
      },
      salesExternalDetails: {
        id: '',
        lead: '',
        source: '',
        affiliateCode: '',
        product: '',
      },
      answers: {
        data: [],
      },
    },
  },
};

const PolicyView = (state = INIT_STATE_VIEW, action) => {
  switch (action.type) {
    case VIEW_POLICY:
      return { ...state, loading: true };
    case SUCCESS(VIEW_POLICY):
      return { ...state, data: action.payload, loading: false };
    case FAILED(VIEW_POLICY):
      return { ...state, loading: false, error: true };
    case RESETED(VIEW_POLICY):
    case RESET:
      return INIT_STATE_VIEW;
    default:
      return state;
  }
};

export default combineReducers({
  PolicyList,
  PolicyView,
});

/**
 * LIST SELECTORS
 */
const getPolicyListFromState = state => state.Policy.PolicyList.data;
export const getPolicyListSelector = createSelector([getPolicyListFromState], policyList => policyList);

export const getPolicyListSelectorLoadingSelector = createSelector(
  [state => state.Policy.PolicyList.loading],
  loading => loading
);

export const getPolicyListSelectorMetaSelector = createSelector(
  [state => state.Policy.PolicyList.data.meta],
  meta => meta
);

/**
 * VIEW SELECTORS
 */

const getPolicyFromState = state => state.Policy.PolicyView.data;
export const getPolicySelector = createSelector([getPolicyFromState], policy => policy);

export const getPolicyLoadingSelector = createSelector(
  state => state.Policy.PolicyView.loading,
  loading => loading
);

export const getPolicyPlanBenefitsSelector = createSelector([getPolicyFromState], policy => {
  return policy.data.plan.data?.benefits?.filter(({ showInPlanListing }) => showInPlanListing) || [];
});

export const getPolicyPlanDocumentsSelector = createSelector([getPolicyFromState], policy => {
  return policy.data.plan.data?.documents || [];
});
