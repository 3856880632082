import { fork, put, takeLeading, call, take, cancel, all } from 'redux-saga/effects';
import { createMethod, resolveErrorPromise } from 'helpers';

import { RESET, FAILED_MESSAGE, CREATE_ENCRYPTION } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import { createEncryptionSuccess, createEncryptionFailed } from './actions';

function* encryptionCreate(action) {
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/encryption`,
      JSON.stringify(action.payload)
    );

    yield put(createEncryptionSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(createEncryptionFailed());
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchCreateEncryption() {
  yield takeLeading(CREATE_ENCRYPTION, encryptionCreate);
}

function* encryptionSaga() {
  while (true) {
    const tasks = yield all([fork(watchCreateEncryption)]);

    yield all([take(RESET)]);

    yield cancel(tasks);
  }
}

export default encryptionSaga;
