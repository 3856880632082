import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import {
  GET_DASHBOARD_ALERT_LIST,
  DELETE_DASHBOARD_ALERT,
  GET_DASHBOARD_ALERT,
  MANAGE_DASHBOARD_ALERT,
  RESET_DASHBOARD_ALERT,
  RESET,
  RESET_DASHBOARD_ALERT_LIST,
} from 'constants/actionTypes';

const INIT_STATE = {
  data: {
    data: [],
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ALERT_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_DASHBOARD_ALERT_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_DASHBOARD_ALERT_LIST):
      return { ...state, loading: false };
    case DELETE_DASHBOARD_ALERT:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_DASHBOARD_ALERT):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_DASHBOARD_ALERT):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_DASHBOARD_ALERT):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_DASHBOARD_ALERT_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_DASHBOARD_ALERT = {
  dashboardAlert: {
    data: {
      id: '',
      title: '',
      message: '',
      sortKey: 1,
      expireAt: '',
    },
  },
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
};

const DashboardAlert = (state = INIT_STATE_DASHBOARD_ALERT, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ALERT:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_DASHBOARD_ALERT):
      return {
        ...state,
        dashboardAlert: _.defaultsDeep(action.payload, INIT_STATE_DASHBOARD_ALERT.dashboardAlert),
        loading: false,
        error: false,
      };
    case FAILED(GET_DASHBOARD_ALERT):
      return { ...state, loading: false, error: true };
    case MANAGE_DASHBOARD_ALERT:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_DASHBOARD_ALERT):
      return {
        ...state,
        dashboardAlert: _.defaultsDeep(action.payload, INIT_STATE_DASHBOARD_ALERT.dashboardAlert),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_DASHBOARD_ALERT):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_DASHBOARD_ALERT:
    case RESET:
      return INIT_STATE_DASHBOARD_ALERT;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  DashboardAlert,
});

const getDashboardAlertDashboardAlertFromState = (state) => state.Auth.DashboardAlert.DashboardAlert.dashboardAlert;
export const getDashboardAlertSelector = createSelector([getDashboardAlertDashboardAlertFromState], (dashboardAlert) => {
  return {
    ...dashboardAlert.data,
  };
});
