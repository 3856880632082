import { all, call, fork, put, takeLeading, takeLatest, cancelled, take, cancel, select } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { createMethod, getMethod, updateMethod, deleteMethod, resolveErrorPromise } from 'helpers';
import {
  GET_ROLE_LIST,
  DELETE_ROLE,
  MANAGE_ROLE,
  GET_ROLE,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import { getRouteList } from 'redux/acl/route/actions';
import { getAuthUserRolesNameSelector } from 'redux/auth/auth/reducers';
import { infoUser } from 'redux/auth/auth/actions';
import {
  getRoleListSuccess,
  getRoleListFailed,
  deleteRoleSuccess,
  deleteRoleFailed,
  getRoleSuccess,
  getRoleFailed,
  manageRoleSuccess,
  manageRoleFailed,
  getRoleList,
} from './actions';

function* roleGetList() {
  try {
    const response = yield call(getMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/role?include=routes`);

    yield put(getRoleListSuccess(response)); // save to redux
  } catch (error) {
    yield put(getRoleListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* roleDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/role/${action.payload.roleId}`);

    yield put(deleteRoleSuccess());
    yield put(getRoleList());
  } catch (error) {
    yield put(deleteRoleFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* roleGet(action) {
  const abortController = new AbortController();
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/role/${action.payload.roleId}?include=routes`,
      abortController.signal
    );

    yield put(getRoleSuccess(response)); // save to redux
    yield put(getRouteList(response.data.isAdmin ? 1 : 2));
  } catch (error) {
    yield put(getRoleFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* roleManage(action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/role/${action.payload.id}?include=routes`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/role?include=routes`,
        JSON.stringify(action.payload)
      );
    }

    // if we manage one of our role we must re-get the available routes
    const authUserRoles = yield select(getAuthUserRolesNameSelector);
    if (authUserRoles.includes(response.data.name)) {
      yield put(infoUser());
    }

    yield put(manageRoleSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageRoleFailed(message));
    } else {
      yield put(manageRoleFailed());

      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetRoleList() {
  yield takeLeading(GET_ROLE_LIST, roleGetList);
}

export function* watchDeleteRole() {
  yield takeLeading(DELETE_ROLE, roleDelete);
}

export function* watchGetRole() {
  yield takeLatest(GET_ROLE, roleGet);
}

export function* watchManageRole() {
  yield takeLeading(MANAGE_ROLE, roleManage);
}

function* roleSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetRoleList),
      fork(watchDeleteRole),
      fork(watchManageRole),
      fork(watchGetRole),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default roleSaga;
