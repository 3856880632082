import { CHANGE_PASSWORD, CHECK_TOKEN, RESET_CHANGE_PASSWORD } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const changePassword = email => ({
  type: CHANGE_PASSWORD,
  payload: email,
});

export const changePasswordSuccess = history => ({
  type: SUCCESS(CHANGE_PASSWORD),
  payload: history,
});

export const changePasswordFailed = error => ({
  type: FAILED(CHANGE_PASSWORD),
  payload: error,
});

export const changePasswordReset = () => ({
  type: RESET_CHANGE_PASSWORD,
});

export const checkToken = token => ({
  type: CHECK_TOKEN,
  payload: { token },
});

export const checkTokenSuccess = () => ({
  type: SUCCESS(CHECK_TOKEN),
});

export const checkTokenFailed = error => ({
  type: FAILED(CHECK_TOKEN),
  payload: error,
});
