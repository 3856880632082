import { call, fork, put, takeLatest, cancelled, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getLogListFailed, getLogListSuccess, exportServerSideSuccess, exportServerSideFailed } from 'redux/actions';
import { getMethod, resolveErrorPromise } from 'helpers';
import { FAILED_MESSAGE, GET_LOG_LIST, RESET, EXPORT_LOGS } from 'constants/actionTypes';

function* logGetList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/log?include=createdBy.person&envUrl=${encodeURIComponent(process.env.REACT_APP_PUBLIC_API_ENDPOINT)}`;

    let params = {};

    // read pagination
    Object.entries(action.data.pagination).forEach(value => {
      params = { ...params, [value[0]]: value[1] };
    });

    // read active filters
    Object.entries(action.data.filters).forEach(value => {
      const filterName = `filter_${value[0]}`;
      params = { ...params, [filterName]: value[1] };
    });

    // read active ordering
    Object.entries(action.data.order).forEach(value => {
      const filterName = `order_${value[0]}`;
      params = { ...params, [filterName]: value[1] };
    });

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getLogListSuccess(response));
  } catch (error) {
    yield put(getLogListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* logsExport(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/log-export`;

    let params = {};

    // read active filters
    Object.entries(action.payload.filters).forEach(value => {
      const filterName = `filter_${value[0]}`;
      params = { ...params, [filterName]: value[1] };
    });

    // read active ordering
    Object.entries(action.payload.order).forEach(value => {
      const filterName = `order_${value[0]}`;
      params = { ...params, [filterName]: value[1] };
    });

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `?${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    // DOWNLOAD FILE
    window.location.href = response.link;
    yield put(exportServerSideSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(exportServerSideFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetLogList() {
  yield takeLatest(GET_LOG_LIST, logGetList);
}

export function* watchExportLogs() {
  yield takeLatest(EXPORT_LOGS, logsExport);
}

function* logsSaga() {
  while (true) {
    const tasks = [yield fork(watchGetLogList), yield fork(watchExportLogs)];

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default logsSaga;
