import {
  GET_APPLICATION_LIST,
  VIEW_APPLICATION,
  RESET,
  DELETE_APPLICATION,
  REACTIVATE_APPLICATION,
  GET_OFF_EX_APPLICATION_LIST,
  VIEW_OFF_EX_APPLICATION,
  GET_QUOTING_ACTIVITY_LIST,
  GET_PRIVATE_EX_LIST,
  GET_AGENT_OF_RECORD_LIST
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  success: false,
  error: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
  isReactivating: false,
  reactivatedSuccess: false,
  reactivatedFailed: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const ApplicationList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_APPLICATION_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_APPLICATION_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_APPLICATION_LIST):
      return { ...state, loading: false };
    case DELETE_APPLICATION:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_APPLICATION):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_APPLICATION):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_APPLICATION):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case REACTIVATE_APPLICATION:
      return { ...state, isReactivating: true };
    case SUCCESS(REACTIVATE_APPLICATION):
      return { ...state, isReactivating: false, reactivatedSuccess: true };
    case FAILED(REACTIVATE_APPLICATION):
      return { ...state, isReactivating: false, reactivatedFailed: true };
    case RESETED(REACTIVATE_APPLICATION):
      return { ...state, isReactivating: false, reactivatedSuccess: false, reactivatedFailed: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const OffExApplicationList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_OFF_EX_APPLICATION_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_OFF_EX_APPLICATION_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_OFF_EX_APPLICATION_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const PrivateExList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_PRIVATE_EX_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_PRIVATE_EX_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_PRIVATE_EX_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_VIEW = {
  loading: false,
  success: false,
  error: false,
  data: {
    id: '',
    appDetails: {
      coverageYear: '',
      // attestations
      attestations: {
        application: {
          applicationAssistors: [
            {
              applicationAssistorType: '',
              creationDateTime: '',
            }
          ],
          requestingFinancialAssistanceIndicator: false,
          contactMemberIdentifier: 'default',
          contactMethod: [
            // E_TEXT, EMAIL, MAIL
          ],
          contactInformation: {
            email: '',
            primaryPhoneNumber: {
              number: '',
              ext: '',
              type: '', // HOME
            },
            secondaryPhoneNumber: {
              number: '',
              ext: '',
              type: '',
            },
          },
          spokenLanguageType: '',
          writtenLanguageType: '',
          coverageState: '',
        },
        household: {
          familyRelationships: [],
          taxRelationships: [],
        },
        members: {
          default: {
            requestingCoverageIndicator: null,
            demographic: {
              ssn: '',
              birthDate: '',
              name: {
                firstName: '',
                middleName: null,
                lastName: '',
                suffix: null,
              },
              sex: '',
              maritalStatus: '',
              noHomeAddressIndicator: false,
              liveOutsideStateTemporarilyIndicator: false,
              hispanicOriginIndicator: '',
              americanIndianAlaskanNativeIndicator: false,
              ethnicity: ['NOT_HISPANIC'],
              otherEthnicityText: null,
              race: [],
              otherRaceText: null,
              mailingAddress: {
                streetName1: '',
                streetName2: '',
                cityName: '',
                stateCode: '',
                zipCode: '',
                plus4Code: '',
                countyName: '',
                countyFipsCode: '',
              },
              homeAddress: {
                streetName1: '',
                streetName2: '',
                cityName: '',
                stateCode: '',
                zipCode: '',
                plus4Code: '',
                countyName: '',
                countyFipsCode: '',
              },
            },
            income: {
              annualTaxIncome: {
                incomeAmount: 0,
                incomeLessExplainedIndicator: '',
                unknownIncomeIndicator: '',
                // variableIncomeIndicator: false,
                receivedUnemploymentCompensationIndicator: '',
                taxHouseholdIncomeDiscrepancyDescriptionText: '',
                variableIncomeDescriptionText: '',
                taxHouseholdIncomeDifferenceReasonType: [
                  // 'SELF_EMPLOYMENT_INCOME_FLUCTUATION', 'HOUSEHOLD_MEMBER_CHANGE', 'WORK_LOCATION_CHANGE', 'OTHER', 'JOB_CHANGE',
                ],
              },
              currentIncome: {
                // currentIncome1: {
                //   sequenceNumber: 1,
                //   incomeAmount: 0,
                //   incomeSourceType: '',
                //   incomeFrequencyType: '',
                //   estimatedForAptcIndicator: false,
                //   jobIncome: {
                //     averageWeeklyWorkDays: 0,
                //     averageWeeklyWorkHours: 0,
                //     incomeDifferenceReason: '',
                //     employerName: '',
                //     otherIncomeDifferenceReason: '',
                //   },
                //   otherDeductionDescription: '',
                //   selfEmploymentIncomeDescription: '',
                //   otherIncomeDescription: '',
                //   incomeFederallyRestrictedIndicator: false,
                // },
              },
            },
            family: {
              babyDueQuantity: 0,
              taxDependentIndicator: false,
              taxFilerIndicator: null,
              taxReturnFilingStatusType: null,
              claimsDependentIndicator: null,
              claimingTaxFilerNotOnApplicationIndicator: false,
              livesWithCustodialParentNotOnApplicationIndicator: false,
              fosterCareIndicator: null,
              fosterCareEndAge: 0,
              medicaidDuringFosterCareIndicator: false,
              fosterCareState: '',
              medicaidFamilyNotProvidedIndicator: false,
              taxFilerNotProvidedIndicator: false,
              parentCaretakerIndicator: false,
              pregnancyIndicator: false,
              fullTimeStatusIndicator: false,
              studentsParentLivingInExchangeStateIndicator: false,
              parentLivesInStudentStateIndicator: null,
              absentParentIndicator: false,
              resideWithBothParentIndicator: false,
              liveWithParentOrSiblingIndicator: true,
              attestedHeadOfHouseholdIndicator: false,
              liveApartFromSpouseIndicator: false,
            },
            lawfulPresence: {
              lawfulPresenceGrantDate: '',
              lawfulPresenceStatusIndicator: null,
              livedInUs5yearIndicator: null,
              noAlienNumberIndicator: null,
              citizenshipIndicator: null,
              naturalizedCitizenIndicator: null,
              lawfulPresenceDocumentation: {},
            },
            insuranceCoverage: {
              enrolledCoverages: [
                // {
                //   insuranceMarketType: '',
                // },
              ],
              offeredEmployeeCoverage: '',
              enrolledInIchraIndicator: false,
              offeredIchraIndicator: false,
            },
            medicaid: {
              medicaidDeniedDate: '',
              // medicaidDeniedIndicator: false,
              enrolledInHealthCoverageIndicator: false,
            },
            chip: {
              coverageEndedIndicator: '',
              stateHealthBenefitIndicator: true,
              coverageEndedReasonType: '',
            },
            nonMagi: {
              // blindOrDisabledIndicator: false,
              // longTermCareIndicator: false,
            },
            other: {
              incarcerationType: '',
              // changeInCircumstance: {
              //   GAINING_LAWFUL_PRESENCE: {
              //     changeDate: '',
              //     changeRequestDateTime: '',
              //     changeType: '',
              //   },
              //   LOSS_OF_MEC: {
              //     changeDate: '',
              //     changeRequestDateTime: '',
              //     changeType: '',
              //   },
              // },
              // veteranIndicator: false,
              // veteranSelfIndicator: false,
              // incarcerationType: 'NOT_INCARCERATED',
              // reconcilePtcIndicatorType: '',
              // appliedDuringOeOrLifeChangeIndicator: false,
            },
          },
        },
      },
      // computed
      computed: {
        application: {},
        taxHouseholds: {},
        members: {},
      },
    },
  },
};

const ApplicationView = (state = INIT_STATE_VIEW, action) => {
  switch (action.type) {
    case VIEW_APPLICATION:
      return { ...state, loading: true };
    case SUCCESS(VIEW_APPLICATION):
      return { ...state, data: action.payload, loading: false };
    case FAILED(VIEW_APPLICATION):
      return { ...state, loading: false, error: true };
    case RESETED(VIEW_APPLICATION):
    case RESET:
      return INIT_STATE_VIEW;
    default:
      return state;
  }
};

const OffExApplicationView = (state = INIT_STATE_VIEW, action) => {
  switch (action.type) {
    case VIEW_OFF_EX_APPLICATION:
      return { ...state, loading: true };
    case SUCCESS(VIEW_OFF_EX_APPLICATION):
      return { ...state, data: action.payload, loading: false };
    case FAILED(VIEW_OFF_EX_APPLICATION):
      return { ...state, loading: false, error: true };
    case RESETED(VIEW_OFF_EX_APPLICATION):
    case RESET:
      return INIT_STATE_VIEW;
    default:
      return state;
  }
};

const QuotingActivityList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_QUOTING_ACTIVITY_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_QUOTING_ACTIVITY_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_QUOTING_ACTIVITY_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};


const INIT_STATE_AGENT_OF_RECORD_LIST = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const AgentOfRecordList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_AGENT_OF_RECORD_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_AGENT_OF_RECORD_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_AGENT_OF_RECORD_LIST):
      return { ...state, loading: false };
    case RESET:
      return GET_AGENT_OF_RECORD_LIST;
    default:
      return state;
  }
};

export default combineReducers({
  ApplicationList,
  ApplicationView,
  OffExApplicationList,
  OffExApplicationView,
  PrivateExList,
  QuotingActivityList,
  AgentOfRecordList
});

/**
 * LIST SELECTORS
 */
const getApplicationListFromState = state => state.Application.ApplicationList.data;
export const getApplicationListSelector = createSelector([getApplicationListFromState], applicationList => applicationList);

export const getApplicationListLoadingSelector = createSelector(
  [state => state.Application.ApplicationList.loading],
  loading => loading
);

export const getApplicationListMetaSelector = createSelector(
  [state => state.Application.ApplicationList.data.meta],
  meta => meta
);

const getOffExApplicationListFromState = state => state.Application.OffExApplicationList.data;
export const getOffExApplicationListSelector = createSelector([getOffExApplicationListFromState], applicationList => applicationList);

export const getOffExApplicationListLoadingSelector = createSelector(
  [state => state.Application.OffExApplicationList.loading],
  loading => loading
);

export const getOffExApplicationListMetaSelector = createSelector(
  [state => state.Application.OffExApplicationList.data.meta],
  meta => meta
);

const getPrivateExListFromState = state => state.Application.PrivateExList.data;
export const getPrivateExListSelector = createSelector([getPrivateExListFromState], applicationList => applicationList);

export const getPrivateExListLoadingSelector = createSelector(
  [state => state.Application.PrivateExList.loading],
  loading => loading
);

export const getPrivateExListMetaSelector = createSelector(
  [state => state.Application.PrivateExList.data.meta],
  meta => meta
);


const getQuotingActivityListFromState = state => state.Application.QuotingActivityList.data;
export const getQuotingActivityListSelector = createSelector([getQuotingActivityListFromState], applicationList => applicationList);

export const getQuotingActivityListLoadingSelector = createSelector(
  [state => state.Application.QuotingActivityList.loading],
  loading => loading
);

export const getQuotingActivityListMetaSelector = createSelector(
  [state => state.Application.QuotingActivityList.data.meta],
  meta => meta
);

/**
 * VIEW SELECTORS
 */

const getApplicationFromState = state => state.Application.ApplicationView.data;
export const getApplicationSelector = createSelector([getApplicationFromState], application => application);

export const getApplicationLoadingSelector = createSelector(
  state => state.Application.ApplicationView.loading,
  loading => loading
);

export const getApplicationAttestationSelector = createSelector(
    [getApplicationFromState],
  attestation => attestation.appDetails.attestations
);

export const getApplicationDemographics = createSelector(
  [state => state?.Application?.ApplicationView?.data?.quote?.demographics],
  loading => loading
);

export const getApplicationContactMemberIdentifierSelector = createSelector(
    [getApplicationFromState],
    attestation => attestation.appDetails.attestations.application.contactMemberIdentifier
);


const getOffExApplicationFromState = state => state.Application.OffExApplicationView.data;
export const getOffExApplicationSelector = createSelector([getOffExApplicationFromState], application => application);

export const getOffExApplicationLoadingSelector = createSelector(
  state => state.Application.OffExApplicationView.loading,
  loading => loading
);

const getAgentOfRecordListFromState = state => state.Application.AgentOfRecordList.data;
export const getAgentOfRecordListSelector = createSelector([getAgentOfRecordListFromState], agentOfRecordList => agentOfRecordList);

export const getAgentOfRecordListLoadingSelector = createSelector(
  [state => state.Application.AgentOfRecordList.loading],
  loading => loading
);

export const getAgentOfRecordListMetaSelector = createSelector(
  [state => state.Application.AgentOfRecordList.data.meta],
  meta => meta
);