import { combineReducers } from 'redux';
import { reducer as flash } from 'redux-flash';
import DMIList from 'redux/dmi/reducers';
import Broker from './broker/reducers';
import Acl from './acl/reducers';
import Auth from './auth/reducers';
import Eligibility from './eligibility/reducers';
import Location from './location/reducers';
import Log from './log/reducers';
import ExportServerSide from './export-server-side/reducers';
import CreditPackage from './billing/credit-package/reducers';
import Policy from './policy/reducers';
import EffectiveDates from './effective-dates/reducers';
import Activity from './activity/reducers';
import Application from './application/reducers';
import Encryption from './encryption/reducers';
import Reporting from './reporting/reducers';
import ZipCode from './zip-code/reducers';
import ConsumerConsent from './broker/consumer-consent/reducers';
import Pin from './pin/reducers';
import Quote from './quote/reducers';

export default combineReducers({
  flash,
  Acl,
  Auth,
  Broker,
  ConsumerConsent,
  Eligibility,
  Location,
  Log,
  ExportServerSide,
  CreditPackage,
  Policy,
  EffectiveDates,
  Activity,
  Application,
  Encryption,
  Reporting,
  ZipCode,
  DMIList,
  Quote,
  Pin,
});
