export const GET_LOG_LIST = 'GET_LOG_LIST';

export const RESET_LOG_LIST = 'RESET_LOG_LIST';

export const EXPORT_LOGS = 'EXPORT_LOGS';

export const GET_IDEON_LOG_LIST = 'GET_IDEON_LOG_LIST';

export const RESET_IDEON_LOG_LIST = 'RESET_IDEON_LOG_LIST';

export const EXPORT_IDEON_LOGS = 'EXPORT_IDEON_LOGS';
