import { all, call, cancel, fork, put, take, takeLeading } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  loginAction,
  getUserInfo,
  updateMethod,
  createMethod,
  removeCookies,
  resolveErrorPromise,
  getMethod
} from 'helpers';
import {
  LOGIN_USER,
  INFO_USER,
  LOGOUT_USER,
  UPDATE_USER,
  RESET,
  FAILED_MESSAGE,
  IMPERSONATE_USER
} from 'constants/actionTypes';
import _ from 'lodash';
import { twoFaSuccess } from 'redux/auth/two-fa/actions';
import dayjs from 'dayjs';
import {
  resetLoginUser,
  loginUserSuccess,
  loginUserFailed,
  infoUserSuccess,
  infoUserFailed,
  updateUserSuccess,
  updateUserFailed,
  logoutUserFailed,
  logoutUserSuccess,
  reset, impersonateUserFailed, impersonateUserSuccess,
} from './actions';

function* userInfoFromToken() {
  try {
    const tokenData = yield call(getMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/info`);

    const { id: userId } = tokenData;

    const inactivity = JSON.parse(localStorage.getItem('inactivity'));
    let difference = 0;
    if (inactivity) {
      difference = dayjs().diff(inactivity.lastActionTime, 'second');
    }

    if (difference < 900) {
      const user = yield call(getUserInfo, userId);

      yield put(infoUserSuccess(user));
    } else {
      throw new Error('Session is outdated. Please login again.');
    }
  } catch (error) {
    localStorage.removeItem('token');
    yield put(infoUserFailed('Session is outdated. Please login again.'));
    yield put(reset());
  }
}

function* login({ payload: { username, password }, history }) {
  try {
    const response = yield call(loginAction, username, password);

    if (_.has(response, 'data.token')) {
      // put token in state and redirect to verification page
      yield put(resetLoginUser());
      yield put(twoFaSuccess(response.data.token));

      yield history.push('/verification-code');
    } else {
      const dataUser = yield call(getMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/info`);

      const user = yield call(getUserInfo, dataUser.id);

      if (_.isEmpty(user)) {
        throw new Error('Invalid Credentials');
      }
      yield put(loginUserSuccess(user));
    }
  } catch (error) {
    yield put(loginUserFailed('Invalid credentials'));
  }
}

function* logout() {
  try {
    // revoke current token, call revoke token also
    const token = JSON.parse(localStorage.getItem('token'));

    const { accessToken, refreshToken } = token;

    yield fork(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/revoke`,
      new URLSearchParams({ accessToken, refreshToken }).toString(),
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    yield put(logoutUserSuccess());
    yield put(reset()); // reinitialze redux, full reinit
  } catch (err) {
    yield put(logoutUserFailed());
  }
}

function* updateUser(action) {
  try {
    const response = yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${action.payload.id}?include=roles,person`,
      JSON.stringify(action.payload)
    );

    yield put(updateUserSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(updateUserFailed(message));
    } else {
      yield put(updateUserFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* userImpersonate(action) {
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/impersonate`,
      JSON.stringify(action.payload)
    );

    yield put(impersonateUserSuccess());

    window.open(response.URL, '_blank');
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    yield put(impersonateUserFailed(message?.detail));
    yield put(message?.detail ? flashErrorMessage(message.detail.errors) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchLoginUser() {
  yield takeLeading(LOGIN_USER, login);
}

export function* watchInfoUser() {
  yield takeLeading(INFO_USER, userInfoFromToken);
}

export function* watchLogoutUser() {
  yield takeLeading(LOGOUT_USER, logout);
}

export function* watchUpdateUser() {
  yield takeLeading(UPDATE_USER, updateUser);
}

export function* watchImpersonateUser() {
  yield takeLeading(IMPERSONATE_USER, userImpersonate);
}

function* authSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchLoginUser),
      fork(watchInfoUser),
      fork(watchLogoutUser),
      fork(watchUpdateUser),
      fork(watchImpersonateUser),
    ]);

    yield take(RESET);

    yield cancel(tasks);

    removeCookies();
  }
}

export default authSaga;
