import _ from 'lodash';
import { createSelector } from 'reselect';
import { LOGIN_USER, LOGOUT_USER, INFO_USER, UPDATE_USER, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import dayjs from 'dayjs';

const INIT_STATE = {
  loading: false,
  hasInfo: false,
  success: false,
  error: null,
  isSubmitting: false,
  user: {
    data: {
      id: '',
      email: '',
      isDeleted: '',
      password: '',
      verifyPassword: '',
      createdBy: '',
      createdAt: '',
      updatedBy: '',
      updatedAt: '',
      person: {
        data: {
          id: '',
          title: '',
          suffix: '',
          lastName: '',
          firstName: '',
          middleName: '',
          gender: '',
          birthDate: '',
          maritalStatus: '',
          profilePicture: '',
        },
      },
      roles: {
        data: [],
      },
      availableRoutes: {
        data: [],
      },
    },
  },
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case SUCCESS(LOGIN_USER):
      return {
        ...state,
        user: _.defaultsDeep(action.payload, INIT_STATE.user),
        loading: false,
        hasInfo: true,
        error: null,
      };
    case FAILED(LOGIN_USER):
      return { ...state, error: action.payload, loading: false };
    case RESETED(LOGIN_USER):
      return { ...state, loading: false, success: false, error: null, hasInfo: false };
    case INFO_USER:
      return { ...state, loading: true, success: false, isSubmitting: false };
    case SUCCESS(INFO_USER):
      return {
        ...state,
        user: _.defaultsDeep(action.payload, INIT_STATE.user),
        loading: false,
        hasInfo: true,
        error: null,
      };
    case FAILED(INFO_USER):
      return { ...state, error: action.payload, loading: false, hasInfo: false };
    case UPDATE_USER:
      return { ...state, error: null, isSubmitting: true };
    case SUCCESS(UPDATE_USER):
      return {
        ...state,
        user: _.defaultsDeep(action.payload, INIT_STATE.user),
        success: true,
        error: null,
        isSubmitting: false,
      };
    case FAILED(UPDATE_USER):
      return { ...state, error: action.payload, isSubmitting: false };
    case LOGOUT_USER:
      return INIT_STATE;
    case RESET:
      return { ...INIT_STATE, error: state.error };

    default:
      return state;
  }
};

export default Auth;

// selectors
const getAuthUserUserFromState = state => state.Auth.Auth.user;
export const getAuthUserSelector = createSelector([getAuthUserUserFromState], user => {
  return {
    ...user.data,
    person: {
      ...user.data.person.data,
      birthDate: dayjs(user.data.person.data.birthDate).format('YYYY-MM-DD'),
      profilePicture: '',
    },
    password: '',
    verifyPassword: '',
    roles: user.data.roles.data,
  };
});

const getProfilePictureProfilePictureFromState = state => state.Auth.Auth.user.data.person.data.profilePicture;
export const getProfilePictureSelector = createSelector([getProfilePictureProfilePictureFromState], profilePicture => {
  return profilePicture;
});

const getUserNameFirstName = state => state.Auth.Auth.user.data.person.data.firstName;
const getUserNameLastName = state => state.Auth.Auth.user.data.person.data.lastName;
export const getUserNameSelector = createSelector(
  [getUserNameFirstName, getUserNameLastName],
  (firstName, lastName) => {
    return `${firstName} ${lastName}`;
  }
);

export const getAuthUserRolesSelector = createSelector([state => state.Auth.Auth.user.data.roles.data], roles => roles);
export const getAuthUserRolesNameSelector = createSelector([state => state.Auth.Auth.user.data.roles.data], roles => roles.map(({ name }) => name));
export const getAuthUserAvailableRoutesSelector = createSelector([state => state.Auth.Auth.user.data.availableRoutes.data], availableRoutes => availableRoutes);

export const getAuthUserHasInfoSelector = createSelector([state => state.Auth.Auth], v => v.hasInfo);
