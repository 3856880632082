export const compareDates = (date1, date2, comparator) => {
  if (comparator === '=') {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);

    return firstDate.getTime() === secondDate.getTime();
  }

  if (comparator === '>') {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    secondDate.setHours(23, 59, 59, 0);

    return firstDate.getTime() > secondDate.getTime();
  }

  if (comparator === '<') {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    secondDate.setHours(0, 0, 0, 0);

    return firstDate.getTime() < secondDate.getTime();
  }

  return true;
};
