import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import { getMethod, RESETED } from 'helpers';

import { GET_POLICY_LIST, VIEW_POLICY, RESET, FAILED_MESSAGE } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import { getPolicyListSuccess, getPolicyListFailed, viewPolicySuccess, viewPolicyFailed } from './actions';

function* policyListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/policy?include=policyMembers.person.addresses,policyMembers.person.contacts,plan,producer.agency,producer.person,createdBy.person`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getPolicyListSuccess(response));
  } catch (error) {
    yield put(getPolicyListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* policyView(action) {
  const abortController = new AbortController();

  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/policy/${action.payload}?include=policyMembers.person.addresses.location,policyMembers.person.contacts,plan,producer.agency,producer.person,producer.agentNumbers,createdBy.person,salesExternalDetails,answers,privateExchange,producer.parent.person,producer.parent.agentNumbers`;
    const response = yield call(getMethod, request, abortController.signal);

    yield put(viewPolicySuccess(response));
  } catch (error) {
    yield put(viewPolicyFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetPolicyList() {
  yield takeLeading(GET_POLICY_LIST, policyListGet);
}

export function* watchViewPolicy() {
  yield takeLeading(VIEW_POLICY, policyView);
}

function* policySaga() {
  while (true) {
    const tasks = yield all([fork(watchGetPolicyList), fork(watchViewPolicy)]);

    yield all([take(RESET), take(RESETED(GET_POLICY_LIST)), take(RESETED(VIEW_POLICY))]);

    yield cancel(tasks);
  }
}

export default policySaga;
