import {
  GET_PRODUCER_LIST,
  GET_PRODUCER,
  RESET_PRODUCER,
  MANAGE_PRODUCER,
  DELETE_PRODUCER,
  REACTIVATE_PRODUCER,
  GET_PRODUCER_POSSIBLE_PARENTS,
  APPROVE_PRODUCER,
  MANUAL_APPROVE_PRODUCER,
  GET_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_PRODUCER_RESTRICTIONS,
  MANAGE_PRODUCER_RESTRICTIONS,
  APPROVE_PRODUCER_NIPR,
  APPROVE_PRODUCER_FFM,
  RESET_PRODUCER_LIST,
  GET_ALL_PRODUCERS_LIST,
  RESET_ALL_PRODUCERS_LIST,
  COPY_TO_CLIPBOARD,
  PRODUCER_TEMPLATE_IMPORT_CSV,
  GET_ALL_PRODUCERS_AND_AGENCIES_LIST,
  RESET_ALL_PRODUCERS_AND_AGENCIES_LIST,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getProducerList = data => ({
  type: GET_PRODUCER_LIST,
  payload: data,
});

export const getProducerListSuccess = data => ({
  type: SUCCESS(GET_PRODUCER_LIST),
  payload: data,
});

export const getProducerListFailed = error => ({
  type: FAILED(GET_PRODUCER_LIST),
  payload: error,
});

export const resetProducerList = () => ({
  type: RESET_PRODUCER_LIST,
});

export const getAllProducersList = data => ({
  type: GET_ALL_PRODUCERS_LIST,
  payload: data,
});

export const getAllProducersListSuccess = data => ({
  type: SUCCESS(GET_ALL_PRODUCERS_LIST),
  payload: data,
});

export const getAllProducersListFailed = error => ({
  type: FAILED(GET_ALL_PRODUCERS_LIST),
  payload: error,
});

export const resetAllProducersList = () => ({
  type: RESET_ALL_PRODUCERS_LIST,
});

export const getAllProducersAndAgenciesList = data => ({
  type: GET_ALL_PRODUCERS_AND_AGENCIES_LIST,
  payload: data,
});

export const resetAllProducersAndAgenciesList = () => ({
  type: RESET_ALL_PRODUCERS_AND_AGENCIES_LIST ,
});

export const getAllProducersAndAgenciesListSuccess = data => ({
  type: SUCCESS(GET_ALL_PRODUCERS_AND_AGENCIES_LIST),
  payload: data,
});

export const getAllProducersAndAgenciesListFailed = error => ({
  type: FAILED(GET_ALL_PRODUCERS_AND_AGENCIES_LIST),
  payload: error,
});

export const getProducer = (agencyId, producerId) => ({
  type: GET_PRODUCER,
  payload: { agencyId, producerId },
});

export const getProducerSuccess = producer => ({
  type: SUCCESS(GET_PRODUCER),
  payload: producer,
});

export const getProducerFailed = error => ({
  type: FAILED(GET_PRODUCER),
  payload: error,
});

export const manageProducer = producer => ({
  type: MANAGE_PRODUCER,
  payload: producer,
});

export const manageProducerSuccess = producer => ({
  type: SUCCESS(MANAGE_PRODUCER),
  payload: producer,
});

export const manageProducerFailed = (error = null) => ({
  type: FAILED(MANAGE_PRODUCER),
  payload: error,
});

export const resetProducer = () => ({
  type: RESET_PRODUCER,
});

export const deleteProducer = data => ({
  type: DELETE_PRODUCER,
  payload: data,
});

export const deleteProducerSuccess = () => ({
  type: SUCCESS(DELETE_PRODUCER),
});

export const deleteProducerFailed = () => ({
  type: FAILED(DELETE_PRODUCER),
});

export const deleteProducerReset = () => ({
  type: RESETED(DELETE_PRODUCER),
});

export const reactivateProducer = data => ({
  type: REACTIVATE_PRODUCER,
  payload: data,
});

export const reactivateProducerSuccess = () => ({
  type: SUCCESS(REACTIVATE_PRODUCER),
});

export const reactivateProducerFailed = () => ({
  type: FAILED(REACTIVATE_PRODUCER),
});

export const reactivateProducerReset = () => ({
  type: RESETED(REACTIVATE_PRODUCER),
});

export const getProducerPossibleParents = ({ agencyId, producerId = null }) => ({
  type: GET_PRODUCER_POSSIBLE_PARENTS,
  payload: { agencyId, producerId },
});

export const getProducerPossibleParentsSuccess = data => ({
  type: SUCCESS(GET_PRODUCER_POSSIBLE_PARENTS),
  payload: data,
});

export const getProducerPossibleParentsFailed = error => ({
  type: FAILED(GET_PRODUCER_POSSIBLE_PARENTS),
  payload: error,
});

export const resetProducerPossibleParents = () => ({
  type: RESETED(GET_PRODUCER_POSSIBLE_PARENTS),
});

export const approveProducer = data => ({
  type: APPROVE_PRODUCER,
  payload: data,
});

export const approveProducerSuccess = () => ({
  type: SUCCESS(APPROVE_PRODUCER),
});

export const approveProducerFailed = data => ({
  type: FAILED(APPROVE_PRODUCER),
  payload: data,
});

export const approveProducerNIPR = data => ({
  type: APPROVE_PRODUCER_NIPR,
  payload: data,
});

export const approveProducerNIPRSuccess = () => ({
  type: SUCCESS(APPROVE_PRODUCER_NIPR),
});

export const approveProducerNIPRFailed = data => ({
  type: FAILED(APPROVE_PRODUCER_NIPR),
  payload: data,
});

export const approveProducerFFM = data => ({
  type: APPROVE_PRODUCER_FFM,
  payload: data,
});

export const approveProducerFFMSuccess = () => ({
  type: SUCCESS(APPROVE_PRODUCER_FFM),
});

export const approveProducerFFMFailed = data => ({
  type: FAILED(APPROVE_PRODUCER_FFM),
  payload: data,
});

export const approveProducerReset = () => ({
  type: RESETED(APPROVE_PRODUCER),
});

export const manualApproveProducer = producer => ({
  type: MANUAL_APPROVE_PRODUCER,
  payload: producer,
});

export const manualApproveProducerSuccess = producer => ({
  type: SUCCESS(MANUAL_APPROVE_PRODUCER),
  payload: producer,
});

export const manualApproveProducerFailed = (errors = null) => ({
  type: FAILED(MANUAL_APPROVE_PRODUCER),
  payload: errors,
});

export const manualApproveProducerReset = () => ({
  type: RESETED(MANUAL_APPROVE_PRODUCER),
});

// restrictions
export const getProducerRestrictionsFormConfig = (agencyId, producerId) => ({
  type: GET_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  payload: { agencyId, producerId },
});

export const getProducerRestrictionsFormConfigSuccess = data => ({
  type: SUCCESS(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG),
  payload: data,
});

export const getProducerRestrictionsFormConfigFailed = () => ({
  type: FAILED(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG),
});

export const getProducerRestrictions = (agencyId, producerId, callback = null) => ({
  type: GET_PRODUCER_RESTRICTIONS,
  payload: { agencyId, producerId, callback },
});

export const getAgentRepProducerRestrictionsFormConfig = (agencyId, producerId) => ({
  type: GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  payload: { agencyId, producerId },
});

export const getAgentRepProducerRestrictionsFormConfigSuccess = data => ({
  type: SUCCESS(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG),
  payload: data,
});

export const getAgentRepProducerRestrictionsFormConfigFailed = () => ({
  type: FAILED(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG),
});

export const getAgentRepProducerRestrictions = (agencyId, producerId, callback = null) => ({
  type: GET_AGENT_REP_PRODUCER_RESTRICTIONS,
  payload: { agencyId, producerId, callback },
});

export const manageProducerRestrictions = producer => ({
  type: MANAGE_PRODUCER_RESTRICTIONS,
  payload: producer,
});

export const manageProducerRestrictionsSuccess = producer => ({
  type: SUCCESS(MANAGE_PRODUCER_RESTRICTIONS),
  payload: producer,
});

export const manageProducerRestrictionsFailed = (error = null) => ({
  type: FAILED(MANAGE_PRODUCER_RESTRICTIONS),
  payload: error,
});

export const copyToClipboard = (message) => ({
  type: COPY_TO_CLIPBOARD,
  payload: message,
});

export const producerTemplateImportCsv = data => ({
  type: PRODUCER_TEMPLATE_IMPORT_CSV,
  payload: data,
});

export const producerTemplateImportCsvSuccess = data => ({
  type: SUCCESS(PRODUCER_TEMPLATE_IMPORT_CSV),
  payload: data,
});

export const producerTemplateImportCsvFailed = error => ({
  type: FAILED(PRODUCER_TEMPLATE_IMPORT_CSV),
  payload: error,
});
