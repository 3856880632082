import { all, call, fork, put, cancelled, take, cancel, takeLatest, select } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  GET_CARRIER_LIST,
  DELETE_CARRIER,
  GET_CARRIER,
  MANAGE_CARRIER,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import { getMethod, updateMethod, resolveErrorPromise, createMethod, deleteMethod } from 'helpers';
import {
  getCarrierListSuccess,
  getCarrierListFailed,
  manageCarrierSuccess,
  manageCarrierFailed,
  getCarrierSuccess,
  getCarrierFailed,
  deleteCarrierSuccess,
  deleteCarrierFailed,
} from './actions';
import { getCarrierLogoFromStateSelector } from './reducers';

function* carrierGetList() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getCarrierListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getCarrierListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* carrierGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier/${action.payload}`,
      abortController.signal
    );
    yield put(getCarrierSuccess(data.data));
  } catch (error) {
    yield put(getCarrierFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* carrierManage(action) {
  const old = yield select(getCarrierLogoFromStateSelector);
  try {
    if (action.payload.id === '') {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier`,
        JSON.stringify(action.payload)
      );
    } else {
      if (old.logo !== action.payload.logo) {
        action.payload.oldLogo = old.logo;
      }
      yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/carrier/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageCarrierSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageCarrierFailed(message));
    } else {
      yield put(manageCarrierFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* carrierDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/carrier/${action.payload}`);

    yield put(deleteCarrierSuccess());
  } catch (error) {
    yield put(deleteCarrierFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchCarrierGetList() {
  yield takeLatest(GET_CARRIER_LIST, carrierGetList);
}

export function* watchCarrierGet() {
  yield takeLatest(GET_CARRIER, carrierGet);
}

export function* watchCarrierManage() {
  yield takeLatest(MANAGE_CARRIER, carrierManage);
}

export function* watchCarrierDelete() {
  yield takeLatest(DELETE_CARRIER, carrierDelete);
}

function* carrierSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchCarrierGetList),
      fork(watchCarrierGet),
      fork(watchCarrierManage),
      fork(watchCarrierDelete),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default carrierSaga;
