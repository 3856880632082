import {
  GET_API_LIST,
  DELETE_API,
  REACTIVATE_API,
  GET_API_FORM_CONFIG,
  GET_API,
  MANAGE_API,
  RESET_API,
  RESET_API_LIST,
} from 'constants/actionTypes';

import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getApiList = (data = {}) => ({
  type: GET_API_LIST,
  payload: data,
});

export const getApiListSuccess = data => ({
  type: SUCCESS(GET_API_LIST),
  payload: data,
});

export const getApiListFailed = error => ({
  type: FAILED(GET_API_LIST),
  payload: error,
});

export const resetApiList = () => ({
  type: RESET_API_LIST,
});

export const deleteApi = api => ({
  type: DELETE_API,
  payload: api,
});

export const deleteApiSuccess = () => ({
  type: SUCCESS(DELETE_API),
});

export const deleteApiFailed = () => ({
  type: FAILED(DELETE_API),
});

export const resetDeleteApi = () => ({
  type: RESETED(DELETE_API),
});

export const reactivateApi = api => ({
  type: REACTIVATE_API,
  payload: api,
});

export const reactivateApiSuccess = () => ({
  type: SUCCESS(REACTIVATE_API),
});

export const reactivateApiFailed = () => ({
  type: FAILED(REACTIVATE_API),
});

export const resetReactivateApi = () => ({
  type: RESETED(REACTIVATE_API),
});

export const getApiFormConfig = () => ({
  type: GET_API_FORM_CONFIG,
});

export const getApiFormConfigSuccess = data => ({
  type: SUCCESS(GET_API_FORM_CONFIG),
  payload: data,
});

export const getApiFormConfigFailed = () => ({
  type: FAILED(GET_API_FORM_CONFIG),
});

export const getApi = (apiId, callback = null) => ({
  type: GET_API,
  payload: { apiId, callback },
});

export const getApiSuccess = data => ({
  type: SUCCESS(GET_API),
  payload: data,
});

export const getApiFailed = () => ({
  type: FAILED(GET_API),
});

export const manageApi = data => ({
  type: MANAGE_API,
  payload: data,
});

export const manageApiSuccess = data => ({
  type: SUCCESS(MANAGE_API),
  payload: data,
});

export const manageApiFailed = (error = null) => ({
  type: FAILED(MANAGE_API),
  payload: error,
});

export const resetApi = () => ({
  type: RESET_API,
});
