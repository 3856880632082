import { combineReducers } from 'redux';
import Clients from './client/reducers';
import Roles from './role/reducers';
import Route from './route/reducers';
import Users from './user/reducers';

export default combineReducers({
  Clients,
  Roles,
  Route,
  Users,
});
