import {
  GET_ZIP_CODE,
  GET_ZIP_CODE_LIST,
  DELETE_ZIP_CODE,
  RESET_ZIP_CODE,
  RESET_ZIP_CODE_LIST,
  MANAGE_ZIP_CODE,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getZipCodeList = (data) => ({
  type: GET_ZIP_CODE_LIST,
  payload: data,
});

export const getZipCodeListSuccess = (data) => ({
  type: SUCCESS(GET_ZIP_CODE_LIST),
  payload: data,
});

export const getZipCodeListFailed = (error) => ({
  type: FAILED(GET_ZIP_CODE_LIST),
  payload: error,
});

export const resetZipCodeList = () => ({
  type: RESET_ZIP_CODE_LIST,
});

export const deleteZipCode = (zipCode) => ({
  type: DELETE_ZIP_CODE,
  payload: zipCode,
});

export const deleteZipCodeSuccess = () => ({
  type: SUCCESS(DELETE_ZIP_CODE),
});

export const deleteZipCodeFailed = () => ({
  type: FAILED(DELETE_ZIP_CODE),
});

export const resetDeleteZipCode = () => ({
  type: RESETED(DELETE_ZIP_CODE),
});

export const getZipCode = (zipCodeId) => ({
  type: GET_ZIP_CODE,
  payload: zipCodeId,
});

export const getZipCodeSuccess = (data) => ({
  type: SUCCESS(GET_ZIP_CODE),
  payload: data,
});

export const getZipCodeFailed = () => ({
  type: FAILED(GET_ZIP_CODE),
});

export const manageZipCode = (data) => ({
  type: MANAGE_ZIP_CODE,
  payload: data,
});

export const manageZipCodeSuccess = (data) => ({
  type: SUCCESS(MANAGE_ZIP_CODE),
  payload: data,
});

export const manageZipCodeFailed = (error) => ({
  type: FAILED(MANAGE_ZIP_CODE),
  payload: error,
});

export const resetZipCode = () => ({
  type: RESET_ZIP_CODE,
});
