import { FORGET_PASSWORD, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const INIT_STATE = {
  loading: false,
  email: null,
  host: null,
  redirect: false,
  error: null,
};

const ForgetPassword = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return { ...state, email: action.payload.email, host: action.payload.host, loading: true };
    case SUCCESS(FORGET_PASSWORD):
      return { ...state, redirect: true, loading: false };
    case FAILED(FORGET_PASSWORD):
      return { ...state, error: action.payload, loading: false };
    case RESETED(FORGET_PASSWORD):
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default ForgetPassword;
