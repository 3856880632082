import _ from 'lodash';
import { all, call, fork, put, takeLeading, takeLatest, cancelled, take, cancel, race } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { createMethod, getMethod, patchMethod, updateMethod, resolveErrorPromise } from 'helpers';
import {
  DELETE_AGENCY,
  GET_AGENCY_LIST,
  APPROVE_AGENCY,
  REACTIVATE_AGENCY,
  GET_AGENCY,
  MANAGE_AGENCY,
  GET_AGENCY_POSSIBLE_PARENTS,
  FAILED_MESSAGE,
  RESET,
  GET_CHILD_AGENCY_LIST,
  DELETE_CHILD_AGENCY,
  REACTIVATE_CHILD_AGENCY,
  APPROVE_CHILD_AGENCY,
  GET_CHILD_AGENCY,
  MANAGE_CHILD_AGENCY,
  GET_AGENCY_RESTRICTION_FORM_CONFIG,
  GET_AGENCY_RESTRICTION,
  MANAGE_AGENCY_RESTRICTION,
  GET_AGENCY_SETTING,
  MANAGE_AGENCY_SETTING,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import {
  getAgencyList,
  getAgencyListSuccess,
  getAgencyListFailed,
  deleteAgencySuccess,
  deleteAgencyFailed,
  reactivateAgencySuccess,
  reactivateAgencyFailed,
  approveAgencySuccess,
  approveAgencyFailed,
  getAgencySuccess,
  getAgencyFailed,
  manageAgencySuccess,
  manageAgencyFailed,
  getAgencyPossibleParentsSuccess,
  getAgencyPossibleParentsFailed,
  getLocation,
  getAgencyPossibleParents,
  getChildAgencyListSuccess,
  getChildAgencyListFailed,
  deleteChildAgencySuccess,
  getChildAgencyList,
  deleteChildAgencyFailed,
  reactivateChildAgencyFailed,
  approveChildAgencyFailed,
  reactivateChildAgencySuccess,
  approveChildAgencySuccess,
  getChildAgencySuccess,
  getChildAgencyFailed,
  manageChildAgencySuccess,
  manageChildAgencyFailed,
  getAgency,
  getAgencyRestrictionFormConfigSuccess,
  getAgencyRestrictionFormConfigFailed,
  getAgencySettingSuccess,
  getAgencySettingFailed,
  manageAgencySettingSuccess,
  manageAgencySettingFailed,
} from 'redux/actions';
import dayjs from 'dayjs';

function* agencyGetList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency?include=defaultProducer.person.contacts,parent`;
    const params = {
      ...action.payload.pagination.pagination,
      ...action.payload.pagination.order,
      ...action.payload.pagination.filters,
    };
    if (params.createdAt !== '') {
      _.set(params, 'createdAt', `${params.createdAt.comparator}${dayjs(params.createdAt.date).format('YYYY-MM-DD')}`);
    }
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAgencyListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAgencyListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencyDelete(isChild = false, action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agency.id}`,
      JSON.stringify({ isDeleted: 1 })
    );

    if (isChild) {
      yield put(deleteChildAgencySuccess());
    } else {
      yield put(deleteAgencySuccess());
    }
  } catch (error) {
    if (isChild) {
      yield put(deleteChildAgencyFailed());
    } else {
      yield put(deleteAgencyFailed());
    }
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* agencyReactivate(isChild = false, action) {
  try {
    const { showDeletedAgencies } = action.payload;
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agency.id}`,
      JSON.stringify({ isDeleted: 0 })
    );

    if (isChild) {
      yield put(reactivateChildAgencySuccess());
    } else {
      yield put(reactivateAgencySuccess());
    }
  } catch (error) {
    if (isChild) {
      yield put(reactivateChildAgencyFailed());
    } else {
      yield put(reactivateAgencyFailed());
    }
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* agencyApprove(isChild = false, action) {
  try {
    const { showDeletedAgencies } = action.payload;
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agency.id}`,
      JSON.stringify({ isApproved: 1 })
    );

    if (isChild) {
      yield put(approveChildAgencySuccess());
    } else {
      yield put(approveAgencySuccess());
    }
  } catch (error) {
    if (isChild) {
      yield put(approveChildAgencyFailed());
    } else {
      yield put(approveAgencyFailed());
    }
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* agencyGet(isChild = false, action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user`;
    const response = yield call(getMethod, request, abortController.signal);

    if (isChild) {
      yield put(getChildAgencySuccess(response));
      yield put(getAgencyPossibleParents(action.payload.agencyId));
    } else {
      yield put(getAgencySuccess(response));
      yield put(getAgencyPossibleParents(action.payload.agencyId));
    }

    if (_.has(response, 'data.address.data.zipCode') && response.data.address.data.zipCode !== '') {
      yield put(
        getLocation({
          zipCode: response.data.address.data.zipCode,
          source: 'address',
        })
      );
    }
  } catch (error) {
    if (isChild) {
      yield put(getChildAgencyFailed());
    } else {
      yield put(getAgencyFailed());
    }
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencyManage(isChild = false, action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.id}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user`,
        JSON.stringify(action.payload)
      );
    }

    if (isChild) {
      yield put(manageChildAgencySuccess(response));
    } else {
      yield put(manageAgencySuccess(response));
    }
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      if (isChild) {
        yield put(manageChildAgencyFailed(message));
      } else {
        yield put(manageAgencyFailed(message));
      }
    } else {
      if (isChild) {
        yield put(manageChildAgencyFailed());
      } else {
        yield put(manageAgencyFailed());
      }

      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* agencyGetPossibleParents(action) {
  let response;
  if (action.payload.parentId) {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.parentId}?include=children`;
    response = yield call(getMethod, request);
    response.data.children.data.unshift(response.data);
    response = response.data.children;
  } else {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency?child=${action.payload.agencyId}`;
    response = yield call(getMethod, request);
  }
  try {
    yield put(getAgencyPossibleParentsSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAgencyPossibleParentsFailed());
  }
}

function* childAgencyGetList(action) {
  const abortController = new AbortController();
  try {
    yield put(getAgency(action.payload.agencyId));
    yield take('GET_AGENCY_SUCCESS');

    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}?include=children.defaultProducer.person.contacts`;

    const params = {
      ...action.payload.pagination.pagination,
      ...action.payload.pagination.order,
      ...action.payload.pagination.filters,
    };
    if (params.createdAt !== '') {
      _.set(params, 'createdAt', `${params.createdAt.comparator}${dayjs(params.createdAt.date).format('YYYY-MM-DD')}`);
    }
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}`;
    }

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getChildAgencyListSuccess(response.data.children));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getChildAgencyListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencyRestrictionGetFormConfig() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency-restrictions`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAgencyRestrictionFormConfigSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAgencyRestrictionFormConfigFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencySettingGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user,salesforce,okta`;
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getAgencySettingSuccess(response));
  } catch (error) {
    yield put(getAgencySettingFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencySettingManage(action) {
  const payload = _.omit(
    action.payload,
    [
      'id',
      'companyName',
      'parent',
      'address',
      'isDeleted',
      'isApproved',
      'allowChildToEditRestrictions',
      'allowProducerToEditRestrictions',
      'hasChildren',
      'createdAt',
      'remainingCredits',
      'theme',
      'issuerName',
      'allowReferrals',
      'contacts',
      'defaultProducer',
      'saveCMSQuotingRequest',
      'allChildren',
      'producerRestrictedIssuers',
      'producerApis'
    ]
  );

  if (Object.values(action.payload.salesforce).every((v) => !v) && !action.payload.useSalesforceLeadCapture) {
    _.unset(payload, 'salesforce');
  }

  try {
    const response = yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.id}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user`,
      JSON.stringify(payload)
    );

    yield put(manageAgencySettingSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageAgencySettingFailed(message));
    } else {
      yield put(manageAgencySettingFailed(message));

      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* agencyRestrictionsGet(isChild = false, action) {
  const abortController = new AbortController();
  try {
    if (action.payload.callback) {
      yield call(action.payload.callback);
      yield race({
        success: take(SUCCESS(GET_AGENCY_RESTRICTION_FORM_CONFIG)), //
        failed: take(FAILED(GET_AGENCY_RESTRICTION_FORM_CONFIG)),
      }); // wait for succes response before continuing...
    }

    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user,producerApis.producerApiLineOfCoverages.apiLineOfCoverage.lineOfCoverage,producerApis.producerApiHiddenPlans,producerApis.api`;
    const response = yield call(getMethod, request, abortController.signal);

    if (isChild) {
      yield put(getChildAgencySuccess(response));
    } else {
      yield put(getAgencySuccess(response));
    }
  } catch (error) {
    if (isChild) {
      yield put(getChildAgencyFailed());
    } else {
      yield put(getAgencyFailed());
    }
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agencyRestrictionsManage(isChild = false, action) {
  try {
    const response = yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency-restrictions/${action.payload.id}?include=parent,allChildren,address,contacts,defaultProducer.person,defaultProducer.person.contacts,defaultProducer.person.addresses,defaultProducer.agentNumbers,defaultProducer.user,producerRestrictedIssuers,producerApis.producerApiLineOfCoverages.apiLineOfCoverage.lineOfCoverage,producerApis.producerApiHiddenPlans,producerApis.api`,
      JSON.stringify(action.payload)
    );

    if (isChild) {
      yield put(manageChildAgencySuccess(response));
    } else {
      yield put(manageAgencySuccess(response));
    }
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      if (isChild) {
        yield put(manageChildAgencyFailed(message));
      } else {
        yield put(manageAgencyFailed(message));
      }
    } else {
      if (isChild) {
        yield put(manageChildAgencyFailed());
      } else {
        yield put(manageAgencyFailed());
      }
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetAgencyList() {
  yield takeLatest(GET_AGENCY_LIST, agencyGetList);
}

export function* watchDeleteAgency() {
  yield takeLeading(DELETE_AGENCY, agencyDelete, false);
  yield takeLeading(DELETE_CHILD_AGENCY, agencyDelete, true);
}

export function* watchActivateAgency() {
  yield takeLeading(REACTIVATE_AGENCY, agencyReactivate, false);
  yield takeLeading(REACTIVATE_CHILD_AGENCY, agencyReactivate, true);
}

export function* watchApproveAgency() {
  yield takeLeading(APPROVE_AGENCY, agencyApprove, false);
  yield takeLeading(APPROVE_CHILD_AGENCY, agencyApprove, true);
}

export function* watchGetAgency() {
  yield takeLatest(GET_AGENCY, agencyGet, false);
  yield takeLatest(GET_CHILD_AGENCY, agencyGet, true);
}

export function* watchGetAgencyRestrictions() {
  yield takeLatest(GET_AGENCY_RESTRICTION, agencyRestrictionsGet, false);
}

export function* watchManageAgency() {
  yield takeLeading(MANAGE_AGENCY, agencyManage, false);
  yield takeLeading(MANAGE_CHILD_AGENCY, agencyManage, true);
}

export function* watchGetAgencyPossibleParents() {
  yield takeLeading(GET_AGENCY_POSSIBLE_PARENTS, agencyGetPossibleParents);
}

export function* watchGetChildAgencyList() {
  yield takeLeading(GET_CHILD_AGENCY_LIST, childAgencyGetList);
}

export function* watchGetAgencyRestrictionFormConfig() {
  yield takeLeading(GET_AGENCY_RESTRICTION_FORM_CONFIG, agencyRestrictionGetFormConfig);
}

export function* watchManageAgencyRestrictions() {
  yield takeLeading(MANAGE_AGENCY_RESTRICTION, agencyRestrictionsManage, false);
}

export function* watchGetAgencySetting() {
  yield takeLeading(GET_AGENCY_SETTING, agencySettingGet);
}

export function* watchManageAgencySetting() {
  yield takeLeading(MANAGE_AGENCY_SETTING, agencySettingManage);
}

function* agenciesSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetAgencyList),
      fork(watchDeleteAgency),
      fork(watchActivateAgency),
      fork(watchApproveAgency),
      fork(watchGetAgency),
      fork(watchManageAgency),
      fork(watchGetAgencyPossibleParents),
      fork(watchGetChildAgencyList),
      fork(watchGetAgencyRestrictionFormConfig),
      fork(watchGetAgencyRestrictions),
      fork(watchManageAgencyRestrictions),
      fork(watchGetAgencySetting),
      fork(watchManageAgencySetting),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default agenciesSaga;
