import {
  GET_ACTIVE_SESSION_LIST,
  REVOKE_ACTIVE_SESSION,
  REVOKE_ALL_ACTIVE_SESSIONS,
  RESET_ACTIVE_SESSION_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const getActiveSessionList = userId => ({
  type: GET_ACTIVE_SESSION_LIST,
  payload: userId,
});

export const getActiveSessionListSuccess = data => ({
  type: SUCCESS(GET_ACTIVE_SESSION_LIST),
  payload: data,
});

export const getActiveSessionListFailed = (error = null) => ({
  type: FAILED(GET_ACTIVE_SESSION_LIST),
  payload: error,
});

export const revokeActiveSession = id => ({
  type: REVOKE_ACTIVE_SESSION,
  payload: id,
});

export const revokeActiveSessionSuccess = () => ({
  type: SUCCESS(REVOKE_ACTIVE_SESSION),
});

export const revokeActiveSessionFailed = () => ({
  type: FAILED(REVOKE_ACTIVE_SESSION),
});

export const revokeAllActiveSessions = (userId, refreshToken) => ({
  type: REVOKE_ALL_ACTIVE_SESSIONS,
  payload: { userId, refreshToken },
});

export const revokeAllActiveSessionsSuccess = () => ({
  type: SUCCESS(REVOKE_ALL_ACTIVE_SESSIONS),
});

export const revokeAllActiveSessionsFailed = () => ({
  type: FAILED(REVOKE_ALL_ACTIVE_SESSIONS),
});

export const resetActiveSessionList = () => ({
  type: RESET_ACTIVE_SESSION_LIST,
});
