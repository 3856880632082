import { GET_LOCATION, RESET_LOCATION } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const getLocation = ({ zipCode, source = '' }) => ({
  type: GET_LOCATION,
  payload: { zipCode, source },
});

export const getLocationSuccess = (data, source) => ({
  type: SUCCESS(GET_LOCATION),
  payload: data,
  source,
});

export const getLocationFailed = error => ({
  type: FAILED(GET_LOCATION),
  payload: error,
});

export const resetLocation = () => ({
  type: RESET_LOCATION,
});
