import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { middleware as createflashMiddleware } from 'redux-flash';
import { persistStore, persistReducer } from 'redux-persist/es';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const flashMiddleware = createflashMiddleware({ timeout: 5000 });
const middlewares = [sagaMiddleware, flashMiddleware];

const persistConfig = {
  key: 'root',
  storage,
  transforms: [],
  whitelist: [],
  stateReconciler: autoMergeLevel2,
};

const pReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    pReducer,
    initialState,
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(...middlewares)
      : composeEnhancers(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);

  return { store, persistor };
}
