export const GET_QUESTION_LIST = 'GET_QUESTION_LIST';

export const RESET_QUESTION_LIST = 'RESET_QUESTION_LIST';

export const DELETE_QUESTION = 'DELETE_QUESTION';

export const REACTIVATE_QUESTION = 'REACTIVATE_QUESTION';

export const GET_QUESTION = 'GET_QUESTION';

export const MANAGE_QUESTION = 'MANAGE_QUESTION';

export const RESET_QUESTION = 'RESET_QUESTION';

export const IMPORT_CSV = 'IMPORT_CSV';

export const GET_TERMS_AND_CONDITIONS_LIST = 'GET_TERMS_AND_CONDITIONS_LIST';

export const RESET_TERMS_AND_CONDITIONS_LIST = 'RESET_TERMS_AND_CONDITIONS_LIST';

export const IMPORT_TERMS_AND_CONDITIONS_CSV = 'IMPORT_TERMS_AND_CONDITIONS_CSV';

export const DELETE_TERMS_AND_CONDITIONS = 'DELETE_TERMS_AND_CONDITIONS';

export const REACTIVATE_TERMS_AND_CONDITIONS = 'REACTIVATE_TERMS_AND_CONDITIONS';
