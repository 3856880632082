import { getMethod, resolveErrorPromise } from "helpers";
import { all, call, cancel, cancelled, fork, put, take, takeLatest } from "redux-saga/effects";
import { flashErrorMessage } from "redux-flash";
import { FAILED_MESSAGE, GET_IDEON_LOG_LIST, RESET } from "constants/actionTypes";
import { getIdeonLogListFailed, getIdeonLogListSuccess } from "./actions";

function* getIdeonLogList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ideon-log?`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}&withPagination=true`;
    }

    const response = yield call(getMethod, request, abortController.signal);

    yield put(getIdeonLogListSuccess(response));
  } catch (error) {
    yield put(getIdeonLogListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(!message?.detail ? flashErrorMessage(FAILED_MESSAGE) : flashErrorMessage(message.detail));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* watchGetIdeonLogList() {
  yield takeLatest(GET_IDEON_LOG_LIST, getIdeonLogList);
}

function* ideonLogSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetIdeonLogList),
    ]);

    yield take(RESET);
    yield cancel(tasks);
  }
}

export default ideonLogSaga;