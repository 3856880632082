import ClientOAuth2 from 'client-oauth2';
import dayjs from 'dayjs';

export default new ClientOAuth2({
  clientId: 'client',
  clientSecret: 'password2',
  accessTokenUri: `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/token?tz=${dayjs(new Date()).format()}`,
  authorizationUri: `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/oauth/token?tz=${dayjs(new Date()).format()}`,
  scopes: [],
});
