import { all, call, fork, put, takeLeading, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, resolveErrorPromise } from 'helpers';
import {
  GET_ROUTE_LIST,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getRouteListSuccess,
  getRouteListFailed,
} from './actions';

function* routeGetList(action) {
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/route?isPublic=0&client=${action.payload.clientId}`;
    const response = yield call(getMethod, request);

    yield put(getRouteListSuccess(response)); // save to redux
  } catch (error) {
    yield put(getRouteListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetRouteList() {
  yield takeLeading(GET_ROUTE_LIST, routeGetList);
}

function* routeSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetRouteList),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default routeSaga;
