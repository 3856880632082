import { all, call, fork, put, takeLeading, takeLatest, cancelled, take, cancel } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, createMethod, updateMethod, patchMethod, resolveErrorPromise } from 'helpers';
import {
  GET_USER_LIST,
  GET_USER,
  MANAGE_USER,
  DELETE_USER,
  REACTIVATE_USER,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getUserListSuccess,
  getUserListFailed,
  getUserSuccess,
  getUserFailed,
  manageUserSuccess,
  manageUserFailed,
  deleteUserSuccess,
  deleteUserFailed,
  reactivateUserSuccess,
  reactivateUserFailed,
} from './actions';

function* userGetList(action) {
  const abortController = new AbortController();

  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/user?include=roles.routes,person,producer.agency,producer.person`;
    let params = {};
    params = { ...params, ...action.payload.filters, ...action.payload.pagination, ...action.payload.order };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }
    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getUserListSuccess(response));
  } catch (error) {
    yield put(getUserListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* userGet(action) {
  const abortController = new AbortController();

  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/user/${action.payload.userId}?include=roles.routes,person.contacts,producer,producer.agency`,
      abortController.signal
    );

    yield put(getUserSuccess(response));
  } catch (error) {
    yield put(getUserFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* userManage(action) {
  try {
    let response;

    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${action.payload.id}?include=roles.routes,person.contacts&isAppAdmin=true`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user?include=roles.routes,person.contacts`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageUserSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageUserFailed(message));
    } else {
      yield put(manageUserFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* userDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${action.payload.userId}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteUserSuccess()); // save to redux
    // yield put(getUserList()); // save to redux
  } catch (error) {
    yield put(deleteUserFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* userReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/user/${action.payload.userId}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(reactivateUserSuccess());
    // yield put(getUserList()); // save to redux
  } catch (error) {
    yield put(reactivateUserFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetUserList() {
  yield takeLatest(GET_USER_LIST, userGetList);
}
export function* watchGetUser() {
  yield takeLatest(GET_USER, userGet);
}
export function* watchManageUser() {
  yield takeLeading(MANAGE_USER, userManage);
}
export function* watchDeleteUser() {
  yield takeLeading(DELETE_USER, userDelete);
}
export function* watchReactivateUser() {
  yield takeLeading(REACTIVATE_USER, userReactivate);
}

function* usersSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetUserList),
      fork(watchGetUser),
      fork(watchManageUser),
      fork(watchDeleteUser),
      fork(watchReactivateUser),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default usersSaga;
