export const GET_CARRIER_LIST = 'GET_CARRIER_LIST';

export const RESET_CARRIER_LIST = 'RESET_CARRIER_LIST';

export const GET_CARRIER = 'GET_CARRIER';

export const MANAGE_CARRIER = 'MANAGE_CARRIER';

export const DELETE_CARRIER = 'DELETE_CARRIER';

export const RESET_CARRIER = 'RESET_CARRIER';
