import { GET_EFFECTIVE_DATES_LIST, GET_EFFECTIVE_DATES, MANAGE_EFFECTIVE_DATES, DELETE_EFFECTIVE_DATES } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getEffectiveDatesList = () => ({
  type: GET_EFFECTIVE_DATES_LIST,
});

export const getEffectiveDatesListSuccess = data => ({
  type: SUCCESS(GET_EFFECTIVE_DATES_LIST),
  payload: data,
});

export const getEffectiveDatesListFailed = data => ({
  type: FAILED(GET_EFFECTIVE_DATES_LIST),
  payload: data,
});

export const resetEffectiveDatesList = () => ({
  type: RESETED(GET_EFFECTIVE_DATES_LIST),
});

export const getEffectiveDates = effectiveDateId => ({
  type: GET_EFFECTIVE_DATES,
  payload: effectiveDateId,
});

export const getEffectiveDatesSuccess = data => ({
  type: SUCCESS(GET_EFFECTIVE_DATES),
  payload: data,
});

export const getEffectiveDatesFailed = data => ({
  type: FAILED(GET_EFFECTIVE_DATES),
  payload: data,
});

export const resetEffectiveDates = () => ({
  type: RESETED(GET_EFFECTIVE_DATES),
});

export const manageEffectiveDates = data => ({
  type: MANAGE_EFFECTIVE_DATES,
  payload: data,
});

export const manageEffectiveDatesSuccess = data => ({
  type: SUCCESS(MANAGE_EFFECTIVE_DATES),
  payload: data,
});

export const manageEffectiveDatesFailed = data => ({
  type: FAILED(MANAGE_EFFECTIVE_DATES),
  payload: data,
});

export const deleteEffectiveDates = effectiveDateId => ({
  type: DELETE_EFFECTIVE_DATES,
  payload: effectiveDateId,
});

export const deleteEffectiveDatesSuccess = () => ({
  type: SUCCESS(DELETE_EFFECTIVE_DATES)
});

export const deleteEffectiveDatesFailed = () => ({
  type: FAILED(DELETE_EFFECTIVE_DATES)
});
