import { FORGET_PASSWORD } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers';

export const forgetPassword = data => ({
  type: FORGET_PASSWORD,
  payload: data,
});

export const forgetPasswordSuccess = () => ({
  type: SUCCESS(FORGET_PASSWORD),
});

export const forgetPasswordFailed = error => ({
  type: FAILED(FORGET_PASSWORD),
  payload: error,
});

export const forgetPasswordReset = () => ({
  type: RESETED(FORGET_PASSWORD),
});
