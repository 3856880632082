import {
  GET_CARRIER_LIST,
  DELETE_CARRIER,
  GET_CARRIER,
  MANAGE_CARRIER,
  RESET_CARRIER,
  RESET_CARRIER_LIST,
} from 'constants/actionTypes';

import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getCarrierList = (data = {}) => ({
  type: GET_CARRIER_LIST,
  payload: data,
});

export const getCarrierListSuccess = data => ({
  type: SUCCESS(GET_CARRIER_LIST),
  payload: data,
});

export const getCarrierListFailed = data => ({
  type: FAILED(GET_CARRIER_LIST),
  payload: data,
});

export const resetCarrierList = () => ({
  type: RESET_CARRIER_LIST,
});

export const deleteCarrier = (carrierId) => ({
  type: DELETE_CARRIER,
  payload: carrierId,
});

export const deleteCarrierSuccess = () => ({
  type: SUCCESS(DELETE_CARRIER),
});

export const deleteCarrierFailed = () => ({
  type: FAILED(DELETE_CARRIER),
});

export const resetDeleteCarrier = () => ({
  type: RESETED(DELETE_CARRIER),
});

export const getCarrier = (carrierId) => ({
  type: GET_CARRIER,
  payload: carrierId,
});

export const getCarrierSuccess = data => ({
  type: SUCCESS(GET_CARRIER),
  payload: data,
});

export const getCarrierFailed = () => ({
  type: FAILED(GET_CARRIER),
});

export const manageCarrier = data => ({
  type: MANAGE_CARRIER,
  payload: data,
});

export const manageCarrierSuccess = data => ({
  type: SUCCESS(MANAGE_CARRIER),
  payload: data,
});

export const manageCarrierFailed = (error = null) => ({
  type: FAILED(MANAGE_CARRIER),
  payload: error,
});

export const resetCarrier = () => ({
  type: RESET_CARRIER,
});
