import { createSelector } from 'reselect';
import {
  RESET_ROLE,
  GET_ROUTE_LIST,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const ROUTE_INIT_STATE = {
  loading: false,
  error: false,
  success: false,
  routes: {
    data: [],
  },
};

const Route = (state = ROUTE_INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ROLE:
    case RESET:
      return ROUTE_INIT_STATE;

    case GET_ROUTE_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_ROUTE_LIST):
      return { ...state, routes: action.payload, loading: false, error: null };
    case FAILED(GET_ROUTE_LIST):
      return { ...state, error: action.payload, loading: false, success: false };

    default:
      return state;
  }
};

export default Route;

// selectors
export const getRoutesSelector = createSelector([state => state.Acl.Route.routes.data], routes => routes);
export const getRoutesLoadingSelector = createSelector([state => state.Acl.Route.loading], loading => loading);
