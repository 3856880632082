import { GET_QUOTE_PIN_LIST } from 'constants/quotePin';
import {getMethod, resolveErrorPromise } from 'helpers';
import { all, call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { FAILED_MESSAGE, RESET } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';

import { getQuotePinListSuccess, getQuotePinListFailed } from './actions';

function* getQuotePinList (action) {
  const abortController = new AbortController();


  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/quote-pin`;
    
    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();
  
    if (paramString.length) {
      request += `?${paramString}&withPagination=true`;
    }
    const response = yield call(getMethod, request, abortController.signal);

    yield(put(getQuotePinListSuccess(response)))
  } catch(error) {
    yield put(getQuotePinListFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(!message?.detail ? flashErrorMessage(FAILED_MESSAGE) : flashErrorMessage(message.detail));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }

}

export function* watchGetQuotePinList() {
  yield takeLatest(GET_QUOTE_PIN_LIST, getQuotePinList);
}

function* quotePinSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetQuotePinList),
    ]);

    yield take(RESET);
    yield cancel(tasks);
  }
}

export default quotePinSaga;