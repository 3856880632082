import { fork, put, takeLeading, call, take, cancel, all, cancelled } from 'redux-saga/effects';
import { getMethod, RESETED } from 'helpers';
import { RESET, FAILED_MESSAGE, GET_DMI_LIST } from 'constants/actionTypes';
import { flashErrorMessage } from 'redux-flash';
import {  getDMIListSuccess, getDMIListFailed } from './actions';

function* dmiListGet(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/dmi-tracking`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };
    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `?${paramString}`;
    }

    // request data
    const response = yield call(getMethod, request, abortController.signal);

    yield put(getDMIListSuccess(response));
  } catch (error) {
    yield put(getDMIListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* watchGetDMIList() {
  yield takeLeading(GET_DMI_LIST, dmiListGet);
}

function* dmiSaga() {
  while (true) {
    const tasks = yield all([fork(watchGetDMIList)]);

    yield all([take(RESET), take(RESETED(GET_DMI_LIST))]);

    yield cancel(tasks);
  }
}

export default dmiSaga;
