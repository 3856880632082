export const GET_DOCUMENT = 'GET_DOCUMENT';

export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';

export const RESET_DOCUMENT_LIST = 'RESET_DOCUMENT_LIST';

export const MANAGE_DOCUMENT = 'MANAGE_DOCUMENT';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const GET_DOCUMENT_LOG_LIST = 'GET_DOCUMENT_LOG_LIST';

export const RESET_DOCUMENT = 'RESET_DOCUMENT';

export const RESET_DOCUMENT_LOG_LIST = 'RESET_DOCUMENT_LOG_LIST';
