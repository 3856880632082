import { EXPORT_SERVER_SIDE } from 'constants/exportServerSide';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
};

const ExportServerSide = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPORT_SERVER_SIDE:
      return { ...state, loading: true };
    case SUCCESS(EXPORT_SERVER_SIDE):
      return { ...state, loading: false, success: true };
    case FAILED(EXPORT_SERVER_SIDE):
      return { ...state, loading: false, success: true, error: true };
    default:
      return state;
  }
};

export default ExportServerSide;
