import _ from 'lodash';
import { combineReducers } from 'redux';
import {
  GET_CLIENT_LIST,
  GET_CLIENT,
  MANAGE_CLIENT,
  DELETE_CLIENT,
  RESET_CLIENT,
  RESET,
  RESET_CLIENT_LIST,
} from 'constants/actionTypes';
import { createSelector } from 'reselect';
import { SUCCESS, FAILED, RESETED } from 'helpers';

const LIST_INIT_STATE = {
  loading: false,
  deletedClient: false,
  data: { data: [], meta: { pagination: { total: 0 } } },
};

const List = (state = LIST_INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_CLIENT_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_CLIENT_LIST):
      return { ...state, loading: false };
    case DELETE_CLIENT:
      return { ...state, loading: true };
    case SUCCESS(DELETE_CLIENT):
      return { ...state, loading: false, deletedClient: true };
    case FAILED(DELETE_CLIENT):
      return { ...state, loading: false, deletedClient: false };
    case RESETED(DELETE_CLIENT):
      return { ...state, deletedClient: false };
    case RESET:
    case RESET_CLIENT_LIST:
      return LIST_INIT_STATE;
    default:
      return state;
  }
};

const CLIENT_INIT_STATE = {
  loading: false,
  isSubmitting: false,
  success: false,
  errors: null,
  client: {
    data: {
      id: '',
      clientIdentifier: '',
      clientSecret: '',
      verifyClientSecret: '',
      redirectUri: '',
      baseUrl: '',
      certName: '',
      routes: {
        data: [],
      },
    },
  },
};

const Client = (state = CLIENT_INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT:
      return { ...state, loading: true };
    case SUCCESS(GET_CLIENT):
      return { ...state, client: _.defaultsDeep(action.payload, CLIENT_INIT_STATE.client), loading: false };
    case FAILED(GET_CLIENT):
      return { ...state, loading: false };
    case MANAGE_CLIENT:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_CLIENT):
      return {
        ...state,
        client: _.defaultsDeep(action.payload, CLIENT_INIT_STATE.client),
        errors: null,
        success: true,
        isSubmitting: false,
      };
    case FAILED(MANAGE_CLIENT):
      return { ...state, errors: action.payload, isSubmitting: false };
    case RESET_CLIENT:
    case RESET:
      return CLIENT_INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({ Client, List });

// selectors
const getClientClientFromState = state => state.Acl.Clients.Client.client;
export const getClientSelector = createSelector([getClientClientFromState], client => {
  return { ...client.data, routes: client.data.routes.data, clientSecret: '', verifyClientSecret: '' };
});
