import { GET_IDEON_LOG_LIST, RESET, RESET_IDEON_LOG_LIST }  from "constants/actionTypes";
import { SUCCESS, FAILED } from "helpers";
import { createSelector } from "reselect";

const INIT_STATE_LIST = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  success: false,
  errors: false,
}

const IdeonLogList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_IDEON_LOG_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_IDEON_LOG_LIST):
      return { ...state, data: action.payload, loading: false, success: true, errors: false };
    case FAILED(GET_IDEON_LOG_LIST):
      return { ...state, loading: false, success: false, errors: action.payload };
    case RESET:
    case RESET_IDEON_LOG_LIST:
      return INIT_STATE_LIST;
    default:
      return state;
  }
}

export default IdeonLogList;


const ideonLogListFromState = state => state.Log.IdeonLogs;

export const getIdeonLogListSelector = createSelector(
  [ideonLogListFromState], 
  ideonLog => ideonLog.data.data
);

export const getIdeonLogListMetaSelector = createSelector(
  [ideonLogListFromState], 
  ideonLog => ideonLog.data.meta
);

export const getIdeonLogListLoadingSelector = createSelector(
  [ideonLogListFromState], 
  ideonLog => ideonLog.loading
);