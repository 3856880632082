export const GET_BENEFIT_SECTION_LIST = 'GET_BENEFIT_SECTION_LIST';

export const RESET_BENEFIT_SECTION_LIST = 'RESET_BENEFIT_SECTION_LIST';

export const GET_BENEFIT_SECTION = 'GET_BENEFIT_SECTION';

export const MANAGE_BENEFIT_SECTION = 'MANAGE_BENEFIT_SECTION';

export const DELETE_BENEFIT_SECTION = 'DELETE_BENEFIT_SECTION';

export const RESET_BENEFIT_SECTION = 'RESET_BENEFIT_SECTION';
