import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

class ErrorBoundary extends React.Component {
  state = { hasError: false, isDisconnected: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'development') {
      /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
      console.log(error, errorInfo);
    }
    // Here we should call our api or something to send a mail
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch(process.env.REACT_APP_PUBLIC_API_ENDPOINT, {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 5000);

      return;
    }

    this.setState({ isDisconnected: true });
  };

  render() {
    const { hasError, isDisconnected } = this.state;
    const { children } = this.props;
    if (hasError || isDisconnected) {
      return (
        <>
          <div className="account-pages mt-5 mb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card bg-pattern">
                    <div className="card-body p-4">
                      <div className="text-center">
                        <Link to="/">
                          <span>
                            <img src="assets/images/logo-dark.png" alt="" height="22" />
                          </span>
                        </Link>
                      </div>
                      <div className="text-center mt-4">
                        <h1 className="text-error">{isDisconnected ? '503' : '500'}</h1>
                        <h3 className="mt-3 mb-2">
                          {isDisconnected ? 'Internet connection lost' : 'Application Error'}
                        </h3>
                        {isDisconnected ? (
                          <p className="text-muted mb-3">
                            Please check your internet connection, for any problems please contact support.
                          </p>
                        ) : (
                          <>
                            <p className="text-muted mb-3">
                              The error has been logged and sent to our development team to investigate, if the problem
                              persists please contact support.
                            </p>
                            <a href="/" className="btn btn-success waves-effect waves-light">
                              Back to Home
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer className="footer footer-alt" />
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
