import {
  GET_DOCUMENT,
  GET_DOCUMENT_LIST,
  RESET_DOCUMENT_LIST,
  MANAGE_DOCUMENT,
  DELETE_DOCUMENT,
  GET_DOCUMENT_LOG_LIST,
  RESET_DOCUMENT,
  RESET_DOCUMENT_LOG_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getDocument = documentId => ({
  type: GET_DOCUMENT,
  payload: { documentId },
});

export const getDocumentSuccess = document => ({
  type: SUCCESS(GET_DOCUMENT),
  payload: document,
});

export const getDocumentFailed = () => ({
  type: FAILED(GET_DOCUMENT),
});

export const getDocumentList = (data = {}) => ({
  type: GET_DOCUMENT_LIST,
  payload: data,
});

export const getDocumentListSuccess = data => ({
  type: SUCCESS(GET_DOCUMENT_LIST),
  payload: data,
});

export const getDocumentListFailed = error => ({
  type: FAILED(GET_DOCUMENT_LIST),
  payload: error,
});

export const resetDocumentList = () => ({
  type: RESET_DOCUMENT_LIST,
});

export const manageDocument = year => ({
  type: MANAGE_DOCUMENT,
  payload: year,
});

export const manageDocumentSuccess = data => ({
  type: SUCCESS(MANAGE_DOCUMENT),
  payload: data,
});

export const manageDocumentFailed = (error = null) => ({
  type: FAILED(MANAGE_DOCUMENT),
  payload: error,
});

export const resetDocument = () => ({
  type: RESET_DOCUMENT,
});

export const deleteDocument = data => ({
  type: DELETE_DOCUMENT,
  payload: data,
});

export const deleteDocumentSuccess = () => ({
  type: SUCCESS(DELETE_DOCUMENT),
});

export const deleteDocumentFailed = () => ({
  type: FAILED(DELETE_DOCUMENT),
});

export const deleteDocumentReset = () => ({
  type: RESETED(DELETE_DOCUMENT),
});

export const getDocumentLogList = (data = {}) => ({
  type: GET_DOCUMENT_LOG_LIST,
  payload: data,
});

export const getDocumentLogListSuccess = data => ({
  type: SUCCESS(GET_DOCUMENT_LOG_LIST),
  payload: data,
});

export const getDocumentLogListFailed = error => ({
  type: FAILED(GET_DOCUMENT_LOG_LIST),
  payload: error,
});

export const resetDocumentLogList = () => ({
  type: RESET_DOCUMENT_LOG_LIST,
});
