import {DELETE_ACTIVITY, GET_ACTIVITY_LIST, RESET} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } }
  },
};
const ActivityList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case DELETE_ACTIVITY:
    case GET_ACTIVITY_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_ACTIVITY_LIST):
      return { ...state, data: action.payload, loading: false };
    case SUCCESS(DELETE_ACTIVITY):
      return { ...state, loading: false };
    case FAILED(GET_ACTIVITY_LIST):
    case FAILED(DELETE_ACTIVITY):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

export default combineReducers({
  ActivityList,
});

/**
 * LIST SELECTORS
 */
const getActivityListFromState = state => state.Activity.ActivityList.data;
export const getActivityListSelector = createSelector([getActivityListFromState], activityList => activityList);

export const getActivityListSelectorLoadingSelector = createSelector(
  [state => state.Activity.ActivityList.loading],
  loading => loading
);

export const getActivityListSelectorMetaSelector = createSelector(
  [state => state.Activity.ActivityList.data.meta],
  meta => meta
);
