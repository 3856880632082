import _ from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  RESET,
  GET_AGENCY_POSSIBLE_PARENTS,
  RESET_AGENCY_POSSIBLE_PARENTS,
  GET_AGENCY_RESTRICTION_FORM_CONFIG,
  RESET_AGENCY_RESTRICTION,
  GET_AGENCY_RESTRICTION,
  MANAGE_AGENCY_RESTRICTION,
  MANAGE_AGENCY_SETTING,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  data: { data: [], meta: { pagination: { total: 0 } } },
  loading: false,
  deletedAgency: false,
  reactivatedAgency: false,
  approvedAgency: false,
};

const createListing = listName => {
  return (state = INIT_STATE, action) => {
    switch (action.type) {
      case `GET_${listName}_LIST`:
        return {
          ...state,
          success: false,
          loading: true,
          deletedAgency: false,
          reactivatedAgency: false,
          approvedAgency: false,
        };
      case `GET_${listName}_LIST_SUCCESS`:
        return { ...state, data: action.payload, error: false, loading: false };
      case `GET_${listName}_LIST_FAILED`:
        return { ...state, error: action.payload, loading: false };
      case `DELETE_${listName}`:
        return { ...state, loading: true };
      case `DELETE_${listName}_SUCCESS`:
        return { ...state, loading: false, deletedAgency: true };
      case `DELETE_${listName}_FAILED`:
        return { ...state, loading: false, deletedAgency: false };
      case `DELETE_${listName}_RESET`:
        return { ...state, loading: false, deletedAgency: false };
      case `REACTIVATE_${listName}`:
        return { ...state, loading: true };
      case `REACTIVATE_${listName}_SUCCESS`:
        return { ...state, loading: false, reactivatedAgency: true };
      case `REACTIVATE_${listName}_FAILED`:
        return { ...state, loading: false, reactivatedAgency: false };
      case `REACTIVATE_${listName}_RESET`:
        return { ...state, loading: false, reactivatedAgency: false };
      case `APPROVE_${listName}`:
        return { ...state, loading: true };
      case `APPROVE_${listName}_SUCCESS`:
        return { ...state, loading: false, approvedAgency: true };
      case `APPROVE_${listName}_FAILED`:
        return { ...state, loading: false, approvedAgency: false };
      case `APPROVE_${listName}_RESET`:
        return { ...state, loading: false, approvedAgency: false };
      case RESET:
      case `RESET_${listName}_LIST`:
        return INIT_STATE;
      default:
        return state;
    }
  };
};

const INIT_STATE_AGENCY = {
  agency: {
    data: {
      id: '',
      companyName: '',
      allowChildToEditRestrictions: 1,
      allowProducerToEditRestrictions: 1,
      allowReferrals: 1,
      perCredit: 1,
      subdomain: '',
      contactProducer: 1,
      hasTwoFactorAuthentication: '',
      customDisclaimer: '',
      gAnalytics: '',
      gtmAnalytics: '',
      partnerId: '',
      password: '',
      clientId: '',
      clientSecret: '',
      logo: '',
      advancedDisclaimerEn: '',
      advancedDisclaimerEs: '',
      isActiveLeadForm: false,
      useSalesforceLeadCapture: 0,
      isStickyHeaderHidden: false,
      isFooterHidden: false,
      isQuotingUIHidden: false,
      saveCMSQuotingRequest: false,
      saveOffExchangeQuoting: false,
      allowQuotePinEntry: false,
      disableAgentDashboard: false,
      issuerName: '',
      salesforce: {
        data: {
          clientId: '',
          clientSecret: '',
          token: '',
          username: '',
          password: '',
          url: '',
          leadVendorId: '',
        },
      },
      okta: {
        data: {
          clientId: '',
          clientSecret: '',
          endpoint: '',
        },
      },
      parent: {
        data: {
          id: '',
          companyName: '',
        },
      },
      allChildren: {
        data: [],
      },
      address: {
        data: {
          id: '',
          addressType: 'Address',
          addressLine1: '',
          addressLine2: '',
          city: '',
          countyFips: '',
          zipCode: '',
          state: '',
        },
      },
      contacts: {
        data: [
          { id: '', type: 'Email', value: '' },
          { id: '', type: 'Phone', value: '' },
        ],
      },
      defaultProducer: {
        data: {
          id: '',
          isDeleted: '0',
          isDefaultProducer: '1',
          person: {
            data: {
              id: '',
              lastName: '',
              firstName: '',
              middleName: '',
              gender: '',
              birthDate: '',
              addresses: {
                data: [
                  {
                    id: '',
                    addressType: 'Address',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    countyFips: '',
                    zipCode: '',
                    state: '',
                  },
                ],
              },
              contacts: {
                data: [
                  { id: '', type: 'Email', value: '' },
                  { id: '', type: 'Phone', value: '' },
                  { id: '', type: 'Secondary Phone', value: '' },
                ],
              },
            },
          },
          user: {
            data: {
              id: '',
              email: '',
              password: '',
              verifyPassword: '',
            },
          },
          agentNumbers: {
            data: [
              { id: '', type: 'NPN', value: '' },
              { id: '', type: 'FFMUserId', value: '' },
              { id: '', type: 'IHCAgentNumber', value: '' },
              { id: '', type: 'SSOId', value: '' },
            ],
          },
        },
      },
      producerRestrictedIssuers: {
        data: [],
      },
      producerApis: {
        data: [
          {
            id: '',
            isActive: false,
            api: {
              data: {
                id: '',
                apiName: '',
                isDeleted: true,
              },
            },
            producerApiLineOfCoverages: {
              data: [
                {
                  id: '',
                  isActive: false,
                  apiLineOfCoverage: {
                    data: {
                      id: '',
                      machineName: '',
                      isDeleted: true,
                      lineOfCoverage: {
                        data: [
                          {
                            id: '',
                            name: '',
                            displayName: '',
                            displayOrder: 0,
                            isDeleted: true,
                          },
                        ],
                      },
                    },
                  },
                },
              ],
            },
            producerApiHiddenPlans: {
              data: [],
            },
          },
        ],
      },
    },
  },
  success: false,
  errors: null,
  loading: false,
  isSubmitting: false,
  needsRedirect: false,
};

const createSingular = singular => {
  return (state = INIT_STATE_AGENCY, action) => {
    switch (action.type) {
      case GET_AGENCY_RESTRICTION:
      case `GET_${singular}`:
      case `GET_AGENCY_SETTING`:
        return { ...state, success: false, loading: true, needsRedirect: false };
      case `GET_${singular}_SUCCESS`:
      case `GET_AGENCY_SETTING_SUCCESS`:
        return {
          ...state,
          agency: _.defaultsDeep(action.payload, INIT_STATE_AGENCY.agency),
          loading: false,
          needsRedirect: false,
        };
      case `GET_${singular}_FAILED`:
      case `GET_AGENCY_SETTING_SUCCESS_FAILED`:
        return { ...state, loading: false, needsRedirect: true };
      case MANAGE_AGENCY_RESTRICTION:
      case `MANAGE_${singular}`:
      case `MANAGE_AGENCY_SETTING`:
        return { ...state, errors: null, isSubmitting: true };
      case `MANAGE_${singular}_SUCCESS`:
        return {
          ...state,
          agency: _.defaultsDeep(action.payload, INIT_STATE_AGENCY.agency),
          success: true,
          errors: null,
          isSubmitting: false,
        };
      case `MANAGE_AGENCY_SETTING_SUCCESS`:
        return {
          ...state,
          success: true,
          errors: null,
          isSubmitting: false,
        };
      case `MANAGE_${singular}_FAILED`:
      case `MANAGE_AGENCY_SETTING_FAILED`:
        return { ...state, errors: action.payload, isSubmitting: false };
      case `RESET_${singular}`:
        return INIT_STATE_AGENCY;
      case RESET_AGENCY_RESTRICTION:
        return { ...state, success: false };
      case RESET:
        return INIT_STATE_AGENCY;
      default:
        return state;
    }
  };
};

const INIT_STATE_POSSIBLE_PARENTS = {
  data: {
    data: [],
  },
  loading: false,
};

const PossibleParents = (state = INIT_STATE_POSSIBLE_PARENTS, action) => {
  switch (action.type) {
    case GET_AGENCY_POSSIBLE_PARENTS:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_AGENCY_POSSIBLE_PARENTS):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_AGENCY_POSSIBLE_PARENTS):
      return { ...state, loading: false };
    case RESET_AGENCY_POSSIBLE_PARENTS:
    case RESET:
      return { ...INIT_STATE_POSSIBLE_PARENTS };
    default:
      return state;
  }
};

const INIT_STATE_AGENCY_RESTRICTION_FORM_CONFIG = {
  loading: false,
  error: false,
  success: false,
  api: { apis: [], issuers: [] },
};

const AgencyRestrictionFormConfig = (state = INIT_STATE_AGENCY_RESTRICTION_FORM_CONFIG, action) => {
  switch (action.type) {
    case GET_AGENCY_RESTRICTION_FORM_CONFIG:
      return { ...state, loading: true, error: false, success: false };
    case SUCCESS(GET_AGENCY_RESTRICTION_FORM_CONFIG):
      return {
        ...state,
        api: action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case FAILED(GET_AGENCY_RESTRICTION_FORM_CONFIG):
      return { ...state, loading: false, error: true };
    case RESET_AGENCY_RESTRICTION:
      return { ...state, success: false };
    case RESET:
      return INIT_STATE_AGENCY_RESTRICTION_FORM_CONFIG;
    default:
      return state;
  }
};

export default combineReducers({
  List: createListing('AGENCY'),
  Agency: createSingular('AGENCY'),
  ChildList: createListing('CHILD_AGENCY'),
  ChildAgency: createSingular('CHILD_AGENCY'),
  PossibleParents,
  AgencyRestrictionFormConfig,
});

// selectors
const processAgency = agency => {
  return {
    ...agency.data,
    parent: agency.data.parent.data.id,
    address: { ...agency.data.address.data },
    contacts: agency.data.contacts.data,
    defaultProducer: {
      ...agency.data.defaultProducer.data,
      isDeleted: agency.data.defaultProducer.data.isDeleted.toString(),
      person: {
        ...agency.data.defaultProducer.data.person.data,
        addresses: agency.data.defaultProducer.data.person.data.addresses.data,
        contacts: agency.data.defaultProducer.data.person.data.contacts.data,
      },
      user: { ...agency.data.defaultProducer.data.user.data, password: '', verifyPassword: '' },
      agentNumbers: agency.data.defaultProducer.data.agentNumbers.data,
    },
    salesforce: { ...agency.data.salesforce.data },
    okta: { ...agency.data.okta.data },
  };
};

const getAgencyAgencyFromState = state => state.Broker.Agencies.Agency.agency;
export const getAgencySelector = createSelector([getAgencyAgencyFromState], agency => {
  return processAgency(agency);
});

const getAgencyChildAgencyFromState = state => state.Broker.Agencies.ChildAgency.agency;
export const getAgencyChildSelector = createSelector([getAgencyChildAgencyFromState], agency => {
  return processAgency(agency);
});

const getAgencyPossibleParentsDataFromState = state => state.Broker.Agencies.PossibleParents.data;
const getAgencyPossibleParentsLoadingFromState = state => state.Broker.Agencies.PossibleParents.loading;
export const getAgencyPossibleParentsSelector = createSelector(
  [getAgencyPossibleParentsDataFromState, getAgencyPossibleParentsLoadingFromState],
  (data, loading) => {
    return {
      ...data,
      loading,
    };
  }
);

const getAgencyNameAgency = state => state.Broker.Agencies.Agency.agency;
export const getAgencyNameSelector = createSelector([getAgencyNameAgency], agency => {
  let agencyName = agency.data.companyName ?? '';
  if (agency.data.companyName === null) {
    agencyName = agency.data.defaultProducer.data.person.data.fullName;
  }

  return agencyName;
});

const getChildAgencyNameAgencyFromState = state => state.Broker.Agencies.ChildAgency.agency;
export const getChildAgencyNameSelector = createSelector([getChildAgencyNameAgencyFromState], agency => {
  return agency.data.companyName;
});

const getAgencyRestrictionFormConfigFromState = state => state.Broker.Agencies.AgencyRestrictionFormConfig;
export const getAgencyRestrictionFormConfigSelector = createSelector(
  [getAgencyRestrictionFormConfigFromState],
  formConfig => {
    return {
      ...formConfig,
    };
  }
);

export const getAgencyRestrictionsSelector = createSelector([getAgencyAgencyFromState], (producer) => {
  return {
    ...producer.data,
    producerRestrictedIssuers: producer.data.producerRestrictedIssuers.data.map((producerRestrictedIssuer) => {
      return { ...producerRestrictedIssuer, issuer: producerRestrictedIssuer.issuer.data.id };
    }),

    producerApis: producer.data.producerApis.data.map((producerApi) => {
      return {
        ...producerApi,
        api: producerApi.api.data.id,
        producerApiLineOfCoverages: producerApi.producerApiLineOfCoverages.data.map((producerApiLineOfCoverage) => {
          return {
            ...producerApiLineOfCoverage,
            apiLineOfCoverage: producerApiLineOfCoverage.apiLineOfCoverage.data.id,
          };
        }),
        producerApiHiddenPlans: producerApi.producerApiHiddenPlans.data,
      };
    }),
  };
});

export const getAgencyRestrictionsOrdered = createSelector(
  [getAgencyRestrictionsSelector, getAgencyRestrictionFormConfigFromState],
  (producerRestrictions, producerRestrictionsFormConfig) => {
    // process apis
    const producerApis = producerRestrictionsFormConfig.api.apis.map((api) => {
      const producerApi = producerRestrictions.producerApis.find((element) => element.api === api.id);

      if (producerApi) {
        return {
          ...producerApi,
          apiName: api.apiName,
          producerApiLineOfCoverages: api.linesOfCoverage.map((lineOfCoverage) => {
            const apiLineOfCoverage = producerApi.producerApiLineOfCoverages.find(
              (producerApiLineOfCoverage) => lineOfCoverage.id === producerApiLineOfCoverage.apiLineOfCoverage
            );

            if (apiLineOfCoverage) {
              return {
                ...apiLineOfCoverage,
                lineOfCoverageName: lineOfCoverage.name,
              };
            }

            return {
              apiLineOfCoverage: lineOfCoverage.id,
              id: '',
              isActive: false,
              lineOfCoverageName: lineOfCoverage.name,
            };
          }),
        };
      }

      return {
        id: '',
        api: api.id,
        apiName: api.apiName,
        isActive: false,
        producerApiHiddenPlans: [],
        producerApiLineOfCoverages: api.linesOfCoverage.map((lineOfCoverage) => {
          return {
            apiLineOfCoverage: lineOfCoverage.id,
            id: '',
            isActive: false,
            lineOfCoverageName: lineOfCoverage.name,
          };
        }),
      };
    });

    // process restricted issuers
    const producerRestrictedIssuers = producerRestrictionsFormConfig.api.issuers.map((issuer, index) => {
      const producerHiddenIssuer = producerRestrictions.producerRestrictedIssuers.find(
        (element) => element.issuer === issuer.id
      );

      if (producerHiddenIssuer) {
        return {
          uniqueKey: index,
          id: producerHiddenIssuer.id,
          issuer: issuer.id,
          isHidden: true,
          name: issuer.name,
          year: issuer.year,
          state: issuer.state,
        };
      }

      return {
        uniqueKey: index,
        id: '',
        issuer: issuer.id,
        isHidden: false,
        name: issuer.name,
        year: issuer.year,
        state: issuer.state,
      };
    });

    return { ...producerRestrictions, producerApis, producerRestrictedIssuers };
  }
);

const getAgenciesListFromState = state => state.Broker.Agencies.List;
export const getAgencyListSelector = createSelector([getAgenciesListFromState], agencies => {
  return agencies.data;
});

export const getProducerListMetaSelector = createSelector(
    [state => state.Broker.Producers.List.data.meta],
    meta => meta
);
