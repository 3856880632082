import { all, call, fork, put, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import {
  GET_BENEFIT_SECTION_LIST,
  DELETE_BENEFIT_SECTION,
  GET_BENEFIT_SECTION,
  MANAGE_BENEFIT_SECTION,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import { getMethod, updateMethod, resolveErrorPromise, createMethod, deleteMethod } from 'helpers';
import {
  getBenefitSectionListSuccess,
  getBenefitSectionListFailed,
  manageBenefitSectionSuccess,
  manageBenefitSectionFailed,
  getBenefitSectionSuccess,
  getBenefitSectionFailed,
  deleteBenefitSectionSuccess,
  deleteBenefitSectionFailed,
} from './actions';

function* benefitSectionGetList() {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/benefit-section`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getBenefitSectionListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getBenefitSectionListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* benefitSectionGet(action) {
  const abortController = new AbortController();
  try {
    const data = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/benefit-section/${action.payload}`,
      abortController.signal
    );
    yield put(getBenefitSectionSuccess(data.data));
  } catch (error) {
    yield put(getBenefitSectionFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* benefitSectionManage(action) {
  try {
    if (action.payload.id === '') {
      yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/benefit-section`,
        JSON.stringify(action.payload)
      );
    } else {
      yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/benefit-section/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageBenefitSectionSuccess());
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manageBenefitSectionFailed(message));
    } else {
      yield put(manageBenefitSectionFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* benefitSectionDelete(action) {
  try {
    yield call(deleteMethod, `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/benefit-section/${action.payload}`);

    yield put(deleteBenefitSectionSuccess());
  } catch (error) {
    yield put(deleteBenefitSectionFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchBenefitSectionGetList() {
  yield takeLatest(GET_BENEFIT_SECTION_LIST, benefitSectionGetList);
}

export function* watchBenefitSectionGet() {
  yield takeLatest(GET_BENEFIT_SECTION, benefitSectionGet);
}

export function* watchBenefitSectionManage() {
  yield takeLatest(MANAGE_BENEFIT_SECTION, benefitSectionManage);
}

export function* watchBenefitSectionDelete() {
  yield takeLatest(DELETE_BENEFIT_SECTION, benefitSectionDelete);
}

function* benefitSectionSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchBenefitSectionGetList),
      fork(watchBenefitSectionGet),
      fork(watchBenefitSectionManage),
      fork(watchBenefitSectionDelete),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default benefitSectionSaga;
