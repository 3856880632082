import { GET_SUBMISSION_ISSUES_LIST, VIEW_SUBMISSION_ISSUE, MANAGE_SUBMISSION_ISSUE, RESET_MANAGE_SUBMISSION_ISSUE } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const viewSubmissionIssue = quoteId => ({
    type: VIEW_SUBMISSION_ISSUE,
    payload: quoteId,
});

export const viewSubmissionIssueSuccess = data => ({
    type: SUCCESS(VIEW_SUBMISSION_ISSUE),
    payload: data,
});

export const viewSubmissionIssueFailed = data => ({
    type: FAILED(VIEW_SUBMISSION_ISSUE),
    payload: data,
});

export const manageSubmissionIssue = data => ({
    type: MANAGE_SUBMISSION_ISSUE,
    payload: data,
});

export const manageSubmissionIssueSuccess = data => ({
    type: SUCCESS(MANAGE_SUBMISSION_ISSUE),
    payload: data,
});

export const manageSubmissionIssueFailed = (error = null) => ({
    type: FAILED(MANAGE_SUBMISSION_ISSUE),
    payload: error,
});

export const resetManageSubmissionIssue = () => ({
    type: RESETED(MANAGE_SUBMISSION_ISSUE),
});

export const getSubmissionIssuesList = data => ({
    type: GET_SUBMISSION_ISSUES_LIST,
    payload: data,
});

export const getSubmissionIssuesListSuccess = data => ({
    type: SUCCESS(GET_SUBMISSION_ISSUES_LIST),
    payload: data,
});

export const getSubmissionIssuesListFailed = data => ({
    type: FAILED(GET_SUBMISSION_ISSUES_LIST),
    payload: data,
});
