export const GET_LINE_OF_COVERAGE_LIST = 'GET_LINE_OF_COVERAGE_LIST';

export const RESET_LINE_OF_COVERAGE_LIST = 'RESET_LINE_OF_COVERAGE_LIST';

export const DELETE_LINE_OF_COVERAGE = 'DELETE_LINE_OF_COVERAGE';

export const REACTIVATE_LINE_OF_COVERAGE = 'REACTIVATE_LINE_OF_COVERAGE';

export const GET_LINE_OF_COVERAGE = 'GET_LINE_OF_COVERAGE';

export const RESET_LINE_OF_COVERAGE = 'RESET_LINE_OF_COVERAGE';

export const MANAGE_LINE_OF_COVERAGE = 'MANAGE_LINE_OF_COVERAGE';
