import { GET_SUBMISSION_ISSUES_LIST, VIEW_SUBMISSION_ISSUE, MANAGE_SUBMISSION_ISSUE, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
  isSubmitting: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const SubmissionIssuesList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBMISSION_ISSUES_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_SUBMISSION_ISSUES_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_SUBMISSION_ISSUES_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

const SubmissionIssueView = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIEW_SUBMISSION_ISSUE:
      return { ...state, loading: true };
    case SUCCESS(VIEW_SUBMISSION_ISSUE):
      return { ...state, data: action.payload, loading: false };
    case FAILED(VIEW_SUBMISSION_ISSUE):
      return { ...state, loading: false, error: true };
    case MANAGE_SUBMISSION_ISSUE:
      return { ...state, isSubmitting: true, errors: null };
    case SUCCESS(MANAGE_SUBMISSION_ISSUE):
      return {
        ...state,
        submissionIssue: _.defaultsDeep(action.payload, INIT_STATE.submissionIssue),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_SUBMISSION_ISSUE):
      return { ...state, isSubmitting: false, success: false, errors: action.payload };
    case RESETED(VIEW_SUBMISSION_ISSUE):
    case RESETED(MANAGE_SUBMISSION_ISSUE):
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  SubmissionIssuesList,
  SubmissionIssueView,
});


const getSubmissionIssuesListFromState = state => state.Quote.SubmissionIssuesList;
export const getSubmissionIssuesListSelector = createSelector([getSubmissionIssuesListFromState], submissionIssuesList => {
  return {
    ...submissionIssuesList.data,
  };
});

export const getSubmissionIssuesListLoadingSelector = createSelector(
  [state => state.Quote.SubmissionIssuesList.loading],
  loading => loading
);

export const getSubmissionIssuesListMetaSelector = createSelector(
  [state => state.Quote.SubmissionIssuesList.data.meta],
  meta => meta
);

const getSubmissionIssueFromState = state => state.Quote.SubmissionIssueView;
export const getSubmissionIssueSelector = createSelector([getSubmissionIssueFromState], submissionIssue => {
  return {
    ...submissionIssue.data,
  };
});

export const getSubmissionIssueLoadingSelector = createSelector(
  state => state.Quote.SubmissionIssueView.loading,
  loading => loading
);
