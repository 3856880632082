import { SUCCESS, FAILED, RESETED } from 'helpers';
import {
  GET_CLIENT_LIST,
  GET_CLIENT,
  MANAGE_CLIENT,
  DELETE_CLIENT,
  RESET_CLIENT,
  RESET_CLIENT_LIST,
} from 'constants/actionTypes';

export const getClientList = () => ({
  type: GET_CLIENT_LIST,
});

export const getClientListSuccess = clients => ({
  type: SUCCESS(GET_CLIENT_LIST),
  payload: clients,
});

export const getClientListFailed = () => ({
  type: FAILED(GET_CLIENT_LIST),
});

export const resetClientList = () => ({
  type: RESET_CLIENT_LIST,
});

export const getClient = clientId => ({
  type: GET_CLIENT,
  payload: { clientId },
});

export const getClientSuccess = client => ({
  type: SUCCESS(GET_CLIENT),
  payload: client,
});

export const getClientFailed = () => ({
  type: FAILED(GET_CLIENT),
});

export const manageClient = client => ({
  type: MANAGE_CLIENT,
  payload: client,
});

export const manageClientSuccess = client => ({
  type: SUCCESS(MANAGE_CLIENT),
  payload: client,
});

export const manageClientFailed = (error = null) => ({
  type: FAILED(MANAGE_CLIENT),
  payload: error,
});

export const deleteClient = clientId => ({
  type: DELETE_CLIENT,
  payload: { clientId },
});

export const deleteClientSuccess = () => ({
  type: SUCCESS(DELETE_CLIENT),
});

export const deleteClientFailed = () => ({
  type: FAILED(DELETE_CLIENT),
});

export const deleteClientReset = () => ({
  type: RESETED(DELETE_CLIENT),
});

export const resetClient = () => ({
  type: RESET_CLIENT,
});
