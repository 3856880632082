import {
  GET_ROLE_LIST,
  DELETE_ROLE,
  MANAGE_ROLE,
  GET_ROLE,
  RESET_ROLE,
  RESET_ROLE_LIST,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

export const getRoleList = () => ({
  type: GET_ROLE_LIST,
});

export const getRoleListSuccess = roles => ({
  type: SUCCESS(GET_ROLE_LIST),
  payload: roles,
});

export const getRoleListFailed = error => ({
  type: FAILED(GET_ROLE_LIST),
  payload: error,
});

export const resetRoleList = () => ({
  type: RESET_ROLE_LIST,
});

export const deleteRole = roleId => ({
  type: DELETE_ROLE,
  payload: { roleId },
});

export const deleteRoleFailed = error => ({
  type: FAILED(DELETE_ROLE),
  payload: error,
});

export const deleteRoleSuccess = () => ({
  type: SUCCESS(DELETE_ROLE),
});

export const deleteRoleReset = () => ({
  type: RESETED(DELETE_ROLE),
});

export const getRole = roleId => ({
  type: GET_ROLE,
  payload: { roleId },
});

export const getRoleSuccess = role => ({
  type: SUCCESS(GET_ROLE),
  payload: role,
});

export const getRoleFailed = error => ({
  type: FAILED(GET_ROLE),
  payload: error,
});

export const resetRole = () => ({
  type: RESET_ROLE,
});

export const manageRole = role => ({
  type: MANAGE_ROLE,
  payload: role,
});

export const manageRoleSuccess = role => ({
  type: SUCCESS(MANAGE_ROLE),
  payload: role,
});

export const manageRoleFailed = error => ({
  type: FAILED(MANAGE_ROLE),
  payload: error,
});
