import { EXPORT_IDEON_LOGS, GET_IDEON_LOG_LIST, RESET_IDEON_LOG_LIST } from "constants/actionTypes";
import { FAILED, SUCCESS } from "helpers";

export const getIdeonLogList = (payload) => ({
  type: GET_IDEON_LOG_LIST,
  payload: payload,
});

export const getIdeonLogListSuccess = data => ({
  type: SUCCESS(GET_IDEON_LOG_LIST),
  payload: data,
});

export const getIdeonLogListFailed = () => ({
  type: FAILED(GET_IDEON_LOG_LIST),
});

export const resetIdeonLogList = () => ({
  type: RESET_IDEON_LOG_LIST,
});

export const exportIdeonLogs = (data = {}) => ({
  type: EXPORT_IDEON_LOGS,
  payload: data,
});
