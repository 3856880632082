import { all, call, fork, put, takeLeading, cancelled, take, cancel, takeLatest } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod, updateMethod, resolveErrorPromise, deleteMethod, createMethod } from 'helpers';
import {
  GET_ZIP_CODE,
  GET_ZIP_CODE_LIST,
  MANAGE_ZIP_CODE,
  DELETE_ZIP_CODE,
  FAILED_MESSAGE,
  RESET,
} from 'constants/actionTypes';
import {
  getZipCodeListSuccess,
  getZipCodeListFailed,
  getZipCodeSuccess,
  getZipCodeFailed,
  manageZipCodeSuccess,
  manageZipCodeFailed,
  deleteZipCodeSuccess,
  deleteZipCodeFailed,
} from 'redux/actions';

function* zipCodeGetList(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/zip-code`;
    let params = {};
    params = {
      ...params,
      ...action.payload.filters,
      ...action.payload.pagination,
      ...action.payload.order,
    }
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `?${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getZipCodeListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getZipCodeListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* zipCodeGet(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/zip-code/${action.payload}`;
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getZipCodeSuccess(response));
  } catch (error) {
    yield put(getZipCodeFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* zipCodeDelete(action) {
  try {
    yield call(
      deleteMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/zip-code/${action.payload.id}`,
      // JSON.stringify({ isDeleting: action.payload.isDeleting })
    );
    yield put(deleteZipCodeSuccess());
  } catch (error) {
    yield put(deleteZipCodeFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* zipCodeManage(action) {
  try {
    let response;
    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/zip-code/${action.payload.id}`,
        JSON.stringify(action.payload)
      );
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/zip-code`,
        JSON.stringify(action.payload)
      );
    }
    yield put(manageZipCodeSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageZipCodeFailed(message.detail.formErrorMessages));
    } else {
      yield put(manageZipCodeFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

export function* watchGetZipCodeList() {
  yield takeLatest(GET_ZIP_CODE_LIST, zipCodeGetList);
}

export function* watchGetZipCode() {
  yield takeLatest(GET_ZIP_CODE, zipCodeGet);
}

export function* watchDeleteZipCode() {
  yield takeLeading(DELETE_ZIP_CODE, zipCodeDelete);
}

export function* watchManageZipCode() {
  yield takeLeading(MANAGE_ZIP_CODE, zipCodeManage);
}

function* zipCodeSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetZipCodeList),
      fork(watchGetZipCode),
      fork(watchDeleteZipCode),
      fork(watchManageZipCode),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default zipCodeSaga;
