export const GET_GLOBAL_ALERT_LIST = 'GET_GLOBAL_ALERT_LIST';

export const RESET_GLOBAL_ALERT_LIST = 'RESET_GLOBAL_ALERT_LIST';

export const DELETE_GLOBAL_ALERT = 'DELETE_GLOBAL_ALERT';

export const GET_GLOBAL_ALERT = 'GET_GLOBAL_ALERT';

export const RESET_GLOBAL_ALERT = 'RESET_GLOBAL_ALERT';

export const MANAGE_GLOBAL_ALERT = 'MANAGE_GLOBAL_ALERT';
