import { GET_REPORTING_LIST, GET_REPORTING_FILTER, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const ReportingList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_REPORTING_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_REPORTING_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_REPORTING_LIST):
      return { ...state, loading: false };
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

const INIT_STATE_FILTER = {
  loading: false,
  success: false,
  error: false,
  data: {
    issuers: [],
    agencies: [],
    linesOfCoverages: [],
    states: [],
    producers: [],
    submissionSource: [],
    policyStatus: [],
    startDate: null,
    endDate: null,
  },
};

const ReportingFilter = (state = INIT_STATE_FILTER, action) => {
  switch (action.type) {
    case GET_REPORTING_FILTER:
      return { ...state, loading: true, success: false };
    case SUCCESS(GET_REPORTING_FILTER):
      return { ...state, data: action.payload, loading: false, success: true };
    case FAILED(GET_REPORTING_FILTER):
      return { ...state, loading: false, error: true, success: false };
    case RESET:
      return INIT_STATE_FILTER;
    default:
      return state;
  }
};

export default combineReducers({
  ReportingList,
  ReportingFilter,
});

/**
 * LIST SELECTORS
 */
const getReportingListFromState = (state) => state.Reporting.ReportingList.data;
export const getReportingListSelector = createSelector([getReportingListFromState], (reportingList) => reportingList);

export const getReportingListSelectorLoadingSelector = createSelector(
  [(state) => state.Reporting.ReportingList.loading],
  (loading) => loading
);

export const getReportingListSelectorMetaSelector = createSelector(
  [(state) => state.Reporting.ReportingList.data.meta],
  (meta) => meta
);

/**
 * FILTER SELECTORS
 */
const getReportingFilterFromState = (state) => state.Reporting.ReportingFilter.data;
export const getReportingFilterSelector = createSelector(
  [getReportingFilterFromState],
  (reportingFilter) => reportingFilter
);

export const getReportingFilterSelectorLoadingSelector = createSelector(
  [(state) => state.Reporting.ReportingFilter.loading],
  (loading) => loading
);

export const getReportingFilterSelectorSuccessSelector = createSelector(
  [(state) => state.Reporting.ReportingFilter.success],
  (success) => success
);
