import { GET_ROUTE_LIST } from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';

export const getRouteList = (clientId = 2) => ({
  type: GET_ROUTE_LIST,
  payload: {clientId},
});

export const getRouteListSuccess = routes => ({
  type: SUCCESS(GET_ROUTE_LIST),
  payload: routes,
});

export const getRouteListFailed = error => ({
  type: FAILED(GET_ROUTE_LIST),
  payload: error,
});
