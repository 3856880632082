import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import {
  GET_GLOBAL_ALERT_LIST,
  DELETE_GLOBAL_ALERT,
  GET_GLOBAL_ALERT,
  MANAGE_GLOBAL_ALERT,
  RESET_GLOBAL_ALERT,
  RESET,
  RESET_GLOBAL_ALERT_LIST,
} from 'constants/actionTypes';

const INIT_STATE = {
  data: {
    data: [],
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GLOBAL_ALERT_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_GLOBAL_ALERT_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_GLOBAL_ALERT_LIST):
      return { ...state, loading: false };
    case DELETE_GLOBAL_ALERT:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_GLOBAL_ALERT):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_GLOBAL_ALERT):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_GLOBAL_ALERT):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET:
    case RESET_GLOBAL_ALERT_LIST:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_GLOBAL_ALERT = {
  globalAlert: {
    data: {
      id: '',
      title: '',
      message: '',
      style: '',
      roles: '',
      includedSubdomains: '',
      excludedSubdomains: '',
      startDate: '',
      endDate: '',
      routes: [],
    },
  },
  success: false,
  error: false,
  isSubmitting: false,
  errors: null,
};

const GlobalAlert = (state = INIT_STATE_GLOBAL_ALERT, action) => {
  switch (action.type) {
    case GET_GLOBAL_ALERT:
      return { ...state, success: false, loading: true };
    case SUCCESS(GET_GLOBAL_ALERT):
      return {
        ...state,
        globalAlert: _.defaultsDeep(action.payload, INIT_STATE_GLOBAL_ALERT.globalAlert),
        loading: false,
        error: false,
      };
    case FAILED(GET_GLOBAL_ALERT):
      return { ...state, loading: false, error: true };
    case MANAGE_GLOBAL_ALERT:
      return { ...state, errors: null, isSubmitting: true };
    case SUCCESS(MANAGE_GLOBAL_ALERT):
      return {
        ...state,
        globalAlert: _.defaultsDeep(action.payload, INIT_STATE_GLOBAL_ALERT.globalAlert),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_GLOBAL_ALERT):
      return { ...state, errors: action.payload, isSubmitting: false, success: false };
    case RESET_GLOBAL_ALERT:
    case RESET:
      return INIT_STATE_GLOBAL_ALERT;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  GlobalAlert,
});

const getGlobalAlertGlobalAlertFromState = (state) => state.Auth.GlobalAlert.GlobalAlert.globalAlert;
export const getGlobalAlertSelector = createSelector([getGlobalAlertGlobalAlertFromState], (globalAlert) => {
  return {
    ...globalAlert.data,
  };
});
