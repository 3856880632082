import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  GET_DOCUMENT,
  GET_DOCUMENT_LIST,
  RESET_DOCUMENT_LIST,
  MANAGE_DOCUMENT,
  DELETE_DOCUMENT,
  RESET_DOCUMENT,
  GET_DOCUMENT_LOG_LIST,
  RESET_DOCUMENT_LOG_LIST,
  RESET,
} from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';

const INIT_STATE = {
  data: {
    data: [],
    meta: {
      pagination: {
        total: 0,
      },
    },
  },
  loading: false,
  isDeleting: false,
  deletedSuccess: false,
  deletedFailed: false,
};

const List = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_DOCUMENT_LIST):
      return { ...state, data: action.payload, source: action.source, loading: false };
    case FAILED(GET_DOCUMENT_LIST):
      return { ...state, loading: false };
    case DELETE_DOCUMENT:
      return { ...state, isDeleting: true };
    case SUCCESS(DELETE_DOCUMENT):
      return { ...state, isDeleting: false, deletedSuccess: true };
    case FAILED(DELETE_DOCUMENT):
      return { ...state, isDeleting: false, deletedFailed: true };
    case RESETED(DELETE_DOCUMENT):
      return { ...state, isDeleting: false, deletedSuccess: false, deletedFailed: false };
    case RESET_DOCUMENT_LIST:
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

const DocumentLogList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LOG_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_DOCUMENT_LOG_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_DOCUMENT_LOG_LIST):
      return { ...state, loading: false };
    case RESET_DOCUMENT_LOG_LIST:
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

const INIT_STATE_DOCUMENT = {
  document: {
    data: {
      id: '',
      name: '',
      path: '',
      signature: '',
    },
  },
  error: false,
  success: false,
  errors: null,
  isSubmitting: false,
};

const Document = (state = INIT_STATE_DOCUMENT, action) => {
  switch (action.type) {
    case GET_DOCUMENT:
      return { ...state, loading: true };
    case SUCCESS(GET_DOCUMENT):
      return {
        ...state,
        document: _.defaultsDeep(action.payload, INIT_STATE_DOCUMENT.document),
        loading: false,
        error: false,
      };
    case FAILED(GET_DOCUMENT):
      return { ...state, loading: false, error: true };
    case MANAGE_DOCUMENT:
      return { ...state, isSubmitting: true, errors: null };
    case SUCCESS(MANAGE_DOCUMENT):
      return {
        ...state,
        document: _.defaultsDeep(action.payload, INIT_STATE_DOCUMENT.document),
        isSubmitting: false,
        success: true,
        errors: null,
      };
    case FAILED(MANAGE_DOCUMENT):
      return { ...state, isSubmitting: false, success: false, errors: action.payload };
    case RESET_DOCUMENT:
    case RESET:
      return INIT_STATE_DOCUMENT;
    default:
      return state;
  }
};

export default combineReducers({
  List,
  Document,
  DocumentLogList,
});

// selectors
const getDocumentDocumentFromState = state => state.Broker.Baa.Document.document;
export const getDocumentSelector = createSelector([getDocumentDocumentFromState], document => {
  return {
    ...document.data,
  };
});
