export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const RESET_ROLE_LIST = 'RESET_ROLE_LIST';

export const DELETE_ROLE = 'DELETE_ROLE';

export const RESET_ROLE = 'RESET_ROLE';
export const GET_ROLE = 'GET_ROLE';

export const MANAGE_ROLE = 'MANAGE_ROLE';

export const GET_ROUTE_LIST = 'GET_ROUTE_LIST';
