import {
  DELETE_APPLICATION,
  GET_APPLICATION_LIST,
  REACTIVATE_APPLICATION,
  VIEW_APPLICATION,
  VIEW_OFF_EX_APPLICATION,
  GET_OFF_EX_APPLICATION_LIST,
  GET_QUOTING_ACTIVITY_LIST,
  GET_PRIVATE_EX_LIST,
  GET_AGENT_OF_RECORD_LIST
} from 'constants/actionTypes';
import {SUCCESS, FAILED, RESETED} from 'helpers/actionTypesUtils';

export const viewApplication = applicationId => ({
  type: VIEW_APPLICATION,
  payload: applicationId,
});

export const viewApplicationSuccess = data => ({
  type: SUCCESS(VIEW_APPLICATION),
  payload: data,
});

export const viewApplicationFailed = data => ({
  type: FAILED(VIEW_APPLICATION),
  payload: data,
});

export const getApplicationList = data => ({
  type: GET_APPLICATION_LIST,
  payload: data,
});

export const getApplicationListSuccess = data => ({
  type: SUCCESS(GET_APPLICATION_LIST),
  payload: data,
});

export const getApplicationListFailed = data => ({
  type: FAILED(GET_APPLICATION_LIST),
  payload: data,
});

export const deleteApplication = application => ({
  type: DELETE_APPLICATION,
  payload: application,
});

export const deleteApplicationSuccess = () => ({
  type: SUCCESS(DELETE_APPLICATION),
});

export const deleteApplicationFailed = () => ({
  type: FAILED(DELETE_APPLICATION),
});

export const resetDeleteApplication = () => ({
  type: RESETED(DELETE_APPLICATION),
});

export const reactivateApplication = application => ({
  type: REACTIVATE_APPLICATION,
  payload: application,
});

export const reactivateApplicationSuccess = () => ({
  type: SUCCESS(REACTIVATE_APPLICATION),
});

export const reactivateApplicationFailed = () => ({
  type: FAILED(REACTIVATE_APPLICATION),
});

export const resetReactivateApplication = () => ({
  type: RESETED(REACTIVATE_APPLICATION),
});

export const viewOffExApplication = applicationId => ({
  type: VIEW_OFF_EX_APPLICATION,
  payload: applicationId,
});

export const viewOffExApplicationSuccess = data => ({
  type: SUCCESS(VIEW_OFF_EX_APPLICATION),
  payload: data,
});

export const viewOffExApplicationFailed = data => ({
  type: FAILED(VIEW_OFF_EX_APPLICATION),
  payload: data,
});

export const getOffExApplicationList = data => ({
  type: GET_OFF_EX_APPLICATION_LIST,
  payload: data,
});

export const getOffExApplicationListSuccess = data => ({
  type: SUCCESS(GET_OFF_EX_APPLICATION_LIST),
  payload: data,
});

export const getOffExApplicationListFailed = data => ({
  type: FAILED(GET_OFF_EX_APPLICATION_LIST),
  payload: data,
});

export const getPrivateExList = data => ({
  type: GET_PRIVATE_EX_LIST,
  payload: data,
});

export const getPrivateExListSuccess = data => ({
  type: SUCCESS(GET_PRIVATE_EX_LIST),
  payload: data,
});

export const getPrivateExListFailed = data => ({
  type: FAILED(GET_PRIVATE_EX_LIST),
  payload: data,
});

export const getQuotingActivityList = data => ({
  type: GET_QUOTING_ACTIVITY_LIST,
  payload: data,
});

export const getQuotingActivityListSuccess = data => ({
  type: SUCCESS(GET_QUOTING_ACTIVITY_LIST),
  payload: data,
});

export const getQuotingActivityListFailed = data => ({
  type: FAILED(GET_QUOTING_ACTIVITY_LIST),
  payload: data,
});

export const getAgentOfRecordList = data => ({
  type: GET_AGENT_OF_RECORD_LIST,
  payload: data,
});

export const getAgentOfRecordListSuccess = data => ({
  type: SUCCESS(GET_AGENT_OF_RECORD_LIST),
  payload: data,
});

export const getAgentOfRecordListFailed = data => ({
  type: FAILED(GET_AGENT_OF_RECORD_LIST),
  payload: data,
});
