import { createSelector } from 'reselect';
import { RESEND_CODE, RESET, TWO_FA, TWO_FA_VERIFICATION } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { TwoFaTransform } from 'redux/auth/two-fa/transforms';

const INIT_STATE = {
  tokenVerification: '',
  tries: 0,
  loading: false,
  isRehydrated: false,
};

const TwoFA = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case SUCCESS(TWO_FA):
      return {
        ...state,
        tokenVerification: action.payload,
        loading: false,
        isRehydrated: false,
        tries: 0,
      };
    case TWO_FA_VERIFICATION:
    case SUCCESS(TWO_FA_VERIFICATION):
      return {
        ...state,
        loading: true,
        isRehydrated: false,
      };
    case FAILED(TWO_FA_VERIFICATION):
    case FAILED(RESEND_CODE):
      return {
        ...state,
        loading: false,
        isRehydrated: false,
        tries: parseInt(state.tries, 10) + 1,
      };
    case RESEND_CODE:
    case SUCCESS(RESEND_CODE):
      return {
        ...state,
        loading: false,
        isRehydrated: false,
        tries: 0,
      };
    case RESETED(TWO_FA):
    case RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

const TwoFaPersistConfig = {
  key: 'tokenVerification',
  storage,
  whitelist: ['tokenVerification', 'tries'],
  transforms: [TwoFaTransform],
  stateReconciler: autoMergeLevel2,
};

export default persistReducer(TwoFaPersistConfig, TwoFA);

// selectors
const getTwoFaTokenFromState = state => state.Auth.TwoFA.tokenVerification;
export const getTwoFaTokenSelector = createSelector([getTwoFaTokenFromState], twoFaToken => {
  return twoFaToken;
});

const getTwoFaLoadingFromState = state => state.Auth.TwoFA.loading;
export const getTwoFaLoadingSelector = createSelector([getTwoFaLoadingFromState], loading => {
  return loading;
});

const getTwoFaTriesFromState = state => state.Auth.TwoFA.tries;
export const getTwoFaTriesSelector = createSelector([getTwoFaTriesFromState], tries => {
  return tries;
});
