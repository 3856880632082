import { all, call, cancel, fork, put, take, takeLeading } from 'redux-saga/effects';
import { flashErrorMessage } from 'redux-flash';
import { getMethod } from 'helpers';

import { FAILED_MESSAGE, GET_LOGIN_HISTORY_LIST, RESET } from 'constants/actionTypes';
import { getLoginHistoryListFailed, getLoginHistoryListSuccess } from './actions';

function* loginHistoryGetList(action) {
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/user/${action.payload}?include=loginHistory.createdBy.person,loginHistory.updatedBy.person`
    );
    yield put(getLoginHistoryListSuccess(response.data));
  } catch (error) {
    yield put(getLoginHistoryListFailed());
    yield put(flashErrorMessage(FAILED_MESSAGE));
  }
}

export function* watchGetLoginHistory() {
  yield takeLeading(GET_LOGIN_HISTORY_LIST, loginHistoryGetList);
}

function* activeSessionsSaga() {
  while (true) {
    const tasks = yield all([fork(watchGetLoginHistory)]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default activeSessionsSaga;
