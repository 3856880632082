import { GET_DMI_LIST, RESET } from 'constants/actionTypes';
import { SUCCESS, FAILED, RESETED } from 'helpers/actionTypesUtils';
import { createSelector } from 'reselect';

const INIT_STATE_LIST = {
  loading: false,
  success: false,
  error: false,
  data: {
    data: [],
    meta: { pagination: { total: 0 } },
  },
};

const DMIList = (state = INIT_STATE_LIST, action) => {
  switch (action.type) {
    case GET_DMI_LIST:
      return { ...state, loading: true };
    case SUCCESS(GET_DMI_LIST):
      return { ...state, data: action.payload, loading: false };
    case FAILED(GET_DMI_LIST):
      return { ...state, loading: false };
    case RESETED(GET_DMI_LIST):
    case RESET:
      return INIT_STATE_LIST;
    default:
      return state;
  }
};

export default DMIList;

/**
 * LIST SELECTORS
 */
export const getDMIListSelector = createSelector(
  [state => state.DMIList.data],
    dmi => dmi
);

export const getDMIListLoadingSelector = createSelector(
  [state => state.DMIList.loading],
  loading => loading
);

export const getDMIListMetaSelector = createSelector(
  [state => state.DMIList.data.meta],
  meta => meta
);
